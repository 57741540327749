import gql from "graphql-tag";

export const ADD_RECHERCHE = gql`
  mutation addRecherche($rechercheInput: RechercheInput) {
    addRecherche(rechercheInput: $rechercheInput) {
      message
    }
  }
`;

export default {
  ADD_RECHERCHE,
};
