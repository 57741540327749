import gql from "graphql-tag";

export const QUOTES = gql`
  query Quotes($where: QuoteWhereInput,$skip: Int,$like:QuoteWhereInput, $take: Int,$gt:QuoteWhereInput,$lt:QuoteWhereInput) {
    quotes(where: $where, skip: $skip, take: $take, like:$like, gt: $gt, lt: $lt) {
      data {
        id
        message
        status
        createdDate
        updatedDate
        file
      }
      count
    }
  }
`;

export default {
  QUOTES
};
