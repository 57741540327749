import gql from "graphql-tag";

export const USERS = gql`
  query Users(
    $where: UserWhereInput
    $skip: Int
    $like: UserWhereInput
    $take: Int
  ) {
    users(where: $where, like: $like, take: $take, skip: $skip) {
      data {
        id
        email
        username
        phone
        createdDate
        updatedDate
        archived
        role {
          id
          name
        }
        account {
          id
          city
          description
          address
          title
          firstName
          lastName
          birthday
          company
          idFiscal
          patent
          rc
          ice
          cnss
          agency
          referent
          phone1
          phone2
          mobile
        }
      }
      count
    }
  }
`;

export const USER = gql`
  query User($where: UserWhereInput!) {
    user(where: $where) {
      id
      email
      username
      phone
      createdDate
      updatedDate
      archived
      role {
        id
        name
      }
      account {
        id
        city
        description
        address
        title
        firstName
        lastName
        birthday
        company
        idFiscal
        patent
        rc
        ice
        cnss
        agency
        referent
        phone1
        phone2
        mobile
      }
    }
  }
`;
export const PROFILE = gql`
  query Profile {
    profile {
      id
      username
      phone
      email
      account {
        id
        city
        description
        address
        title
        firstName
        lastName
        birthday
        company
        idFiscal
        patent
        rc
        ice
        cnss
        agency
        referent
        phone1
        phone2
        mobile
      }
      role {
        id
        name
      }
    }
  }
`;

export default {
  USERS,
  USER,
  PROFILE,
};
