import React, { useState } from "react";
import CartItems from "../../../../entities/cartItems";
import Loader from "../../../../shared/components/Loader";
import View from "./View";
export default () => {
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(0);
  const variables = {
    where: { deleted: false },
    take: skip + 20,
  };

  return (
    <CartItems
      filter={variables}
      View={Loader(View)}
      setTake={setTake}
      skip={skip}
      setSkip={setSkip}
      take={take}
      NoData={View}
    ></CartItems>
  );
};
