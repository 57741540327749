import gql from "graphql-tag";

export const ROLES = gql`
  query Roles($where: RoleWhereInput) {
    roles(where: $where) {
      data {
        id
        name
      }
      count
    }
  }
`;
export const ROLE = gql`
  query Role($where: RoleWhereInput) {
    role(where: $where) {
      id
      name
    }
  }
`;

export default {
  ROLES,
  ROLE,
};
