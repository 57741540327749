export const numberFormatter = (number, avecVirgule) => {
  if (number !== 0 && !number) return "-";

  var sNumber = number.toString();
  //number.toString().includes(".") ||

  sNumber = (Math.round(number * 100) / 100).toFixed(
    avecVirgule ? avecVirgule : 0
  );

  return sNumber
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");
};

export const convertEnglishDateToDateString = (date) => {
  if (!date) return "-";

  var yyyy = Number(date.match(/^(\d+)/)[1]);
  var MM = Number(date.match(/-(\d+)/)[1]);
  var dd = Number(date.match(/-(\d+)T/)[1]);

  yyyy = yyyy.toString();
  dd = dd.toString();
  MM = MM.toString();

  if (dd < 10) dd = "0" + dd;

  if (MM < 10) MM = "0" + MM;

  return dd + "/" + MM + "/" + yyyy;
};

export const datetimeFormatter = (GMTDate) => {
  if (!GMTDate) return "-";

  var date = new Date(GMTDate);

  var today = new Date();
  // // console.log("date", date);

  // // console.log("today", today);

  var nombreJour = Math.floor(
    (today.getTime() - date.getTime()) / 1000 / 60 / 60 / 24
  );
  //// console.log("nombreJour", nombreJour);
  return (
    (nombreJour === 0
      ? date.getDate() - today.getDate() === 0
        ? "Aujourd'hui "
        : "Hier "
      : "Le " +
        [
          date.getDate() <= 9 ? "0" + date.getDate() : date.getDate(),
          date.getMonth() + 1 <= 9
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1,
          date.getFullYear(),
        ].join("-")) +
    " à " +
    [
      date.getHours() <= 9 ? "0" + date.getHours() : date.getHours(),
      date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes(),
    ].join(":")
  );
};
