import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import link from "./core";
import resolvers from "./resolvers";
const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-first",
    },
  },
  resolvers: {
    Query: resolvers,
  },
});

export default client;
