import { Card, Col, Collapse, Icon, Row } from "antd";
import { get, isEmpty } from "lodash";
import React from "react";

const { Panel } = Collapse;

const Pdf = ({ article }) => {
  return (
    <div className="descriptions-div">
      <Collapse
        bordered={false}
        expandIconPosition={"right"}
        defaultActiveKey={["1"]}
      >
        <Panel header={"PDF"} key="1">
          {isEmpty(get(article, "pdfs", [])) ? (
            <h4 className="not-found-message">Aucun PDF trouvé</h4>
          ) : (
            <Row gutter={16}>
              {get(article, "pdfs", []).map((pdf) => {
                return (
                  <Col
                    key={pdf.id}
                    xs={24}
                    sm={12}
                    md={9}
                    lg={6}
                    xl={6}
                    style={{ marginBottom: 10 }}
                  >
                    <Card title={pdf.name} className="card-pdf">
                      <p key={`${pdf.name}`} className="card-pdf-item">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={pdf.url}
                        >
                          <div className="icon_pdf">
                            <Icon type="file-pdf" className={"icon"} />
                            <span> Télécharger</span>
                          </div>
                          <Icon type="cloud-download" className={"icon"} />
                        </a>
                      </p>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};
export default Pdf;
