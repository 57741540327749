/* eslint-disable no-array-constructor */
import { Modal, Spin, Tooltip } from "antd";
import * as _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { TabContext } from "../../../../../../Context/Tab";
import Heading from "../../../../../../shared/components/stories/Heading";
import { StoreContext } from "../../../../../../shared/store";
import { numberFormatter } from "../../../../../../utils/formatter-functions";

import { useHistory } from "react-router-dom";
import { ItemWareHousesList } from "../../../../WishList/Items/List/view";

import { format, priceAndDiscount } from "../../../../../../utils/helpers";

import { SCREENS, TABS_ACTIONS } from "../../../../../../utils/constants";
import AddToCart from "../../Detail/External/AddToCart";
import Image from "../../Detail/External/Image";

const ComparerModal = (props) => {
  const { visible, setVisible, articles: articlesMixed } = props;
  var caracteristiques = [];
  var fabriquantsOE = [];
  var isCompatibleSelectionnes = [];
  var articlesSelectionnes = [];
  // eslint-disable-next-line no-array-constructor
  var listeOE = new Array();

  const compareOE = (a, b) => {
    return format(a.oenNumber) > format(b.oenNumber);
  };

  const isOECompatible = (
    indexArticleSelectionne,
    fabriquantIndex,
    allOEIndex
  ) => {
    if (indexArticleSelectionne === 0) return false;

    var oeNumber = myState.articlesSelectionnesState[0].oens.filter(
      (oen) =>
        format(oen.oenNumber) ===
          format(
            getNumerosOEParFabriquantArticle(
              indexArticleSelectionne,
              fabriquantIndex,
              allOEIndex
            )
          ) &&
        oen.brandName ===
          myState.listeOEState[fabriquantIndex][allOEIndex].brandName
    );

    return oeNumber.length > 0 ? true : false;
  };

  const INITIAL_STATE = {
    articlesSelectionnesState: [],
    criteresState: new Array(),
    oesParFournisseurState: new Array(),
    caracteristiquesState: [],
    fabriquantsOEState: [],
    listeOEState: [],
    isCompatibleSelectionnesState: [],
    oeCompatibleState: new Array(),
    loading: false,
    loadingCriteres: false,
    loadingOEs: false,
  };
  const [myState, setMyState] = useState(INITIAL_STATE);

  const { user = {} } = useContext(StoreContext);
  const { city: clientCity } = user.account;

  const history = useHistory();

  const { dispatch } = useContext(TabContext);

  const [imagePopUpConfig, setImagePopUpConfig] = useState({
    hidden: true,
    url: "",
  });

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const traiterDonnees = async () => {
    await sleep(1000);

    articlesSelectionnes = articlesMixed.map(({ comparer }) => comparer);
    // console.log("comparer articles debut", new Date());

    caracteristiques.length = 0;
    fabriquantsOE.length = 0;

    for (var i = 0; i < articlesSelectionnes.length; i++) {
      for (var j = 0; j < articlesSelectionnes[i].attributes.length; j++) {
        // eslint-disable-next-line no-loop-func
        if (
          caracteristiques.findIndex(
            // eslint-disable-next-line no-loop-func
            (caracteristique) =>
              caracteristique.id === articlesSelectionnes[i].attributes[j].id
          ) === -1
        )
          caracteristiques.push(articlesSelectionnes[i].attributes[j]);
      }

      for (var k = 0; k < articlesSelectionnes[i].oens.length; k++) {
        if (!fabriquantsOE.includes(articlesSelectionnes[i].oens[k].brandName))
          fabriquantsOE.push(articlesSelectionnes[i].oens[k].brandName);
      }
    }
    // eslint-disable-next-line no-array-constructor
    listeOE = new Array();

    for (j = 0; j < fabriquantsOE.length; j++) {
      listeOE[j] = [];

      for (var i = 0; i < articlesSelectionnes.length; i++) {
        listeOE[j] = [
          ...listeOE[j],
          ...articlesSelectionnes[i].oens.filter(
            (oemNumber) => oemNumber.brandName === fabriquantsOE[j]
          ),
        ];
      }
      //    listeOE[j] = listeOE[j].sort(compareOE);

      listeOE[j] = _.uniqBy(listeOE[j], function (e) {
        return format(e.oenNumber) + ";" + e.brandName;
      }).sort(compareOE);
    }

    // eslint-disable-next-line no-redeclare
    for (var i = 0; i < articlesSelectionnes.length; i++) {
      var array = _.flatten([
        ..._.uniqBy(_.flatten(articlesSelectionnes[0].oens), function (oen) {
          return format(oen.oenNumber) + ";" + oen.brandName;
        }),

        ..._.uniqBy(_.flatten(articlesSelectionnes[i].oens), function (oen) {
          return format(oen.oenNumber) + ";" + oen.brandName;
        }),
      ]);

      var groupped = _.groupBy(array, function (oen) {
        return format(oen.oenNumber) + ";" + oen.brandName;
      });
      var result = _.filter(groupped, function (n) {
        return n.length > 1;
      });

      isCompatibleSelectionnes[i] = _.uniqBy(_.flatten(result), function (oen) {
        return format(oen.oenNumber) + ";" + oen.brandName;
      });
    }
    // console.log("comparer articles fin", new Date());

    setMyState({
      ...myState,
      caracteristiquesState: caracteristiques,

      fabriquantsOEState: fabriquantsOE,
      listeOEState: listeOE,
      articlesSelectionnesState: articlesMixed.map(({ comparer }) => comparer),
      isCompatibleSelectionnesState: isCompatibleSelectionnes,
      loading: false,
    });
  };

  useEffect(() => {
    (async () => {
      var criteres = new Array();

      myState.articlesSelectionnesState.forEach((article, j) => {
        criteres[j] = [];
        myState.caracteristiquesState.forEach((caracteristique, i) => {
          criteres[j][i] = getCritere(j, i);
        });
      });

      var oeCompatible = new Array();
      var oesParFournisseur = new Array();

      myState.articlesSelectionnesState.forEach((article, j) => {
        oesParFournisseur[j] = [];
        oeCompatible[j] = [];
        myState.fabriquantsOEState.forEach((fabriquantOE, i) => {
          oesParFournisseur[j][i] = [];
          oeCompatible[j][i] = [];

          myState.listeOEState[i].forEach((oenNumber, k) => {
            oesParFournisseur[j][i][k] = getNumerosOEParFabriquantArticle(
              j,
              i,
              k
            );
            oeCompatible[j][i][k] = isOECompatible(j, i, k);
          });
        });
      });
      // console.log("comparer articles fin2", new Date());

      setMyState({
        ...myState,
        criteresState: criteres,
        loadingCriteres: false,
        oeCompatibleState: oeCompatible,
        oesParFournisseurState: oesParFournisseur,
        loadingOEs: false,
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    myState.caracteristiquesState,
    myState.fabriquantsOEState,
    myState.listeOEState,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // console.log("comparer articles useEffect comparer " + visible, new Date());

    (async () => {
      if (visible) {
        setMyState({
          //  ...INITIAL_STATE,
          ...myState,
          loading: true,
          loadingCriteres: true,
          loadingOEs: true,
        });

        await traiterDonnees();

        // console.log("comparer articles fin loading", new Date());
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const getCritere = (indexArticle, indexCritere) => {
    var critere = myState.articlesSelectionnesState[
      indexArticle
    ].attributes.find(
      (critere) => critere.id === myState.caracteristiquesState[indexCritere].id
    );
    return critere ? critere.value : "-";
  };

  const getNumerosOEParFabriquantArticle = (
    indexArticleSelectionne,
    fabriquantIndex,
    allOEIndex
  ) => {
    var oeNumber = myState.articlesSelectionnesState[
      indexArticleSelectionne
    ].oens.filter(
      (oen) =>
        oen.brandName === myState.fabriquantsOEState[fabriquantIndex] &&
        format(oen.oenNumber) ===
          format(myState.listeOEState[fabriquantIndex][allOEIndex].oenNumber)
    );

    return oeNumber.length > 0 ? oeNumber[0].oenNumber : "-";
  };

  const setQuantityParent = (qte, index) => {
    setMyState({
      ...myState,
      articlesSelectionnesState: _.flatten([
        myState.articlesSelectionnesState.slice(0, index),

        {
          ...myState.articlesSelectionnesState[index],
          quantity: qte,
        },

        myState.articlesSelectionnesState.slice(index + 1),
      ]),
    });
  };

  return (
    <Modal
      centered
      className="full-screen-modal"
      title={<Heading title={"Comparer articles"} />}
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
    >
      <Spin spinning={myState.loading}>
        <table className="comparison-table">
          <thead>
            <tr>
              <th style={{ width: 300 }}>&nbsp;</th>

              {myState.articlesSelectionnesState.map((article, i) => {
                return (
                  <th
                    key={
                      article.referenceArticle +
                      ";" +
                      article.brand.name +
                      ";" +
                      article.item.id
                    }
                  >
                    <span className={article.item.id === -1 ? "" : "gere"}>
                      {article.item.id === -1 ? article.code : article.item.id}
                    </span>

                    <span>
                      {article.item.id === -1
                        ? " (" + article.brand.name + ")"
                        : " (" + article.item.oem + ")"}
                    </span>
                    <span style={{ marginLeft: 15 }}>
                      {i === 0
                        ? ""
                        : myState.isCompatibleSelectionnesState[i]?.length > 0
                        ? "✅"
                        : "❌"}
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="cadre">Groupe de produit</td>
              {myState.articlesSelectionnesState.map((article) => (
                <td
                  key={
                    article.referenceArticle +
                    ";" +
                    article.brand.name +
                    ";" +
                    article.item.id
                  }
                >
                  {article.name}
                </td>
              ))}
            </tr>

            <tr>
              <td className="cadre">Prix & Stock</td>
              {myState.articlesSelectionnesState.map((article, index) => (
                <td key={index}>
                  <div className="div-horizontal center">
                    <span style={{ margin: "0px 10px" }}>
                      {numberFormatter(
                        priceAndDiscount({
                          quantity: article.quantity || 1,
                          prices: article.item.prices,
                          discount: article.item.discount,
                          account: user.account,
                        }).original,
                        2
                      ) + " Dh"}
                    </span>

                    <AddToCart
                      itemWareHouses={article.item.itemWareHouses || []}
                      itemId={article.item.id}
                      itemName={article.item.name}
                      prices={article.item.prices}
                      history={history}
                      clientCity={clientCity}
                      quantity={article.quantity || 1}
                      setQuantity={(qte) => {
                        setQuantityParent(qte, index);
                      }}
                      code={article.code}
                    ></AddToCart>
                    <span style={{ margin: "0px 10px" }}>
                      <ItemWareHousesList
                        prices={article.item.prices}
                        itemWareHouses={article.item.itemWareHouses}
                        quantity={article.quantity || 1}
                      />
                    </span>
                  </div>
                </td>
              ))}
            </tr>

            <tr>
              <td
                colSpan={myState.articlesSelectionnesState.length + 1}
                className="titre"
                style={{ paddingTop: 15, paddingBottom: 15 }}
              >
                Caractéristiques
              </td>
            </tr>

            {myState.loadingCriteres ? (
              <div
                className="div-vertical center center-justified"
                style={{ margin: 30 }}
              >
                <Spin />
              </div>
            ) : (
              myState.caracteristiquesState.map((caracteristique, i) => (
                <tr key={i}>
                  <td className="cadre">{caracteristique.name}</td>

                  {myState.articlesSelectionnesState.map((article, j) => (
                    <td key={j}>
                      {_.get(myState.criteresState, `[${j}][${i}]`, "")}
                    </td>
                  ))}
                </tr>
              ))
            )}

            <tr>
              <td
                colSpan={myState.articlesSelectionnesState.length + 1}
                className="titre"
                style={{ paddingTop: 15, paddingBottom: 15 }}
              >
                Images
              </td>
            </tr>
            <tr>
              <td className="cadre">Images</td>
              {myState.articlesSelectionnesState.map((article, index) => (
                <td key={index} style={{ background: "#fff" }}>
                  <Image
                    id={article.referenceArticle}
                    oem={article.item.oem}
                    itemId={article.item.id}
                    photo={article.item.photo}
                    photoVersion={article.item.photoVersion}
                    photo2={article.item.photo2}
                    photoVersion2={article.item.photoVersion2}
                    setImagePopUpConfig={setImagePopUpConfig}
                    mode="carousel"
                  ></Image>
                </td>
              ))}
            </tr>

            <tr>
              <td
                colSpan={myState.articlesSelectionnesState.length + 1}
                className="titre"
                style={{ paddingTop: 15, paddingBottom: 15 }}
              >
                Numéros OE
              </td>
            </tr>

            {myState.loadingOEs ? (
              <div
                className="div-vertical center center-justified"
                style={{ margin: 30 }}
              >
                <Spin />
              </div>
            ) : (
              myState.fabriquantsOEState.map((fabriquantOE, i) => (
                <tr key={i}>
                  <td className="cadre" style={{ verticalAlign: "top" }}>
                    {fabriquantOE}
                  </td>

                  {myState.articlesSelectionnesState.map((article, j) => (
                    <td key={j}>
                      <div
                        className="div-horizontal"
                        style={{ flexWrap: "wrap" }}
                      >
                        {myState.listeOEState[i].map((oenNumber, k) => (
                          <Tooltip
                            key={k}
                            placement="top"
                            title={_.get(
                              myState.oesParFournisseurState,
                              `[${j}][${i}][${k}]`,
                              ""
                            )}
                          >
                            <span
                              className={
                                "trade-number fixed" +
                                (_.get(
                                  myState.oeCompatibleState,
                                  `[${j}][${i}][${k}]`,
                                  ""
                                )
                                  ? " compatible"
                                  : "")
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (
                                  _.get(
                                    myState.oesParFournisseurState,
                                    `[${j}][${i}][${k}]`,
                                    ""
                                  ) === "-"
                                )
                                  return;

                                dispatch({
                                  type: TABS_ACTIONS.HISTORY_PUSH,
                                  payload: {
                                    screen: SCREENS.ARTICLES,
                                    props: {
                                      search: {
                                        reference: _.get(
                                          myState.oesParFournisseurState,
                                          `[${j}][${i}][${k}]`,
                                          ""
                                        ),
                                        type: "direct",
                                      },
                                    },
                                  },
                                });
                              }}
                            >
                              {_.get(
                                myState.oesParFournisseurState,
                                `[${j}][${i}][${k}]`,
                                ""
                              )}
                            </span>
                          </Tooltip>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div
          hidden={imagePopUpConfig.hidden}
          className="article__image--zoom--in"
        >
          <img
            src={imagePopUpConfig.url}
            alt={"name"}
            className="article__image--zoom--in--img"
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default ComparerModal;
