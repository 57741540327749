import { Icon } from "antd";
import React from "react";
import "./style.scss";

export default ({ path }) => {
  return (
    <div className={"path__container"}>
      {path.map(({ text, onPress }) => (
        <div key={text} className="path__container__item">
          <a onClick={onPress} className={[`path__container__item__link`]}>
            {text}
          </a>
          <Icon type="right" />
        </div>
      ))}
    </div>
  );
};
