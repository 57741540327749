import gql from "graphql-tag";

export const QUERY_MDDS = gql`
  query mdds($mddInput: MddInput) {
    mdds(mddInput: $mddInput) {
      manufacturerCode
      name
    }
  }
`;
