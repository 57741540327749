import { useQuery } from "@apollo/react-hooks";
import { Button, Tooltip } from "antd";
import get from "lodash/get";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { TabContext } from "../../../../../../../Context/Tab";
import DirectArticle from "../../../../../../../entities/direct-article";
import loadingGif from "../../../../../../../shared/assets/images/loading.gif";
import Loader from "../../../../../../../shared/components/Loader";
import { ManufacturersQueries } from "../../../../../../../shared/graphql/queries";
import {
  ITEM_WAREHOUSE_COLOR,
  SCREENS,
  TABS_ACTIONS,
} from "../../../../../../../utils/constants";
import { getItemWareHousesAvailibity } from "../../../../../../../utils/helpers";

const { DIRECT_ARTICLE } = ManufacturersQueries;

export const Substitute = ({ substitute }) => {
  const { dispatch } = useContext(TabContext);
  const { data, loading } = useQuery(DIRECT_ARTICLE, {
    variables: {
      where: { type: "REFERENCE", reference: substitute.reference },
      like: {},
    },
  });
  const [article = {}] = get(data, "directArticle.data", []);
  const { code } = article;

  return (
    <Tooltip
      placement="bottom"
      overlayClassName="tooltipDetials"
      title={
        loading ? (
          <img src={loadingGif} alt="" />
        ) : (
          <div
            className="card_article_description_tags_tooltip"
            onClick={() =>
              dispatch({
                type: TABS_ACTIONS.HISTORY_PUSH,
                payload: {
                  screen: SCREENS.ARTICLES,
                  props: {
                    search: {
                      type: "direct",
                      reference: code,
                    },
                  },
                },
              })
            }
          >
            <div className="card_article_description_tags_tooltip_item">
              <span>
                <h6>Numéro d’article</h6>
              </span>

              <span style={{ width: "22%", cursor: "pointer" }}>
                <div
                  style={{
                    width: 15,
                    height: 15,
                    backgroundColor:
                      ITEM_WAREHOUSE_COLOR[
                        getItemWareHousesAvailibity(
                          get(article, "item.itemWareHouses", []),
                          1
                        )
                      ],
                    float: "left",
                    borderRadius: "50%",
                  }}
                />
                <h5>&nbsp;{article.code}</h5>
              </span>
            </div>
            <div className="card_article_description_tags_tooltip_item">
              <span>
                <h6>Unité d’emballage</h6>
              </span>
              <span>
                <h5>{article.packagingUnit}</h5>
              </span>
            </div>
            <div className="card_article_description_tags_tooltip_item">
              <span>
                <h6>Quantité par UV</h6>
              </span>
              <span>
                <h5>{article.packagingQuantity}</h5>
              </span>
            </div>
            <div className="card_article_description_tags_tooltip_item">
              <span>
                <h6>Status d’article</h6>
              </span>
              <span>
                <h5>{article.status}</h5>
              </span>
            </div>
            <div className="card_article_description_tags_tooltip_item">
              <span>
                <h6>EAN</h6>
              </span>
              <span>
                <h5>{article.ean}</h5>
              </span>
            </div>
          </div>
        )
      }
      color={"#F6F6F6"}
    >
      <Button
        onClick={() =>
          dispatch({
            type: TABS_ACTIONS.HISTORY_PUSH,
            payload: {
              screen: SCREENS.ARTICLES,
              props: {
                search: {
                  type: "direct",
                  reference: code,
                },
              },
            },
          })
        }
      >
        {substitute.reference}
      </Button>
    </Tooltip>
  );
};
export default ({ substitutes, code }) => {
  const history = useHistory();
  console.log("substitutes", substitutes);
  if (substitutes)
    return (
      <div className="card_article_description_tags scroll-hidden">
        {substitutes.map((substitute) => (
          <Substitute
            key={substitute.id}
            substitute={substitute}
            history={history}
          />
        ))}
        {substitutes.length === 0 && (
          <p className="no-sub-item-tecdoc">Aucun substitut</p>
        )}
      </div>
    );
  console.log("before loader substitutes");

  return (
    <DirectArticle
      NoData={() => (
        <div className="card_article_description_tags">
          <p className="no-sub-item-tecdoc">Aucun substitut</p>
        </div>
      )}
      filter={{
        where: { type: "REFERENCE", reference: code },
        like: {},
      }}
      options={{ fetchPolicy: "cache-first" }}
      View={Loader(({ data }) => {
        console.log("article substitutes", data);

        const article = data.find(
          ({ code: codeArticle }) => code === codeArticle
        );
        return (
          <div className="card_article_description_tags">
            {get(article, "substitutes", []).map((substitute) => (
              <Substitute
                key={substitute.id}
                substitute={substitute}
                history={history}
              />
            ))}
            {get(article, "substitutes", []).length === 0 && (
              <p className="no-sub-item-tecdoc">Aucun substitut</p>
            )}
          </div>
        );
      })}
    ></DirectArticle>
  );
};
