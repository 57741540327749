import Loadable from "react-loadable";
import Loading from "../../../shared/components/Loading";
import { PATHS } from "../../../utils/constants";

const List = Loadable({
  loader: () => import("./List"),
  loading: Loading,
});
const Create = Loadable({
  loader: () => import("./Create"),
  loading: Loading,
});

const routes = [
  {
    path: PATHS.ADMIN.QUOTES.LIST,
    exact: true,
    component: List,
  },

  {
    path: PATHS.ADMIN.QUOTES.CREATE,
    exact: true,
    component: Create,
  },
];

export default routes;
