import { Form } from "antd";
import * as React from "react";
import { FormItemValidatorWrapper } from "../utils";

import DatePicker from "./Date";
import Input from "./Input";
import Select from "./Select";

const Widgets = {
  input: Input,
  select: Select,
  date: DatePicker,
};

export const Field = ({ name, getFieldValidator, initialValue, type }) => {
  const Widget = Widgets[[type]];
  const { validators, getFieldDecorator } = getFieldValidator;
  return (
    <Form.Item>
      {FormItemValidatorWrapper({
        code: name,
        validators,
        getFieldDecorator,
        initialValue,
      })(<Widget />)}
    </Form.Item>
  );
};

export default ({ children, name, getFieldValidator, initialValue }) => {
  const { validators, getFieldDecorator } = getFieldValidator;
  return (
    <Form.Item>
      {FormItemValidatorWrapper({
        code: name,
        validators,
        getFieldDecorator,
        initialValue,
      })(children)}
    </Form.Item>
  );
};
