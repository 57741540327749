import { Col, Dropdown, Icon, Layout, Menu, Row } from "antd";
import React, { useContext } from "react";
import { TabContext } from "../../../../../Context/Tab";
import { TABS_ACTIONS } from "../../../../../utils/constants";
import autoplusLogo from "../../../../assets/images/dynamique/logo.png";
import { Store } from "../../../../store";

const { Header } = Layout;

const AdminHeader = ({ store: { clearStore } }) => {
  const { dispatch } = useContext(TabContext);

  return (
    <Header className="admin--header">
      <Row>
        <Col xs={12} sm={12} md={12}>
          <div className="logo--admin">
            <img src={autoplusLogo} alt="" />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12}>
          <div className="admin--right">
            <Dropdown
              className="dropdown--admin"
              overlay={
                <Menu>
                  <Menu.Item>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        clearStore();

                        dispatch({ type: TABS_ACTIONS.CLEAR });
                      }}
                    >
                      Se déconnecter
                    </a>
                  </Menu.Item>
                </Menu>
              }
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Admin <Icon type="down" />
              </a>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Header>
  );
};

export default Store(AdminHeader);
