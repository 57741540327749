import React, { useState } from "react";

export const UrlContext = React.createContext("url");

export const UrlConsumer = UrlContext.Consumer;

export const UrlProvider = (props) => {
  const [entries, setEntries] = useState([]);

  return (
    <UrlContext.Provider
      value={{
        entries,
        setEntries,
      }}
    >
      {props.children}
    </UrlContext.Provider>
  );
};
