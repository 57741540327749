import PropTypes from "prop-types";
import React from "react";

const processData = ({ data, type, meta }) => {
  let result;
  let count = 1;
  if (meta === "object") {
    result = data[type];
  } else {
    result = data[type].data;
    count = data[type].count;
  }
  return { count, result };
};

const Formatter = (WrappedComponent) => {
  const Process = ({ error, data, type, meta, called, ...rest }) => {
    if (error) {
      return (
        <div>
          <span>{error.message}</span>
        </div>
      );
    }

    const { count, result } =
      called && data ? processData({ data, type, meta }) : {};

    return <WrappedComponent data={result} count={count} {...rest} />;
  };

  Process.propTypes = {
    error: PropTypes.object,
    data: PropTypes.object,
    type: PropTypes.string.isRequired,
    meta: PropTypes.string,
  };

  Process.defaultProps = {
    meta: "array",
    error: undefined,
  };

  return Process;
};

Formatter.propTypes = {
  WrappedComponent: PropTypes.func.isRequired,
};

Formatter.defaultProps = {};

export default Formatter;
