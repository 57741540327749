import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { CartItemsQueries } from "../shared/graphql/queries";

const { CART_ITEMS } = CartItemsQueries;

const CartItems = ({ View, filter, options, ...props }) => {
  const query = useQuery(CART_ITEMS, {
    variables: filter,
    ...options,
  });
  // console.log("render CartItems");

  useEffect(() => {
    // console.log("render CartItems useEffect");
  }, []);

  return (
    <View
      {...{
        ...props,
        ...query,
        type: "cartItems",
      }}
    />
  );
};

CartItems.propTypes = {
  View: PropTypes.func.isRequired,
  filter: PropTypes.object,
  options: PropTypes.object,
};

CartItems.defaultProps = {
  filter: {},
  options: {},
};

export default CartItems;
