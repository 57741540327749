import { Collapse } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const { Panel } = Collapse;

export default () => {
  return (
    <Collapse bordered={false} expandIconPosition={"right"}>
      <Panel
        header="Mes demandes de réclamations"
        key="1"
        className="contact_info_item"
      >
        <div className="contact_info_item_content">
          <div className="contact_info_item_content_link">
            <Link to="/sav" className="">
              Mes réclamations
            </Link>
          </div>
        </div>
      </Panel>
    </Collapse>
  );
};
