import { Spin } from "antd";
import { flattenDeep, uniq } from "lodash";
import isNil from "lodash/isNil";
import moment from "moment";
import React, { useState } from "react";
import Vehicles from "../../../../../entities/vehicles";
import Loader from "../../../../../shared/components/Loader";
import VehiclesView from "./VehiclesView";

const filterVehicles = (elements, filter) => {
  const { year, fuelType } = filter;

  const fuelTypeCondition = (model) =>
    model.fuelType.toUpperCase().includes(fuelType.toUpperCase());
  const yearCondition = (model) =>
    model.fuelType.toUpperCase().includes(fuelType.toUpperCase()) &&
    moment(model.yearOfConstructionFrom, "YYYYMM").format("YYYY") <=
      filter.year &&
    filter.year <= moment(model.yearOfConstructionTo, "YYYYMM").format("YYYY");

  return elements.filter((model) =>
    isNil(year)
      ? fuelTypeCondition(model)
      : fuelTypeCondition(model) && yearCondition(model)
  );
};

const Manufacturer = (props) => {
  const { search, history } = props;
  const { manufacturer: manufacturerId, model } = search;
  const manufacturerBean = search.manufacturerBean;
  const modelBean = search.modelBean;

  const manufacturer = { id: manufacturerId, name: null };
  const [filter, setFilter] = useState({ year: null, fuelType: "" });

  if (!model || !manufacturerId) return null;

  return (
    <Vehicles
      NoData={() => null}
      filter={{
        where: {
          manufacturer: { id: manufacturerId },
          model: { id: model },
        },
      }}
      Loading={() => <Spin />}
      View={Loader(({ data }) => {
        const years = uniq(
          flattenDeep(
            data.map(({ yearOfConstructionFrom, yearOfConstructionTo }) => [
              yearOfConstructionFrom,
              yearOfConstructionTo,
            ])
          )
            .filter((x) => x)
            .map((x) => moment(x, "YYYYMM").format("YYYY"))
        )
          .sort()
          .reverse();
        const vehicles = filterVehicles(data, filter);
        return (
          <VehiclesView
            model={model}
            modelBean={modelBean}
            years={years}
            models={vehicles}
            manufacturer={manufacturer}
            manufacturerBean={manufacturerBean}

            setFilter={setFilter}
            filter={filter}
            history={history}
            search={search}
          />
        );
      })}
    ></Vehicles>
  );
};
export default Manufacturer;
