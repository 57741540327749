import gql from "graphql-tag";

export const FILES = gql`
  query Files(
    $where: FileWhereInput
    $like: FileWhereInput
    $skip: Int
    $take: Int
    $orderBy: [JSONObject]
  ) {
    files(
      where: $where
      skip: $skip
      take: $take
      orderBy: $orderBy
      like: $like
    ) {
      data {
        id
        position
        type
        title
        visible
        url
        body
        deleted
        active
        createdDate
        updatedDate
      }
      count
    }
  }
`;

export default {
  FILES,
};
