import React from "react"
import { Empty, Typography } from 'antd'
const { Title } = Typography

export default ({ image, description, style }) =>
  <Empty
    image={image}
    style={style}
    description={
      <Title level={4}>
        {description}
      </Title>
    }
  />