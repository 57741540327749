import {
  AutoComplete,
  Col,
  notification,
  Popover,
  Row,
  Spin,
  Tooltip,
} from "antd";
import cuid from "cuid";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import React from "react";
import ReactGA from "react-ga4";

import { apiRootUrl } from "../../../../config";
import ItemsEntity from "../../../../entities/item";
import { FILES, SCREENS, TABS_ACTIONS } from "../../../../utils/constants";
import Loader from "../../../components/Loader";
import AddToCart from "./ArticlesDetail/AddToCart";
import Card from "./ArticlesDetail/Card";

const openNotification = (type) => {
  notification[type]({
    message: "l'article est ajouté au pannier avec succès",
    placement: "bottomRight",
  });
};
const { Option } = AutoComplete;

export default {
  articles: (dispatch) => (element) => {
    return (
      <Option
        ref={"Articles"}
        key={element.id}
        text={element.name}
        onClick={() => {
          const { id, code, oens = [] } = element;
          return dispatch({
            type: TABS_ACTIONS.HISTORY_PUSH,
            payload: {
              screen: SCREENS.ARTICLES,
              props: {
                search: {
                  id,
                  code,
                  type: "catalog",
                  item: { catalogCode: code },
                  oens: oens.map(({ oenNumber }) => oenNumber),
                },
              },
            },
          });
        }}
      >
        <div className="global-search-item">
          <Tooltip
            placement="topLeft"
            title={`${element.name} / ${get(element, "brand.name", "---")} / ${
              element.code
            }`}
            arrowPointAtCenter
          >
            <Row>
              <Col span={24}>
                <span className="global-search-item-desc">{element.code}</span>
              </Col>
            </Row>
          </Tooltip>
        </div>
      </Option>
    );
  },
  cars: (dispatch) => (element) =>
    (
      <Option
        ref={"Code moteur"}
        key={element.id}
        text={element.name}
        onClick={() => {
          return dispatch({
            type: TABS_ACTIONS.HISTORY_PUSH,
            payload: {
              screen: SCREENS.VEHICLES,
              props: {
                search: {
                  car: element.id,
                },
              },
            },
          });
        }}
      >
        <div className="global-search-item">
          <span className="global-search-item-desc">{element.name}</span>
        </div>
      </Option>
    ),
  ean: (dispatch) => (element) => {
    return (
      <Option
        ref={"EAN"}
        key={element.id}
        text={element.name}
        onClick={() => {
          const { id, code, ean, oens = [] } = element;

          return dispatch({
            type: TABS_ACTIONS.HISTORY_PUSH,
            payload: {
              screen: SCREENS.ARTICLES,
              props: {
                search: {
                  id,
                  item: { catalogCode: code },
                  oens: oens.map(({ oenNumber }) => oenNumber),
                },
              },
            },
          });
        }}
      >
        <div className="global-search-item">
          <Tooltip
            placement="topLeft"
            title={`${element.name} / ${get(element, "brand.name", "---")} / ${
              element.ean
            }`}
            arrowPointAtCenter
          >
            <Row>
              <Col span={12}>
                <img
                  style={{ width: 40 }}
                  src={
                    isEmpty(element.thumbnails)
                      ? FILES.noAttachementUploaded
                      : `https://${apiRootUrl}/tec-doc/images/${element.thumbnails[0].id}`
                  }
                  alt="article"
                />
              </Col>
              <Col span={12}>
                <span className="global-search-item-desc">{element.code}</span>
              </Col>
            </Row>
          </Tooltip>
        </div>
      </Option>
    );
  },
  items: (dispatch, onArticlClik) => (element) => {
    const {
      id,
      catalogCode,
      prices,
      oem,
      name,
      thumbnail,
      itemWareHouses,
      quantity,
    } = element;
    const content = {
      id,
      catalogCode,
      prices,
      oem,
      name,
      thumbnail,
      itemWareHouses,
      quantity,
    };
    return (
      <Option ref={"N° commercial"} key={id}>
        <div className="global-search-item">
          <Popover
            placement="right"
            zIndex="1500"
            content={<Card dispatch={dispatch} articleInfo={content} />}
          >
            <Row>
              <Col
                span={17}
                onClick={() => {
                  return dispatch({
                    type: TABS_ACTIONS.HISTORY_PUSH,
                    payload: {
                      screen: SCREENS.ARTICLES,
                      props: {
                        search: {
                          id: catalogCode,
                          code: catalogCode,
                          item: { catalogCode, id },
                          type: "mdd",
                        },
                      },
                    },
                  });
                }}
              >
                <span className="global-search-item-desc">{id}</span>
              </Col>

              <Col className="add-to-cart-icon" span={6}>
                <ItemsEntity
                  filter={{
                    where: { id },
                  }}
                  View={Loader(
                    ({
                      data: { prices = [], discount, itemWareHouses = [] },
                    }) => {
                      return (
                        <AddToCart
                          itemWareHouses={itemWareHouses}
                          openNotification={openNotification}
                          itemId={id}
                          itemName={name}
                          code={catalogCode}
                        ></AddToCart>
                      );
                    }
                  )}
                  Loading={() => <Spin></Spin>}
                ></ItemsEntity>
              </Col>
            </Row>
          </Popover>
        </div>
      </Option>
    );
  },
  oe: (dispatch, _, reference) => (element) => {
    const { id, name, oens, code, brand, thumbnails, searchValue } = element;
    return (
      <Option
        ref={"N° OE"}
        key={id}
        text={name}
        onClick={() => {
          return dispatch({
            type: TABS_ACTIONS.HISTORY_PUSH,
            payload: {
              screen: SCREENS.ARTICLES,
              props: {
                search: {
                  id,
                  item: { catalogCode: code },
                  oens: oens.map(({ oenNumber }) => oenNumber),
                },
              },
            },
          });
        }}
      >
        <div className="global-search-item">
          <Tooltip
            placement="topLeft"
            title={`${name} / ${get(brand, "name", "---")} / ${reference}`}
            arrowPointAtCenter
          >
            <Row>
              <Col span={6}>
                <img
                  style={{ width: 40 }}
                  src={
                    isEmpty(thumbnails)
                      ? FILES.noAttachementUploaded
                      : `https://${apiRootUrl}/tec-doc/images/${thumbnails[0].id}`
                  }
                  alt="article"
                />
              </Col>
              <Col span={17}>
                <span className="global-search-item-desc">{code}</span>
              </Col>
            </Row>
          </Tooltip>
        </div>
      </Option>
    );
  },
  tradeNumber: (dispatch) => (element) => {
    const { searchQueryMatches, articleNumber, mfrName } = element;
    return (
      <Option
        ref={"Trade number"}
        key={articleNumber}
        onClick={() => {
          return dispatch({
            type: TABS_ACTIONS.HISTORY_PUSH,
            payload: {
              screen: SCREENS.ARTICLES,
              props: {
                search: {
                  reference: articleNumber,
                  type: "direct",
                },
              },
            },
          });
        }}
      >
        <div className="global-search-item">
          <Tooltip
            placement="topLeft"
            title={`${mfrName} / ${get(
              searchQueryMatches,
              "[0].match",
              "---"
            )}`}
            arrowPointAtCenter
          >
            <Row>
              <Col span={12}>
                <span className="global-search-item-desc">
                  {`${articleNumber} (${get(searchQueryMatches, "[0].match")})`}
                </span>
              </Col>
            </Row>
          </Tooltip>
        </div>
      </Option>
    );
  },
  vehicles: (dispatch, _, reference, addRecherche) => {
    return (element) => {
      console.log("dispatch 2 ******* ref", reference);
      const { id, name, manufacturer, model } = element;
      const vin = reference;

      return (
        <Option
          ref={"Vin"}
          key={cuid()}
          text={name}
          onClick={() => {
            console.log("dispatch 2 *******", element);

            ReactGA.event({
              category: "recherche",
              action: "vin",
              label: `${
                vin +
                " (" +
                manufacturer.name +
                " ->" +
                model.name +
                " ->" +
                name +
                ")"
              }`.toUpperCase(), // optional
              value: 99, // optional, must be a number
              //  nonInteraction: true, // optional, true/false
              //  transport: "xhr", // optional, beacon/xhr/image
            });

            addRecherche({
              variables: {
                rechercheInput: {
                  vin,
                  type: "vin",
                  motorisation: {
                    id,
                    designation: name,
                    modele: {
                      id: model.id,
                      designation: model.name,
                      marque: {
                        id: manufacturer.id,
                        designation: manufacturer.name,
                      },
                    },
                  },
                },
              },
            });

            dispatch({
              type: TABS_ACTIONS.HISTORY_PUSH,
              payload: {
                screen: SCREENS.ASSEMBLYGROUPS,
                props: {
                  search: {
                    linkingTarget: id,
                    manufacturer: manufacturer.id,
                    manufacturerBean: manufacturer,
                    model: model.id,
                    modelBean: model,
                    motorizationBean: { id, name, model, manufacturer },
                  },
                },
              },
            });
          }}
        >
          <div className="global-search-item">
            <span className="global-search-item-desc">
              {manufacturer.name + " " + model.name + " " + name}
            </span>
          </div>
        </Option>
      );
    };
  },
  description: (dispatch) => (element) =>
    (
      <Option
        ref={"Description"}
        key={element.id}
        text={element.code}
        onClick={() => {
          const { id, code, oens = [] } = element;

          return dispatch({
            type: TABS_ACTIONS.HISTORY_PUSH,
            payload: {
              screen: SCREENS.ARTICLES,
              props: {
                search: {
                  id,
                  code,
                  type: "catalog",
                  item: { catalogCode: code },
                  oens: oens.map(({ oenNumber }) => oenNumber),
                },
              },
            },
          });
        }}
      >
        <div className="global-search-item">
          <Tooltip
            placement="topLeft"
            title={`${element.name} / ${get(element, "brand.name", "---")} / ${
              element.code
            }`}
            arrowPointAtCenter
          >
            <Row>
              <Col span={24}>
                <span className="global-search-item-desc">{element.code}</span>
              </Col>
            </Row>
          </Tooltip>
        </div>
      </Option>
    ),
};
