import React from "react";
import { Logos } from "../../../../../../../utils/Logos/AssemblyGroups";
import "./styles.scss";

const LogosRender = Object.entries(Logos).reduce(
  (prev, [key, value]) => {
    prev[[key]] = () => (
      <img
        className="card--product--icon"
        src={value}
        alt={key}
      />
    );
    return prev;
  },
  { default: () => null }
);
export default ({ name, assemblyGroupId, onPress, logo }) => {
  const assemblyId =
    Object.keys(Logos).includes(assemblyGroupId) && logo
      ? assemblyGroupId
      : "default";
  const Logo = LogosRender[assemblyId];
  return (
    <span className="cardProduct__list-item-title">
      <div
        style={{ cursor: "pointer" }}
        onClick={() => onPress({ id: assemblyGroupId, name })}
      >
        <Logo></Logo>
        {name}
      </div>
    </span>
  );
};
