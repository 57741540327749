import { dequal } from "dequal";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import qs from "qs";
import React from "react";
import { SEARCH_PARTS } from "../../../../../utils/constants";

const FILTERS = {
  [SEARCH_PARTS.CAR]: (props) => {
    const {
      search ,
    } = props;
    const {
      car,
      assemblyGroup,
      item = {},
      linkingTarget,
      brand = {},
      brands = [],
      oens = [],
      reference,
      type,
    } =search;
    return {
      groups: {
        type: SEARCH_PARTS.CAR,
        criteria: { assemblyGroup, car, brands },
      },
      brands: {
        type: SEARCH_PARTS.CAR,
        criteria: { parent: assemblyGroup, car, brands },
      },
      articles: isEmpty(oens)
        ? { linkingTarget, brand, assemblyGroup, item, brands, reference, type }
        : { oens, brands, reference, item, type },
    };
  },
  [SEARCH_PARTS.ARTICLE]: (props) => {
    const {
      search ,
    } = props;
    const {
      promotion = {},
      id,
      code,
      item = {},
      brand = {},
      brands = [],
      oens = [],
      reference,
      type,
    } =search;
    return {
      brands: {
        type: SEARCH_PARTS.ARTICLE,
        criteria: { article: { id, code, item, brand } },
      },
      groups: {
        type: SEARCH_PARTS.ARTICLE,
        criteria: { article: { id, code, item, brand } },
      },
      articles: { brands, oens, id, reference, promotion, item, brand, type },
    };
  },
  [SEARCH_PARTS.REFERENCE]: (props) => {
    const {
      search ,
    } = props;
    const { brands = [], oens = [], reference } =search;
    return {
      brands: {
        type: SEARCH_PARTS.REFERENCE,
        criteria: {
          article: { id: reference, code: reference, item: { id: reference } },
        },
      },
      groups: {
        type: SEARCH_PARTS.REFERENCE,
        criteria: {
          article: { id: reference, code: reference, item: { id: reference } },
        },
      },
      articles: { brands, oens, reference },
    };
  },
  [SEARCH_PARTS.DEFAULT]: (props) => {
    /**
     * Handles only brands, brands should have their own filter
     */
    const {
      search ,
    } = props;
    const { brand = {}, promotion = {} } =search;
    return {
      brands: {},
      groups: {},
      articles: { brand, promotion },
    };
  },
};

export default (WrappedComponent) =>
  React.memo(
    (props) => {
      const {
        search ,
      } = props;
      const { car, id, linkingTarget, reference, brand } =search
      const selector = !isNil(car)
        ? SEARCH_PARTS.CAR
        : !isNil(linkingTarget)
        ? SEARCH_PARTS.LINKING_TARGET
        : !isNil(id)
        ? SEARCH_PARTS.ARTICLE
        : !isNil(reference)
        ? SEARCH_PARTS.REFERENCE
        : !isNil(brand)
        ? SEARCH_PARTS.ARTICLE
        : SEARCH_PARTS.DEFAULT;
      const filter = FILTERS[selector](props);
      return <WrappedComponent {...props} filter={filter} />;
    },
    (prev, next) => dequal(prev, next)
  );
