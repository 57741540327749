import { Spin } from "antd";
import get from "lodash/get";
import React, { useContext } from "react";
import { TabContext } from "../../Context/Tab";
import Vehicles from "../../entities/vehicles";
import Loader from "../../shared/components/Loader";
import { SCREENS, TABS_ACTIONS } from "../../utils/constants";
import Path from "./Path";

const View = ({ car, data }) => {
  const { dispatch } = useContext(TabContext);

  const path = [
    {
      onPress: () =>
        dispatch({
          type: TABS_ACTIONS.HISTORY_PUSH,
          payload: {
            screen: SCREENS.MODELS,
            props: {
              search: {
                manufacturer: get(data[0], "manufacturer", {}).id,
                manufacturerBean: get(data[0], "manufacturer", {}),
              },
            },
          },
        }),
      text: get(data[0], "manufacturer", {}).name,
    },
    {
      onPress: () =>
        dispatch({
          type: TABS_ACTIONS.HISTORY_PUSH,
          payload: {
            screen: SCREENS.VEHICLES,
            props: {
              search: {
                manufacturer: get(data[0], "manufacturer", {}).id,
                model: get(data[0], "model", {}).id,

                manufacturerBean: get(data[0], "manufacturer", {}),
                modelBean: get(data[0], "model", {}),
              },
            },
          },
        }),
      text: get(data[0], "model", {}).name,
    },
    {
      onPress: () => {
        console.log("dispatch 12 *******");

        dispatch({
          type: TABS_ACTIONS.HISTORY_PUSH,
          payload: {
            screen: SCREENS.ASSEMBLYGROUPS,
            props: {
              search: {
                manufacturer: get(data[0], "manufacturer", {}).id,
                linkingTarget: car,
                model: get(data[0], "model", {}).id,
                assemblyGroup: car,

                manufacturerBean: get(data[0], "manufacturer", {}),
                modelBean: get(data[0], "model", {}),
              },
            },
          },
        });
      },
      text: data[0].name,
    },
  ];
  return <Path path={path}></Path>;
};

export default ({ car }) => {
  const variables = {
    where: { cars: [{ id: car }] },
  };

  return (
    <Vehicles
      filter={variables}
      View={Loader(View)}
      NoData={() => null}
      car={car}
      Loading={() => <Spin size="large" />}
    ></Vehicles>
  );
};
