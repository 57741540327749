export const FILES = {
  noAttachementUploaded:
    "http://via.placeholder.com/750?text=No%20file%20uploaded",

  defaultCatalogImage: "https://via.placeholder.com/300.png",
};
export const IMAGELINK = {
  imageURL50: "https://digital-assets.tecalliance.services/images/50/",
  imageURL100: "https://digital-assets.tecalliance.services/images/100/",
  imageURL200: "https://digital-assets.tecalliance.services/images/200/",
  imageURL400: "https://digital-assets.tecalliance.services/images/400/",
  imageURL800: "https://digital-assets.tecalliance.services/images/800/",
};
export const URLS = {};

export const RETOUR_STATUSES = {
  1: "Initié",
  2: "Demandé",
  3: "A ramasser",
  4: "Ramassé",
  5: "Reçu",
  6: "Validé",
  7: "Validé & Facturé",
  8: "Validé  & Expédié",
  9: "Validé & Livré",

  10: "Rejeté",
  11: "Rejeté & Expédié",
  12: "Rejeté & Livré",

  13: "Rejeté & Expédié pour litige",
  "-1": "Echec",
};

export const RETOUR_STATUSES_COLORS = {
  1: "rgba(16, 142, 233,1)",
  2: "rgba(16, 142, 233,1)",
  3: " #87d068",
  4: "rgba(255, 165, 0,1)",
  4: "rgba(0, 200,0,1)",
  5: "rgba(0, 50,0,1)",
  6: "rgba(0,150,0,1)",
  7: "#000",
  8: "rgba(255,0,0,1)",
  9: "rgba(200,0,0,1)",
  10: "rgba(150,0,0,1)",
  11: "rgba(80,0,0,1)",
  12: "rgba(80,0,0,1)",
  13: "rgba(80,0,0,1)",

  "-1": "rgba(255,0,0,1)",
};

export const DEFAULT_LINKING_TARGET_ID = "124722";
export const DEFAULT_PHONE_NUMBER = "0000000000";

export const DEFAULT_LINKING_TARGET = {
  id: DEFAULT_LINKING_TARGET_ID,
};
export const DATE_SHORT_FILTER_FORMAT = "YYYY-MM-DD";

export const DATE_FILTER_FORMAT = "YYYY-MM-DD HH:mm:ss.SSS";
export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";
export const DATE_FORMAT = "DD/MM/YYYY";

export const VIDEO_BY_PATHNAME = (pathname) => {
  switch (pathname) {
    case "/":
      return "demo_type_recherche.webm";

    case "/cart-items":
      return "demo_panier.webm";

    case "/catalog":
      return "demo_filtre_recherche.webm";

    case "/achats":
      return "demo_achats.webm";

    default:
      return "demo_type_recherche.webm";
  }
};

export const INITIAL_VALUES = {
  minValue: 0,
  maxValue: 20,
};
export const SYNCHRONISATION = {
  STATUS: {
    DELETED: "DELETED",
    RUNNING: "RUNNING",
    PAUSED: "PAUSED",
    FAILED: "FAILED",
  },
  ACTION: {
    PAUSE: "pause",
    CREATE: "create",
    UPDATE: "update",
    DELETE: "delete",
    RESUME: "resume",
    RESTART: "restart",
  },
};

export const CRON_JOB = {
  STATUS: {
    DESTROYED: "destroyed",
    SCHEDULED: "scheduled",
    STOPED: "stoped",
  },
  ACTION: {
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    START: "START",
    STOP: "STOP",
    DELETE: "DELETE",
  },
  TYPES: {
    BATCH: {
      ADDRESS: "ADDRESS",
      DISCOUNT_ACCOUNT: "DISCOUNT_ACCOUNT",
      DISCOUNT_QUANTITATIVE: "DISCOUNT_QUANTITATIVE",
      EXPEDITION_NUMBER: "EXPEDITION_NUMBER",
      TANSPORTER: "TANSPORTER",
      PRICE: "PRICE",
      STOCK: "STOCK",
    },

    CONNECTOR: {
      ACCOUNTS: "ACCOUNTS",
      ASSETS: "ASSETS",
      CLAIMS: "CLAIMS",
      COMMERCIAL: "COMMERCIAL",

      ORDER_DRAFT: "ORDER_DRAFT",
      INVOICES: "INVOICES",
      ITEM_WAREHOUSE: "ITEM_WAREHOUSE",
      ORDERS: "ORDERS",
      ARTICLES: "ARTICLES",
      SUPPLIERS: "SUPPLIERS",
      WAREHOUSE: "WAREHOUSE",
      PAYMENT_ACCOUNT: "PAYMENT_ACCOUNT",
      CANCELLED_ORDERS_QUERY: "CANCELLED_ORDERS_QUERY",
      CANCELLED_ORDERS_VIEW: "CANCELLED_ORDERS_VIEW",
    },
  },
};

export const TABS_ACTIONS = {
  ADD_TAB: "ADD_TAB",
  CLOSE_TAB: "CLOSE_TAB",
  SWITCH_TAB: "SWITCH_TAB",
  EDIT_TAB: "EDIT_TAB",
  HISTORY_PUSH: "HISTORY_PUSH",
  HISTORY_BACK: "HISTORY_BACK",
  HISTORY_INIT: "HISTORY_INIT",
  HISTORY_NEXT: "HISTORY_NEXT",
  HISTORY_UPDATE: "HISTORY_UPDATE",
  CLEAR: "CLEAR",
};
export const SCREENS = {
  ARTICLES: "Articles",
  MODELS: "Models",
  VEHICLES: "Vehicles",
  CATALOG: "Catalog",
  ASSEMBLYGROUPS: "AssemblyGroups",
  MANFACTURERS: "Manufacturers",
  INIT_SCREEN: "Recherche",
};
export const PATHS = {
  LOGIN: "/sign-in",
  SIGN_UP: "/sign-up",
  UNAUTHORIZED: "/403",
  CATALOG: "/catalog",
  PROMOTIONS: "/promotions",
  SALES: "/sales",
  HOME: "/",
  EMPTY_PATH: "/home",
  ACCESS: "/access",
  ENTRIES: "/entries",
  RETOURS: "/retours",

  ADMIN: {
    ACCESSES: {
      LIST: "/admin/accesses",
      CREATE: "/admin/accesses/create",
    },
    VEDETTES: {
      LIST: "/admin/vedettes",
    },
    STOCK: {
      LIST: "/admin/stock",
      CREATE: "/admin/stock/create",
      DELETE: "/admin/stock/delete/:id",
      DELETE_MANY: "/admin/stock/delete/many",
      UPDATE: "/admin/stock/update/:id",
    },
    ENTRIES: {
      ACCUEIL: "/admin/entries",
    },
    USERS: {
      CREATE: "/admin/users/create",
      LIST: "/admin/users",
      EDIT: "/admin/users",
      DELETE: "/admin/users/delete",
    },
    ACCOUNTS: {
      NOTIFY: "/admin/accounts/notify",
    },
    PROMOTIONS: {
      CREATE: "/admin/promotions/create",
      LIST: "/admin/promotions",
      EDIT: "/admin/promotions",
    },
    SUPPLIERS: {
      CREATE: "/admin/suppliers/create",
      LIST: "/admin/suppliers",
      EDIT: "/admin/suppliers/:id",
    },
    SUPPORTS: {
      CREATE: "/admin/supports/create",
      LIST: "/admin/supports",
    },
    INVOICES: {
      LIST: "/admin/invoices",
    },
    ORDERS: {
      LIST: "/admin/orders",
    },
    ASSETS: {
      LIST: "/admin/assets",
    },
    CRON_JOB: {
      LIST: "/admin/cron-job",
    },
    SYNCHRONISATION: {
      LIST: "/admin/synchronisation",
    },
    ASSEMBLYGROUPS: {
      LIST: "/admin/assemblygroups",
    },
    CART_ITEMS: {
      LIST: "/admin/cart-items",
    },
    WISH_LIST_ITEMS: {
      LIST: "/admin/wish-list-items",
    },
    CLAIMS: {
      LIST: "/admin/claims",
      CREATE: "/admin/claims/create",
    },
    QUOTES: {
      LIST: "/admin/quotes",
      CREATE: "/admin/quotes/create",
    },
    UPLOADS: {
      LIST: "/admin/uploads",
      CREATE: "/admin/uploads/create",
    },
    BANNERS: {
      LIST: "/admin/banners",
      CREATE: "/admin/banners/create",
    },
    MDDS: {
      LIST: "/admin/mdds",
    },
    NOTIFICATIONS: {
      LIST: "/admin/notifications",
    },
    INDEX: "/admin",
  },
  CUSTOMER: {
    CLAIMS: {
      LIST: "/claims",
      CREATE: "/claims/create",
      EDIT: "/claims/edit",
    },

    COMPLAINTS: {
      LIST: "/sav",
      CREATE: "/sav/create",
    },
    ORDERS: {
      LIST: "/orders",
      DETAIL: "/orders/:id",
    },
    ACHATS: {
      ACCUEIL: "/achats",
    },
    RECHERCHES: {
      ACCUEIL: "/recherches",
    },

    INVOICES: {
      LIST: "/invoices",
      DETAIL: "/invoices/:id",
    },
    ASSETS: {
      LIST: "/assets",
      DETAIL: "/assets/:id",
    },
    CART: {
      ITEMS: {
        LIST: "/cart-items",
        EDIT: "/cart-items/edit",
        DELETE: "/cart-items/delete",
      },
    },
    STATEMENTS: {
      LIST: "/statements",
      EXPORT: "/statements/export",
      DETAIL: "/statements/:id",
    },
  },
};

export const CLAIM_STATUSES = {
  CREATED: "created",
  PENDING: "pending",
  FAILED: "failed",
  CLEARED: "cleared",
};

export const CLAIM_STATUSES_DESCRIPTIONS = {
  [CLAIM_STATUSES.CREATED]: {
    title: "Créé",
    value: "created",
    color: "magenta",
  },
  [CLAIM_STATUSES.CLEARED]: {
    title: "Cloturé",
    value: "cleared",
    color: "lime",
  },
  [CLAIM_STATUSES.FAILED]: { title: "Échoué", value: "failed", color: "red" },
  [CLAIM_STATUSES.PENDING]: {
    title: "En cours",
    value: "pending",
    color: "geekblue",
  },
};

export const ROLES = {
  ADMIN: "admin",
  REGULAR: "regular",
};

export const CART_ITEM_STATUSES = [
  "created",
  "pending",
  "cleared",
  "failed",
  "cancelled",
];

export const DEFAULT_DISCOUNT = 0.35;

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_SIZE_CART = 50;
export const DEFAULT_VEHICLES = 100;
export const STOCK_REASONS = {
  "Rupture de stock": "OUT_OF_STOCK",
  "Baisse de prix": "DISCOUNT",
  Nouveauté: "NEW",
};
export const SUPPLIER_TYPES = {
  REGULAR: "REGULAR",
  CATALOG: "CATALOG",
};
export const STOCK_REASONS_KEYS = {
  OUT_OF_STOCK: "OUT_OF_STOCK",
  DISCOUNT: "DISCOUNT",
  NEW: "NEW",
};
export const LOGOS = {
  sasic: "http://www.sasic-b2b.fr/img/logo-corp3.png",
  valeo: "https://logodix.com/logo/613138.png",
  hella: "https://logodix.com/logo/2149406.png",
  continental: "https://logodix.com/logo/77451.jpg",
  magnetimarelli:
    "https://www.magnetimarelli-aftermarket.fr/etc.clientlibs/mmamps/clientlibs/resources/imgml/logo.svg",
  meyle: "https://logodix.com/logo/2116441.jpg",
  purflux: "https://www.purflux.com/static/upload/pur/purflux1.png",
  default: "http://via.placeholder.com/750?text=",
};

export const COMPLAINT_STATUSES = {
  CREATED: "created",
  PENDING: "pending",
  CLEARED: "cleared",
  FAILED: "failed",
};

export const COMPLAINT_STATUSES_COLORS = {
  [COMPLAINT_STATUSES.CREATED]: "magenta",
  [COMPLAINT_STATUSES.PENDING]: "lime",
  [COMPLAINT_STATUSES.CLEARED]: "geekblue",
  [COMPLAINT_STATUSES.FAILED]: "volcano",
};

export const ORDER_STATUSES = {
  CREATED: "created",
  PENDING: "pending",
  APPROVED: "approved",
  CHARGING: "charging",
  INVOICED: "invoiced",
  EXCEEDED_LIMIT: "exceededLimit",
  CLEARED: "cleared",
  FAILED: "failed",
  CANCELLED: "cancelled",
};

export const ORDER_STATUSES_COLORS = {
  [ORDER_STATUSES.CREATED]: "magenta",
  [ORDER_STATUSES.INVOICED]: "rgba(16, 142, 233,1)",
  [ORDER_STATUSES.PENDING]: "#87d068",
  [ORDER_STATUSES.APPROVED]: "rgba(255, 165, 0,1)",
  [ORDER_STATUSES.CANCELLED]: "#f50",
  [ORDER_STATUSES.CHARGING]: "rgba(255, 85, 0,1)",
  [ORDER_STATUSES.EXCEEDED_LIMIT]: "rgba(0,0,0,1)",
  [ORDER_STATUSES.FAILED]: "rgba(255,0,0,1)",
};

export const ORDER_STATUSES_DESCRIPTIONS = {
  [ORDER_STATUSES.CREATED]: {
    tip: "Votre commande est en attente de traitement",
    name: "En attente",
  },
  [ORDER_STATUSES.PENDING]: {
    tip: "Votre commande est bien reçue, elle en cours de validation",
    name: "Reçue",
  },
  [ORDER_STATUSES.APPROVED]: {
    tip: "La commande est validée et va être prise en charge par notre équipe préparation",
    name: "En cours de traitement",
  },
  [ORDER_STATUSES.CHARGING]: {
    tip: "Votre commande est en cours de préparation par notre équipe logistique",
    name: "En cours de préparation",
  },
  [ORDER_STATUSES.INVOICED]: {
    tip: "La préparation de la commande est terminée. Elle sera remise au transporteur dans la journée",
    name: "Facturée & expédiée",
  },
  [ORDER_STATUSES.EXCEEDED_LIMIT]: {
    tip: "Commande clôturée, article en rupture de stock",
    name: "Fermée Rupture",
  },
  [ORDER_STATUSES.CLEARED]: {
    tip: "La commande est validée et va être prise en charge par notre équipe préparation",
    name: "Clôturer",
  },
  [ORDER_STATUSES.FAILED]: {
    tip: "Une erreur est survenue lors de la validation de la commande, merci de contacter le support",
    name: "Echec",
  },

  [ORDER_STATUSES.CANCELLED]: {
    tip: "Commande annulée",
    name: "Annulée",
  },
};

export const ORDER_SOURCES = {
  CRM: "CRM",
  MOBILE: "MOBILE",
  WEB: "WEB",
  SAP: "SAP",
  B2B_WEB: "B2B WEB",
  B2B_MOBILE: "B2B MOBILE",
};

export const ORDER_SOURCE_COLORS = {
  [ORDER_SOURCES.CRM]: "magenta",
  [ORDER_SOURCES.MOBILE]: "#108ee9",
  [ORDER_SOURCES.SAP]: "green",
  [ORDER_SOURCES.WEB]: "orange",
  [ORDER_SOURCES.B2B_WEB]: "orange",
  [ORDER_SOURCES.B2B_MOBILE]: "green",
};

export const ORDER_SOURCE_DESCRIPTIONS = {
  [ORDER_SOURCES.CRM]: {
    tip: "Votre commande est passé depuis CRM",
    name: "CRM",
  },
  [ORDER_SOURCES.MOBILE]: {
    tip: "Votre commande est passé depuis MOBILE",
    name: "MOBILE",
  },
  [ORDER_SOURCES.WEB]: {
    tip: "Votre commande est passé depuis WEB",
    name: "WEB",
  },
  [ORDER_SOURCES.SAP]: {
    tip: "Votre commande est passé depuis SAP",
    name: "SAP",
  },
  [ORDER_SOURCES.B2B_WEB]: {
    tip: "Votre commande est passé depuis B2B WEB",
    name: "B2B WEB",
  },
  [ORDER_SOURCES.B2B_MOBILE]: {
    tip: "Votre commande est passé depuis B2B MOBILE",
    name: "B2B MOBILE",
  },
};

export const DEFAULT_DEBOUNCE = 1000;

export const PAGINATION_LIMIT = 1000;

export const CARS = {
  DACIA: "DACIA",
  RENAULT: "RENAULT",
  PEUGEOT: "PEUGEOT",
  FORD: "FORD",
  HYUNDAI: "HYUNDAI",
  VW: "VW",
  FIAT: "FIAT",
  CITROEN: "CITROËN",
  TOYOTA: "TOYOTA",
  KIA: "KIA",
  NISSAN: "NISSAN",
  "MERCEDES BENZ": "MERCEDES BENZ",
  MITSUBISHI: "MITSUBISHI",
  BMW: "BMW",
  AUDI: "AUDI",
  OPEL: "OPEL",
  SKODA: "SKODA",
  SEAT: "SEAT",
  HONDA: "HONDA",
  SUZUKI: "SUZUKI",
  "LAND ROVER": "LAND ROVER",
  ISUZU: "ISUZU",
  CHEVROLET: "CHEVROLET",
  JEEP: "JEEP",
  VOLVO: "VOLVO",
  SSANGYONG: "SSANGYONG",
  "ALFA ROMEO": "ALFA ROMEO",
  MAZDA: "MAZDA",
  JAGUAR: "JAGUAR",
  DAIHATSU: "DAIHATSU",
  MINI: "MINI",
  PORSCHE: "PORSCHE",
  MASERATI: "MASERATI",
  INFINITI: "INFINITI",
};

export const PARTNERS = {
  "VICTOR REINZ": "VICTOR REINZ",
  HELLA: "HELLA",
  ATE: "ATE",
  PIERBURG: "PIERBURG",
  ELRING: "ELRING",
  BOGE: "BOGE",
  NGK: "NGK",
  LuK: "LuK",
  BILSTEIN: "BILSTEIN",
  VALEO: "VALEO",
  VARTA: "VARTA",
  SACHS: "SACHS",
  MONROE: "MONROE",
  PURFLUX: "PURFLUX",
  DAYCO: "DAYCO",
  SKF: "SKF",
  TYC: "TYC",
  FERODO: "FERODO",
  Optibelt: "Optibelt",
  BREMBO: "BREMBO",
  ZF: "ZF",
  PHILIPS: "PHILIPS",
  KYB: "KYB",
  GLASER: "GLASER",
  "MAGNETI MARELLI": "MAGNETI MARELLI",
  NISSENS: "NISSENS",
  MEYLE: "MEYLE",
  TRW: "TRW",
  WOLF: "WOLF",
  FAG: "FAG",
  INA: "INA",
  NRF: "NRF",
  "MEAT & DORIA": "MEAT & DORIA",
  FILTRON: "FILTRON",
  SASIC: "SASIC",
  KOLBENSCHMIDT: "KOLBENSCHMIDT",
  NPS: "NPS",
  OSVAT: "OSVAT",
  MAPA: "MAPA",
  GALFER: "GALFER",
  AKEBONO: "AKEBONO",
  TEKNOROT: "TEKNOROT",
  CONTINENTAL: "CONTINENTAL",
  SAMPA: "SAMPA",
  LASO: "LASO",
  "CONTITECH AIR SPRING": "CONTITECH AIR SPRING",
  "MANN-FILTER": "MANN-FILTER",
  BOSCH: "BOSCH",
  "CONTINENTAL CTAM": "CONTINENTAL CTAM",
  KNECHT: "KNECHT",
  FEBI: "FEBI",
  SNR: "SNR",
  METELLI: "METELLI",
  AISIN: "AISIN",
  HEPU: "HEPU",
  "CALORSTAT by Vernet": "CALORSTAT by Vernet",
  IPD: "IPD",
  MAHLE: "MAHLE",
  HITACHI: "HITACHI",
  "CONTINENTAL CTAM BR": "CONTINENTAL CTAM BR",
  "IJS GROUP": "IJS GROUP",
  "HITACHI-MX": "HITACHI-MX",
  JAPANPARTS: "JAPANPARTS",
  LEMFÖRDER: "LEMFÖRDER",
  NIPPARTS: "NIPPARTS",
};

export const WAREHOUSES = {
  FES: [
    { city: "FES" },
    { city: "MEKNES" },
    { city: "RABAT" },
    { city: "CASABLANCA" },
    { city: "TANGER" },
    { city: "OUJDA" },
    { city: "MARRAKECH" },
    { city: "AGADIR" },
    { city: "AIT MELLOUL" },
    { city: "LAAYOUNE" },
  ],
  MEKNES: [
    { city: "MEKNES" },
    { city: "FES" },
    { city: "RABAT" },
    { city: "CASABLANCA" },
    { city: "TANGER" },
    { city: "OUJDA" },
    { city: "MARRAKECH" },
    { city: "AGADIR" },
    { city: "AIT MELLOUL" },
    { city: "LAAYOUNE" },
  ],
  RABAT: [
    { city: "RABAT" },
    { city: "CASABLANCA" },
    { city: "FES" },
    { city: "MEKNES" },
    { city: "TANGER" },
    { city: "MARRAKECH" },
    { city: "AGADIR" },
    { city: "AIT MELLOUL" },
    { city: "OUJDA" },
    { city: "LAAYOUNE" },
  ],
  CASABLANCA: [
    { city: "CASABLANCA" },
    { city: "RABAT" },
    { city: "FES" },
    { city: "MEKNES" },
    { city: "TANGER" },
    { city: "MARRAKECH" },
    { city: "AGADIR" },
    { city: "AIT MELLOUL" },
    { city: "OUJDA" },
    { city: "LAAYOUNE" },
  ],
  TANGER: [
    { city: "TANGER" },
    { city: "RABAT" },
    { city: "CASABLANCA" },
    { city: "FES" },
    { city: "MEKNES" },
    { city: "MARRAKECH" },
    { city: "AGADIR" },
    { city: "AIT MELLOUL" },
    { city: "OUJDA" },
    { city: "LAAYOUNE" },
  ],
  OUJDA: [
    { city: "OUJDA" },
    { city: "FES" },
    { city: "CASABLANCA" },
    { city: "MEKNES" },
    { city: "RABAT" },
    { city: "TANGER" },
    { city: "MARRAKECH" },
    { city: "AGADIR" },
    { city: "AI MELLOUL" },
    { city: "LAAYOUNE" },
  ],
  MARRAKECH: [
    { city: "MARRAKECH" },
    { city: "AIT MELLOUL" },
    { city: "AGADIR" },
    { city: "CASABLANCA" },
    { city: "RABAT" },
    { city: "FES" },
    { city: "MEKNES" },
    { city: "TANGER" },
    { city: "OUJDA" },
    { city: "LAAYOUNE" },
  ],
  AGADIR: [
    { city: "AGADIR" },
    { city: "AIT MELLOUL" },
    { city: "MARRAKECH" },
    { city: "CASABLANCA" },
    { city: "RABAT" },
    { city: "FES" },
    { city: "MEKNES" },
    { city: "TANGER" },
    { city: "OUJDA" },
    { city: "LAAYOUNE" },
  ],
  "AIT MELLOUL": [
    { city: "AIT MELLOUL" },
    { city: "AGADIR" },
    { city: "MARRAKECH" },
    { city: "CASABLANCA" },
    { City: "RABAT" },
    { city: "FES" },
    { city: "MEKNES" },
    { city: "TANGER" },
    { city: "OUJDA" },
    { city: "LAAYOUNE" },
  ],
  LAAYOUNE: [
    { city: "LAAYOUNE" },
    { city: "AIT MELLOUL" },
    { city: "AGADIR" },
    { city: "MARRAKECH" },
    { city: "CASABLANCA" },
    { city: "RABAT" },
    { city: "FES" },
    { city: "MEKNES" },
    { city: "TANGER" },
    { city: "OUJDA" },
  ],
};

export const PRODUCTS = [
  {
    title: "Moteurs",
    items: [
      {
        name: "Alimentation carburant",
        id: "100214",
        order: 22,
        hasChildren: true,
      },
      { name: "Echappement", id: "100004", order: 24, hasChildren: true },
      { name: "Filtre", id: "100005", order: 6, hasChildren: true },
      { name: "Moteur", id: "100002", order: 11, hasChildren: true },
      {
        name: "Commande à courroie",
        id: "100016",
        order: 7,
        hasChildren: true,
      },
      { name: "Carburation", id: "100254", order: 23, hasChildren: true },
      {
        name: "Huile moteur",
        id: "101994",
        order: 4,
        hide: true,
        hasChildren: false,
      },
    ],
  },
  {
    title: "Châssis & Entraînements",
    items: [
      {
        name: "Boîte de vitesses",
        id: "100238",
        order: 18,
        hasChildren: true,
      },
      { name: "Direction", id: "100012", order: 19, hasChildren: true },
      {
        name: "Embrayage / composants",
        id: "100050",
        order: 2,
        hasChildren: true,
      },
      {
        name: "Entraînement des roues",
        id: "100014",
        order: 10,
        hasChildren: true,
      },
      { name: "Freinage", id: "100006", order: 3, hasChildren: true },
      { name: "Roues/Pneus", id: "103099", order: 38, hasChildren: true },
      {
        name: "Suspension/Amortissement",
        id: "100011",
        order: 5,
        hasChildren: true,
      },
      {
        name: "Suspension d'essieu/Guidage des roues/Roues",
        id: "100013",
        order: 12,
        hasChildren: true,
      },
      {
        name: "Entraînement des essieux",
        id: "100400",
        order: 39,
        hasChildren: true,
      },
    ],
  },
  {
    title: "Accessoires & Diverses",
    items: [
      { name: "Accessoires", id: "100733", order: 28, hasChildren: true },
      {
        name: "Nettoyage des phares",
        id: "100342",
        order: 30,
        hasChildren: false,
      },
      {
        name: "Nettoyage des vitres",
        id: "100018",
        order: 16,
        hasChildren: true,
      },
      {
        name: "Pièces de maintenance",
        id: "100019",
        order: 27,
        hasChildren: true,
      },
      {
        name: "Systèmes de confort",
        id: "100335",
        order: 34,
        hasChildren: true,
      },
      { name: "Antigel", id: "102205", order: 9, hasChildren: false },
    ],
  },
  {
    title: "Carrosseries",
    items: [
      { name: "Carrosserie", id: "100001", order: 13, hasChildren: true },
      { name: "Verrouillage", id: "100685", order: 36, hasChildren: true },
      {
        name: "Equipement intérieur",
        id: "100341",
        order: 35,
        hasChildren: true,
      },
    ],
  },
  {
    title: "Chauffages et refroidissements",
    items: [
      {
        name: "Chauffage / Ventilation",
        id: "100241",
        order: 20,
        hasChildren: true,
      },
      { name: "Climatisation", id: "100243", order: 21, hasChildren: true },
      { name: "Refroidissement", id: "100007", order: 8, hasChildren: true },
    ],
  },
  {
    title: "Systèmes électriques",
    items: [
      {
        name: "Allumage / préchauffage",
        id: "100008",
        order: 15,
        hasChildren: true,
      },
      {
        name: "Système électrique",
        id: "100010",
        order: 14,
        hasChildren: true,
      },
      { name: "Batterie", id: "100042", order: 1, hasChildren: false },
      {
        name: "Feux",
        id: "100043",
        order: 17,
        hide: true,
        hasChildren: true,
      },
      {
        name: "Système d'information et de communication",
        id: "100339",
        order: 25,
        hasChildren: true,
      },
      {
        name: "Système de sécurité",
        id: "100417",
        order: 26,
        hasChildren: true,
      },
      {
        name: "Système pneumatique",
        id: "103168",
        order: 37,
        hasChildren: false,
      },
    ],
  },
];

export const LOGIN = {
  errorMessage:
    "Veuillez contacter votre représentant commercial ou le service client au (+212) 05 22 44 40 04/05 /contact@Autoplus-maroc.com",
};
export const SEARCH_PARTS = {
  CAR: "CAR",
  LINKING_TARGET: "LINKING_TARGET",
  REFERENCE: "REFERENCE",
  ARTICLE: "ARTICLE",
  DEFAULT: "DEFAULT",
};

export const ITEM_WAREHOUSE_COLOR = {
  EMPTY: "gray",
  AVAILABLE: "green",
  UNAVAILABLE: "red",
  EXCEEDED: "orange",
  AVAILABLE_IN_DEFAULT_CITY: "#ffd90f",
};
export const WAREHOUSES_AVAILABILITIES = {
  EMPTY: "EMPTY",
  AVAILABLE: "AVAILABLE",
  UNAVAILABLE: "UNAVAILABLE",
  EXCEEDED: "EXCEEDED",
  AVAILABLE_IN_DEFAULT_CITY: "AVAILABLE_IN_DEFAULT_CITY",
};

export const WAREHOUSES_CITIES = {
  DEFAULT_CITY: "CENTRALE",
  DEFAULT_CITY_SECOND: "AGENCE",
};

export const ITEM_WAREHOUSE_DESCRIPTION = {
  FAVORITE: "FAVORITE",
  NOT_HANDLED: "NOT_HANDLED",
  NOT_AVAILAIBLE: "NOT_AVAILAIBLE",
  TOO_EXPENSIVE: "TOO_EXPENSIVE",
};
export const ITEM_WAREHOUSE_DESCRIPTION_VIEW = {
  FAVORITE: "Favoris",
  NOT_HANDLED: "Non géré",
  NOT_AVAILAIBLE: "Rupture",
  TOO_EXPENSIVE: "Trop chère",
};
export const OEMS = {
  MDD: "MDD",
  DEFAULT: "DEFAULT",
};
export const ARTICLES = {
  OURS: "ours",
  OTHERS: "others",
  NOTFOUND: "notFound",
};

export const BREAKPOINT = {
  BREAKPOINT_WEB_992: 992,
  BREAKPOINT_MOBILE_991: 991,
};

export const BUTTON_STATUS = {
  ON: "create_order_button_active",
  OFF: "create_order_button",
};

export const STOCK_STATUS = {
  SUCCESS: "success",
  WARNING: "warning",
  WARNING_ORANGE: "warning_orange",
  AVAILABLE_IN_DEFAULT_CITY: "available_in_default_city",
};

export const MAX_OPNED_TABS = 6;

export const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
