import { useMutation } from "@apollo/react-hooks";
import { Input } from "antd";
import { uniqBy } from "lodash";
import React, { useState } from "react";
import { WishListItemsMutations } from "../../../../../shared/graphql/mutations";
import { WishListItemsQueries } from "../../../../../shared/graphql/queries";
import { ITEM_WAREHOUSE_DESCRIPTION } from "../../../../../utils/constants";
import { numberFormatter } from "../../../../../utils/formatter-functions";

const { UPDATE_WISH_LIST_ITEM } = WishListItemsMutations;
const { WISH_LIST_ITEMS } = WishListItemsQueries;

export default (props) => {
  const { record } = props;
  const [item, setItem] = useState(record);
  const [updateWishListItem] = useMutation(UPDATE_WISH_LIST_ITEM);
  const onChange = (e) => {
    const price = e.target.value;
    setItem({ ...item, price: price });
    const { id } = item;
    updateWishListItem({
      variables: {
        data: { id, price: price },
        update: (cache, { data: { updateWishListItem } }) => {
          const {
            wishListItems,
            wishListItems: { data, count },
          } = cache.readQuery({
            query: WISH_LIST_ITEMS,
            variables: {
              where: { deleted: false },
            },
          });
          cache.writeQuery({
            query: WISH_LIST_ITEMS,
            variables: {
              where: { deleted: false },
            },
            data: {
              wishListItems: {
                ...wishListItems,
                data: uniqBy([...data, updateWishListItem], "id"),
                count: count,
              },
            },
          });
        },
      },
    });
  };
  return (
    <>
      {item.description === ITEM_WAREHOUSE_DESCRIPTION.TOO_EXPENSIVE ? (
        <Input defaultValue={item.price} onChange={onChange} />
      ) : (
        <span>{numberFormatter(item.price) + " Dh"}</span>
      )}
    </>
  );
};
