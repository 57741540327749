/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/react-hooks";
import { Button, Icon, Modal, Radio } from "antd";
import cuid from "cuid";
import { dequal } from "dequal";
import React, { useContext, useEffect, useState } from "react";
import { PanierContext } from "../../../../../../Context/Panier";
import AssemblyGroupsBar from "../../../../../../shared/components/AssemblyGroupsBar";
import Details from "../../Detail/External";

import Recherche from "../../../../../../shared/components/Autocomplete/Recherche";
import Heading from "../../../../../../shared/components/stories/Heading";
import { CREATE_CART_ITEM } from "../../../../../../shared/graphql/mutations/cart-item";
import { CREATE_ITEM } from "../../../../../../shared/graphql/mutations/item";
import { CART_ITEMS } from "../../../../../../shared/graphql/queries/cart-items";
import { StoreContext } from "../../../../../../shared/store";
import Critere from "../../../GlobalSearch/Vehicles/Critere";
import { DEFAULT_PAGE_SIZE_CART } from "./../../../../../../utils/constants";
import Brands from "./Brands";
import ComparerModal from "./ComparerModal";
import Criteres from "./Criteres";
import Groups from "./Groups";
import NouveauTopVente from "./NouveauTopVente";
import Vehicles from "./Vehicles";
import "./style.scss";

export default (WrappedComponent) => {
  return React.memo(
    (props) => {
      const {
        filter,
        filter: { articles },
        filter: {
          articles: { skip, take },
        },
        search,
      } = props;

      console.log("search", search);

      const { compact, setCompact } = useContext(StoreContext);
      const [visible, setVisible] = useState(false);
      const [visibleCritere, setVisibleCritere] = useState(false);

      const [selectedArticle, setSelectedArticle] = useState(null);

      const [addCartItem, { loading: cartItemLoading }] = useMutation(
        CREATE_CART_ITEM,
        {
          update: (cache, { data: { createCartItem } }) => {
            const {
              cartItems,
              cartItems: { data, count },
            } = cache.readQuery({
              query: CART_ITEMS,
              variables: {
                where: { deleted: false },
                take: DEFAULT_PAGE_SIZE_CART,
              },
            });
            cache.writeQuery({
              query: CART_ITEMS,
              variables: {
                where: { deleted: false },
                take: DEFAULT_PAGE_SIZE_CART,
              },
              data: {
                cartItems: {
                  ...cartItems,
                  data: [createCartItem, ...data],
                  count: count + 1,
                },
              },
            });
          },
          onCompleted: () => {},
        }
      );
      const [addItem, { loading: itemLoading }] = useMutation(CREATE_ITEM, {
        onCompleted: () => {
          //  // console.log("variables2", variables);
          /*  addCartItem({
            variables: {
              data: {
                item: {
                  id,
                  thumbnail: get(thumbnails, "[0].url", ""),
                },
                quantity,
                id: cuid(),
              },
            },
          }) */
        },
      });

      const { modelBean, type: typeSearch } = search;

      const [oems, setOems] = useState([]);
      const [assemblyGroups, setAssemblyGroups] = useState([]);
      const [assemblyGroupsList, setAssemblyGroupsList] = useState([]);
      const [brandsOurs, setBrandOurs] = useState([]);
      const [brandsOthers, setBrandOthers] = useState([]);
      const [nouveauTopVente, setNouveauTopVente] = useState([]);
      const [nouveauTopVenteDataSource, setNouveauTopVenteDataSource] =
        useState([
          { key: "topVente", value: "Top vente", count: 0 },
          { key: "nouveau", value: "Nouveau", count: 0 },
        ]);

      const [criteres, setCriteres] = useState([]);
      const [criteresDataSource, setCriteresDataSource] = useState([]);

      const [comparerVisible, setComparerVisible] = useState(false);

      const oemFiltred = oems.filter((oem) =>
        [...brandsOthers, ...brandsOurs].map(({ name }) => name).includes(oem)
      );

      const { articlesPourPanier, setArticlesPourPanier } =
        useContext(PanierContext);

      const addItemAndCart = async ({ variables, variables2 }) => {
        await addItem({
          variables,
        });

        addCartItem({
          variables: variables2,
        });
      };

      const ajouterAuPanier = async () => {
        // console.log("ajouterAuPanier");

        articlesPourPanier.forEach(({ panier: { item, quantity } }) => {
          return item.itemId === -1
            ? addItemAndCart({
                variables: {
                  data: {
                    id: item.id,
                    code: item.id,
                    thumbnail: item.thumbnail,
                  },
                },
                variables2: {
                  data: {
                    item: {
                      id: item.id,
                      thumbnail: item.thumbnail,
                    },
                    quantity,
                    id: cuid(),
                  },
                },
              })
            : addCartItem({
                variables: {
                  data: {
                    item: {
                      id: item.id,
                      thumbnail: item.thumbnail,
                    },
                    quantity,
                    id: cuid(),
                  },
                },
              });
        });

        viderPanier();
      };

      const viderPanier = () => {
        setArticlesPourPanier([]);
      };

      useEffect(() => {
        viderPanier();

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return (
        <>
          <div className="home_section_bg ">
            <div className="container catalog p-bottom-10">
              <AssemblyGroupsBar {...props} />
              <div
                className="div-horizontal center"
                style={{ marginBottom: 20, marginTop: 10 }}
              >
                <Button
                  type="primary"
                  size="large"
                  disabled={articlesPourPanier.length === 0}
                  onClick={viderPanier}
                >
                  <Icon style={{ fontSize: 24 }} type="delete" />
                </Button>

                <Button
                  type="secondary"
                  size="large"
                  disabled={articlesPourPanier.length < 2}
                  style={{ marginRight: 20, marginLeft: 10 }}
                  onClick={() => {
                    // console.log("comparer articles click debut", new Date());

                    setComparerVisible(true);
                  }}
                >
                  {"Comparer (" + articlesPourPanier.length + ")"}
                  <Icon style={{ fontSize: 24 }} type="project" />
                </Button>

                <Button
                  type="primary"
                  size="large"
                  disabled={articlesPourPanier.length === 0}
                  onClick={ajouterAuPanier}
                >
                  {"Ajouter au panier (" + articlesPourPanier.length + ")"}
                  <Icon style={{ fontSize: 24 }} type="shopping-cart" />
                </Button>

                <div
                  className="div-horizontal center growed"
                  style={{ marginLeft: 20, marginRight: 20 }}
                >
                  <Recherche />
                </div>
                <Radio.Group
                  type="primary"
                  defaultValue={compact ? "compact" : "normal"}
                  buttonStyle="solid"
                  size="large"
                  onChange={(value) =>
                    setCompact(value.target.value === "compact")
                  }
                >
                  <Radio.Button value="normal">Graphique</Radio.Button>
                  <Radio.Button value="compact">Compact</Radio.Button>
                </Radio.Group>
              </div>
              <div
                className="row wrapped shop_wrapper grid_list"
                style={{ margin: 0 }}
              >
                <div
                  className="col-sm-12 col-lg-3 col-xl-3"
                  style={{ paddingLeft: 0 }}
                >
                  <div className="sticky--tab">
                    <div className="sidebar_widget">
                      {/*  {assemblyGroupTitle} */}

                      {modelBean && (
                        <div className="div-vertical center">
                          <object
                            style={{
                              marginBottom: 20,
                              marginTop: 10,
                            }}
                            key={modelBean.id}
                            data={`https://media.carparts-cat.com/webdata2/kmodthumb/${modelBean.id}.png`}
                            type="image/png"
                          >
                            <img
                              style={{ maxWidth: "100%", maxHeight: 120 }}
                              src="https://www.freeiconspng.com/uploads/no-image-icon-13.png"
                              alt="Modèle"
                            />
                          </object>
                        </div>
                      )}

                      {typeSearch === "articlesAssembly" && (
                        <div className="div-horizontal center light-tag-div-critere">
                          <p
                            style={{
                              color: "#000",
                              fontWeight: "bold",
                              margin: "0px",
                              marginRight: "15px",
                              flexGrow: 1,
                            }}
                          >
                            {search.assemblyGroupBean.name}
                            <br />
                            {"(Critère: " +
                              search.critereBean.name +
                              " = " +
                              search.critereBean.formattedValue +
                              ")"}
                          </p>

                          <Icon
                            style={{ fontSize: 24, marginRight: 5 }}
                            type="monitor"
                            onClick={() => setVisibleCritere(true)}
                          />

                          <Modal
                            className="form-modal"
                            visible={visibleCritere}
                            footer={null}
                            onCancel={() => setVisibleCritere(false)}
                            title={<Heading title={"Recherche par critère"} />}
                            width={1000}
                            centered
                          >
                            <Critere
                              assemblyGroupName={search.assemblyGroupBean.name}
                              assemblyGroupId={search.assemblyGroupBean.id}
                            />
                          </Modal>
                        </div>
                      )}

                      <Criteres
                        filter={filter}
                        history={props.history}
                        criteres={criteres}
                        setCriteres={setCriteres}
                        criteresDataSource={criteresDataSource}
                        search={search}
                      />
                      <NouveauTopVente
                        filter={filter}
                        history={props.history}
                        nouveauTopVente={nouveauTopVente}
                        setNouveauTopVente={setNouveauTopVente}
                        nouveauTopVenteDataSource={nouveauTopVenteDataSource}
                        search={search}
                      />

                      <Groups
                        filter={filter}
                        history={props.history}
                        assemblyGroups={assemblyGroups}
                        setAssemblyGroups={setAssemblyGroups}
                        oems={oemFiltred}
                        search={search}
                        assemblyGroupsList={assemblyGroupsList}
                      />
                      <Brands
                        brandsOthers={brandsOthers}
                        brandsOurs={brandsOurs}
                        filter={filter}
                        history={props.history}
                        setOems={setOems}
                        oems={oemFiltred}
                        assemblyGroups={assemblyGroups}
                        search={search}
                      />
                      <Vehicles
                        filter={filter}
                        history={props.history}
                        search={search}
                      />
                    </div>
                  </div>
                </div>
                <WrappedComponent
                  {...props}
                  oems={oemFiltred}
                  assemblyGroups={assemblyGroups}
                  nouveauTopVente={nouveauTopVente}
                  setNouveauTopVenteDataSource={setNouveauTopVenteDataSource}
                  nouveauTopVenteDataSource={nouveauTopVenteDataSource}
                  setCriteresDataSource={setCriteresDataSource}
                  criteresDataSource={criteresDataSource}
                  criteres={criteres}
                  setAssemblyGroupsList={setAssemblyGroupsList}
                  setBrandOthers={setBrandOthers}
                  setBrandOurs={setBrandOurs}
                  setVisible={setVisible}
                  setSelectedArticle={setSelectedArticle}
                />
              </div>
            </div>
          </div>
          <ComparerModal
            articles={articlesPourPanier}
            visible={comparerVisible}
            setVisible={setComparerVisible}
          />
          <Details
            article={selectedArticle}
            visible={visible}
            setVisible={setVisible}
          ></Details>
        </>
      );
    },
    (prev, next) => dequal(prev, next)
  );
};
