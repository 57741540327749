import { Button, Collapse, Select, Tag, Tooltip } from "antd";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { format } from "../../../../../../utils/helpers";

const { Panel } = Collapse;
const { Option } = Select;

const Criteres = ({
  criteres,
  setCriteres,
  criteresDataSource,
  search: where = {},
}) => {
  const [selectedCritere, setSelectedCritere] = useState(null);
  const [filtredData, setFiltredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueSuggestion, setSearchValueSuggestion] = useState("");

  const [critere, setCritere] = useState({
    value: "",
  });

  const deleteCritere = (index) => {
    setCriteres([...criteres.slice(0, index), ...criteres.slice(index + 1)]);
  };

  useEffect(() => {
    const filtred = criteresDataSource
      .filter((critere) => format(critere.name).includes(format(searchValue)))
      .slice(0, 100);

    setFiltredData(filtred);
  }, [searchValue, criteresDataSource]);

  const handleChange = (valueParam) => {
    //setValue(valueParam);

    setSelectedCritere(
      criteresDataSource.filter(
        (critere) => critere.id.toString() === valueParam.key.toString()
      )[0]
    );
    // setSearchValue("");

    // setLoading(false);
  };

  useEffect(() => {
    setCritere({
      ...selectedCritere,
      value: "",
    });
  }, [selectedCritere]);

  const searchChange = (valueParam) => {
    setSearchValue(valueParam);
  };

  const searchChangeSuggestion = (valueParam) => {
    setSearchValueSuggestion(valueParam);
  };
  const handleChangeSuggestion = (valueParam) => {
    setCritere({
      ...critere,
      value: valueParam.key,
    });
  };

  const submitForm = () => {
    console.log("submit", critere);

    if (
      criteres.findIndex(
        (critereItem) =>
          critereItem.id === critere.id && critereItem.value === critere.value
      ) === -1
    )
      setCriteres([...criteres, critere]);

    setCritere({
      ...critere,
      value: "",
    });
    setSearchValueSuggestion("");
    setSearchValue("");
  };

  return (
    <>
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIconPosition={"right"}
      >
        <Panel header="Critères" key="1" className="collapse_filter not-last">
          <div className="widget_list widget_categories ">
            <div className="vehicles_scroll" style={{ padding: "10px" }}>
              <div className="div-vertical" style={{ height: "100%" }}>
                <Select
                  showSearch
                  labelInValue
                  size={"default"}
                  // value={value}
                  placeholder="Selectionner un critère"
                  notFoundContent={null}
                  filterOption={false}
                  onSearch={searchChange}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                >
                  {filtredData.map((critere) => (
                    <Option key={critere.id}>{critere.name}</Option>
                  ))}
                </Select>

                {selectedCritere ? (
                  <div className="div-vertical">
                    <div
                      className="div-horizontal center"
                      style={{ marginTop: 20, flexGrow: 1 }}
                    >
                      <Select
                        showSearch
                        size={"default"}
                        placeholder="Suggestion"
                        labelInValue
                        notFoundContent={null}
                        filterOption={false}
                        onSearch={searchChangeSuggestion}
                        onChange={handleChangeSuggestion}
                        style={{ width: "100%" }}
                      >
                        {get(selectedCritere, "values", [])
                          .filter(
                            (valueItem) =>
                              format(valueItem).includes(
                                format(critere.value)
                              ) &&
                              format(valueItem).includes(
                                format(searchValueSuggestion)
                              )
                          )
                          .map((value) => (
                            <Option key={value}>
                              {value + " " + get(selectedCritere, "unity", "")}
                            </Option>
                          ))}
                      </Select>
                    </div>
                    <Button
                      onClick={submitForm}
                      style={{ marginTop: 20, width: "100%" }}
                      size={"default"}
                      type="primary"
                      disabled={critere.value === ""}
                    >
                      Ajouter critère
                    </Button>
                  </div>
                ) : (
                  <div
                    className="div-vertical center center-justified"
                    style={{
                      padding: "40px 20px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p>Sélectionnez un critère</p>
                  </div>
                )}

                <div
                  className="div-horizontal wrapped"
                  style={{
                    marginTop: "15px",
                  }}
                >
                  {criteres.map((attributeItem, index) => (
                    <Tag
                      color="#555"
                      className="critere-tag"
                      key={
                        attributeItem.name +
                        " = " +
                        attributeItem.value +
                        ";" +
                        index
                      }
                      closable
                      onClose={() => deleteCritere(index)}
                    >
                      <Tooltip
                        title={attributeItem.name + " = " + attributeItem.value}
                      >
                        {attributeItem.name + " = " + attributeItem.value}
                      </Tooltip>
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default React.memo((props) => {
  const {
    groups: { type },
  } = props.filter;

  return <Criteres {...props} />;
});
