import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Store } from "../store";
import { PATHS } from "../../utils/constants";

const PublicRoute = ({ component: Component, store, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const {
          match: { path }
        } = props;
        if (store.hasPrivateAccess && path === "/")
          return <Redirect to={PATHS.LOGIN} />;
        return <Component {...props} />;
      }}
    />
  );
};
export default Store(PublicRoute);
