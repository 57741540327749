import gql from "graphql-tag";

export const TRANSPORTERS = gql`
  query transporters(
    $where: TransporterWhereInput
    $skip: Int
    $like: TransporterWhereInput
    $take: Int
  ) {
    transporters(where: $where, like: $like, take: $take, skip: $skip) {
      data {
        id
        name
      }
      count
    }
  }
`;
export default {
  TRANSPORTERS,
};
