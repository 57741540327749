import gql from "graphql-tag";

export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload) {
    uploadFile(file: $file)
  }
`;
export const CREATE_FILE = gql`
  mutation CreateFile($data: FileWhereInput) {
    createFile(data: $data) {
      id
      position
      type
      url
      body
      deleted
    }
  }
`;
export const DELETE_FILE = gql`
  mutation DeleteFile($data: FileWhereInput) {
    deleteFile(data: $data) {
      id
      position
      type
      url
      body
      deleted
    }
  }
`;

export const DELETE_FILE_FINAL = gql`
  mutation deleteFileFinal($where: FileWhereInput) {
    deleteFileFinal(where: $where) {
      id
      position
      type
      url
      body
      deleted
    }
  }
`;

export const UPDATE_FILE = gql`
  mutation UpdateFile($data: FileWhereInput) {
    updateFile(data: $data) {
      id
      position
      type
      url
      body
      deleted
    }
  }
`;

export default {
  UPLOAD_FILE,
  CREATE_FILE,
  DELETE_FILE,
  UPDATE_FILE,
  DELETE_FILE_FINAL,
};
