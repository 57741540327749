import Loadable from "react-loadable";
import Loading from "../../../shared/components/Loading";

const Frame = Loadable({
  loader: () => import("./Frame"),
  loading: Loading,
});

const routes = [
  {
    path: "/web-catalog",
    exact: true,
    component: Frame,
  },
];

export default routes;
