import React from "react";
import ArticleNotManaged from "../../assets/images/articleNotManaged.svg";
import Overpriced from "../../assets/images/overpriced.svg";
import SoldOut from "../../assets/images/soldOut.svg";
import Favorite from "../../assets/images/wishlist.svg";

const Icons = {
  FAVORITE: <img src={Favorite} alt={"Favorite"} />,

  NOT_HANDLED: <img src={ArticleNotManaged} alt={"articleNotManaged"} />,

  NOT_AVAILAIBLE: <img src={SoldOut} alt={"soldOut"} />,

  TOO_EXPENSIVE: <img src={Overpriced} alt={"overpriced"} />,
};
export default Icons;
