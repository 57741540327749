import { useQuery } from "@apollo/react-hooks";
import { Modal } from "antd";
import { find } from "lodash";
import get from "lodash/get";
import React, { useState } from "react";
import Article from "../../../../../../entities/article";
import Loader from "../../../../../../shared/components/Loader";
import Carousel from "../../../../../../shared/components/stories/Carousel";
import { QUERY_MDDS } from "../../../../../../shared/graphql/queries/mdd";

const ZoomImage = ({
  visible,
  setVisible,
  photo,
  photoVersion,
  dossier,
  itemId,
  name,
  url,
  checkIfMdds,
}) => {
  return (
    <div className="product__details--img">
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        className="product__details--zoom"
        centered
        footer={false}
      >
        <img
          src={
            checkIfMdds()
              ? photo
                ? `http://81.192.102.38:31849/sap-tecdoc-synchronisation/inc/dynamique/${dossier}/${
                    itemId + photo + "?v=" + photoVersion
                  }`
                : `https://upload.wikimedia.org/wikipedia/commons/a/af/Image_non_disponible.png`
              : ` https://digital-assets.tecalliance.services/images/800/${url}`
          }
          alt={photo && itemId ? itemId : name}
          className="product__details--image"
        />
      </Modal>
    </div>
  );
};

const Image = ({ url, name, itemId, oem, photo, photoVersion, dossier }) => {
  const [visible, setVisible] = useState(false);

  const {
    refetch: fetchMdds,
    data: dataMdds,
    loading: loadingMdds,
  } = useQuery(QUERY_MDDS, {
    variables: {
      mddInput: {
        motCle: "",
      },
    },
  });

  const checkIfMdds = () =>
    find(get(dataMdds, "mdds", []), (item) => {
      return (
        itemId && itemId !== -1 && item.name.toLowerCase() === oem.toLowerCase()
      );
    });

  return (
    <div className="div-vertical center center-justified">
      <div className="product__details--img">
        <img
          src={
            checkIfMdds()
              ? photo
                ? `http://81.192.102.38:31849/sap-tecdoc-synchronisation/inc/dynamique/${dossier}/${
                    itemId + photo + "?v=" + photoVersion
                  }`
                : `https://upload.wikimedia.org/wikipedia/commons/a/af/Image_non_disponible.png`
              : ` https://digital-assets.tecalliance.services/images/400/${url}`
          }
          alt={photo && itemId ? itemId : name}
          className="product__details--image"
          onClick={() => setVisible(true)}
        />
        <ZoomImage
          visible={visible}
          setVisible={setVisible}
          url={url}
          itemId={itemId}
          photo={photo}
          photoVersion={photoVersion}
          dossier={dossier}
          name={name}
          oem={oem}
          checkIfMdds={checkIfMdds}
        />
      </div>
    </div>
  );
};

export default ({
  setImagePopUpConfig,
  id,
  itemId,
  photo,
  photoVersion,
  photo2,
  photoVersion2,
  mode,
  oem,
  checkIfMdds,
}) => {
  const ImageView = {
    carousel: ({ data, setImagePopUpConfig }) => (
      <Carousel
        NumberOfDesktop={1}
        NumberOfMobile={1}
        showDots={false}
        items={data[0].thumbnails.map(({ url, name }) => (
          <Image
            key={url}
            url={url}
            name={name}
            setImagePopUpConfig={setImagePopUpConfig}
            itemId={itemId}
            photo={photo}
            photoVersion={photoVersion}
            oem={oem}
          />
        ))}
      />
    ),
    single: ({ data, setImagePopUpConfig }) => (
      <Image
        url={get(data, "[0].thumbnails[0].url", "")}
        name={get(data, "[0].thumbnails[0].name", "")}
        setImagePopUpConfig={setImagePopUpConfig}
        itemId={itemId}
        photo={photo}
        photoVersion={photoVersion}
        oem={oem}
      />
    ),
  };

  return photo && itemId ? (
    <Carousel
      NumberOfDesktop={1}
      NumberOfMobile={1}
      showDots={false}
      items={(photo2
        ? [
            { photo: photo, photoVersion: photoVersion, dossier: "article" },
            { photo: photo2, photoVersion: photoVersion2, dossier: "article2" },
          ]
        : [{ photo: photo, photoVersion: photoVersion, dossier: "article" }]
      ).map((element, index) => (
        <Image
          key={index}
          photo={element.photo}
          photoVersion={element.photoVersion}
          dossier={element.dossier}
          itemId={itemId}
          setImagePopUpConfig={setImagePopUpConfig}
          oem={oem}
        />
      ))}
    />
  ) : (
    <Article
      filter={{
        isIn: [{ id }],
      }}
      options={{ fetchPolicy: "cache-first" }}
      View={Loader(({ data }) => {
        const View = ImageView[mode];

        return (
          <View data={data} setImagePopUpConfig={setImagePopUpConfig}></View>
        );
      })}
    ></Article>
  );
};
