import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import React from "react";
import { ItemQueries } from "../shared/graphql/queries";

const { ITEMS } = ItemQueries;

const Items = ({ View, filter, options, ...props }) => {
  const query = useQuery(ITEMS, {
    variables: filter,
    ...options,
  });
  return (
    <View
      {...{
        ...props,
        ...query,
        type: "items",
      }}
    />
  );
};

Items.propTypes = {
  View: PropTypes.func.isRequired,
  filter: PropTypes.object,
  options: PropTypes.object,
};

Items.defaultProps = {
  filter: {},
  options: {},
};

export default Items;
