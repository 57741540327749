import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { cacheQueries } from "../../queries";

const { LOCAL_ARTICLES } = cacheQueries;
export const localArticles = (
  { localArticles },
  { isIn: { oems = [], assemblyGroups = [] }, where },
  { cache }
) => {
  if (localArticles) return localArticles;
  const data = cache.readQuery({
    query: LOCAL_ARTICLES,
    variables: { where, isIn: {} },
  });
  return {
    __typename: "localArticles",
    data: get(data, "localArticles.data", []).filter(
      ({ brand: { name }, assemblyGroups: assGroup }) => {
        const checkOems = isEmpty(oems) ? true : oems.includes(name);
        const checkAssemmbly = isEmpty(assemblyGroups)
          ? true
          : assemblyGroups.reduce((prev, curr) => {
              if (assGroup.map(({ name }) => name).includes(curr)) {
                prev = prev + 1;
              }
              return prev;
            }, 0) > 0;
        return checkOems && checkAssemmbly;
      }
    ),
  };
};
