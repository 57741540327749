import React, { Component } from "react";
import { Row } from "antd";
import { withRouter } from "react-router-dom";
import BottomFooter from "./../stories/Footer/BottomFooter";
import TopFooter from "./../stories/Footer/TopFooter";
// import "bootstrap/dist/css/bootstrap.css";

class HeaderBar extends Component {
  render() {
    return (
      <Row>
        <TopFooter />
       
      </Row>
    );
  }
}

export default withRouter(HeaderBar);
