import Loadable from "react-loadable";
import Loading from "../../../../shared/components/Loading";
import Support from "./Supports";

const ContactIdentifier = Loadable({
  loader: () => import("./ContactIdentifiers"),
  loading: Loading,
});

const routes = [
  {
    path: "/profile/me",
    exact: true,
    component: ContactIdentifier,
  },
  ...Support,
];

export default routes;
