import Loadable from "react-loadable";
import Loading from "../../../../../shared/components/Loading";

const List = Loadable({
  loader: () => import("./List"),
  loading: Loading,
});
const Create = Loadable({
  loader: () => import("./Create"),
  loading: Loading,
});

const routes = [
  {
    path: "/profile/supports",
    exact: true,
    component: List,
  },
  {
    path: "/profile/support/create",
    exact: true,
    component: Create,
  },
];

export default routes;
