import { useMutation } from "@apollo/react-hooks";
import { Icon } from "antd";
import cuid from "cuid";
import React from "react";
import { CartItemMutations } from "../../../../../shared/graphql/mutations";
import { CartItemsQueries } from "../../../../../shared/graphql/queries";
import { DEFAULT_PAGE_SIZE_CART } from "../../../../../utils/constants";
const { CREATE_CART_ITEM } = CartItemMutations;
const { CART_ITEMS } = CartItemsQueries;
export default ({ itemId, quantity, url = "" }) => {
  const addCartItemMutation = useMutation(CREATE_CART_ITEM, {
    update: (cache, { data: { createCartItem } }) => {
      const {
        cartItems,
        cartItems: { data, count },
      } = cache.readQuery({
        query: CART_ITEMS,
        variables: {
          where: { deleted: false },
          take: DEFAULT_PAGE_SIZE_CART,
        },
      });
      cache.writeQuery({
        query: CART_ITEMS,
        variables: {
          where: { deleted: false },
          take: DEFAULT_PAGE_SIZE_CART,
        },
        data: {
          cartItems: {
            ...cartItems,
            data: [createCartItem, ...data],
            count: count + 1,
          },
        },
      });
    },
    onCompleted: () => {},
  });
  const [addCartItem] = addCartItemMutation;
  return (
    <Icon
      className="iconTable2"
      type={addCartItemMutation.loading ? "loading" : "shopping-cart"}
      onClick={() => {
        addCartItem({
          variables: {
            data: {
              item: { id: itemId, thumbnail: url },
              quantity: parseInt(quantity),
              id: cuid(),
            },
          },
        });
      }}
    />
  );
};
