import React, { useState } from "react";
import "./SideBySideMagnifier.css"; // CSS for styling

const SideBySideMagnifier = ({ imageUrl, magnification }) => {
  const [magnifiedX, setMagnifiedX] = useState(0);
  const [magnifiedY, setMagnifiedY] = useState(0);

  const handleMouseMove = (e) => {
    console.log("mouseMove");
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;

    setMagnifiedX(x);
    setMagnifiedY(y);
  };

  return (
    <div className="side-by-side-magnifier" onMouseMove={handleMouseMove}>
      <div className="image-container">
        <img src={imageUrl} alt="Original" />
      </div>
      <div className="magnified-image-container">
        <div
          className="magnified-image"
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundPosition: `${magnifiedX * 100}% ${magnifiedY * 100}%`,
            backgroundSize: `${100 * magnification}% ${100 * magnification}%`,
          }}
        />
      </div>
    </div>
  );
};

export default SideBySideMagnifier;
