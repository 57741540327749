import { Modal } from "antd";
import React from "react";

export default ({ selectedResponsability, setSelectedResponsability }) => {
  return (
    <Modal
      className="form-modal"
      title="RESPONSABILITÉ ET GARANTIES"
      visible={selectedResponsability}
      width={800}
      onOk={() => setSelectedResponsability(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => setSelectedResponsability(false)}
    >
      <p>
        Les Produits proposés sur le site GAP sont conformes à la législation
        marocaine en vigueur. La responsabilité de GAP ne saurait être engagée
        en cas de non-respect de la législation du pays dans lequel les Produits
        sont livrés, qu'il appartient au Client de vérifier.
      </p>
      <p>
        Le Client est seul responsable du choix des Produits, de leur
        conservation et/ou de leur utilisation.
      </p>
      <p>
        Les Produits fournis par le site peuvent disposer de la garantie du
        fabricant en fonction des produits et marques concernés. Lorsque ces
        garanties sont accordées, elles sont donc susceptibles d'être
        différentes selon les produits (durée,...), la marque et les
        fournisseurs concernés. Il est précisé que la Société n'apporte aucune
        garantie supplémentaire à celle du fabricant.
      </p>
      <p>
        Pour pouvoir bénéficier de la garantie des Produits, il convient
        impérativement de conserver la facture d'achat des Produits.
      </p>
      <p>
        Pendant la période de garantie, afin de faire valoir ses droits, le
        Client devra, sous peine de déchéance de toute action s'y rapporter,
        informer la Société, par courrier électronique confirmé par courrier
        recommandé avec accusé de réception, de l'existence des défauts dans un
        délai maximum de 7 jours à compter de leur découverte. La réclamation
        sera transmise au fabricant qui se positionnera sur la recevabilité
        après expertise le cas échéant.
      </p>
      <p>
        Afin de connaître les démarches à suivre concernant le service
        après-vente pour tout problème ou de panne sur un produit vous pouvez
        contacter le Service Après-Vente GAP au numéro de téléphone suivant :{" "}
        <i style={{ fontWeight: "bolder" }}>05 22 44 40 04/05</i> du lundi au
        vendredi de 09h00 à 19h00 et le samedi de 09h00 à 13h00 ou par le biais
        d'un courriel adressé à :
        <i style={{ fontWeight: "bold", color: "blue" }}>
          contact@gap-marco.com
        </i>
      </p>
      <p>
        En tout état de cause, les garanties contractuelles ne couvrent pas :
        <ol style={{ listStyleType: "circle" }}>
          <li> Le remplacement des consommables</li>
          <li>
            L'utilisation anormale ou non conforme des produits. Nous vous
            invitons à cet égard à consulter attentivement la notice d'emploi
            fournie avec les produits
          </li>
          <li>Les pannes liées aux accessoires (câbles d'alimentation...) </li>
          <li>
            Les défauts et leurs conséquences liés à l'utilisation non-conforme
            à l'usage pour lequel le produit est destiné
          </li>
          <li>
            Les défauts et leurs conséquences liés à toute cause extérieure
          </li>
          <li>
            Les interventions et réparations réalisées sur le produit par des
            tiers.
          </li>
        </ol>
      </p>
      <p>
        La garantie contractuelle du constructeur ne fait pas obstacle à la
        garantie légale contre les vices cachés ni à la garantie légale de
        conformité.
      </p>
    </Modal>
  );
};
