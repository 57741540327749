import { Tooltip } from "antd";
import React, { useState } from "react";
import { priceAndDiscount } from "../../../../../../../utils/helpers";
import { numberFormatter } from "../../../../../../../utils/formatter-functions";
const Price = ({
  quantity,
  prices,
  discount,
  account,
  globalUnitaire = "unitaire",
}) => {
  const { price, formula, original } = priceAndDiscount({
    quantity,
    prices,
    discount,
    account,
  });
  const [showNetPrice, setShowNetPrice] = useState(false);
  return (
    <Tooltip title={formula}>
      <div className="card_article_price_boxprice">
        <h4
          onClick={() => setShowNetPrice(!showNetPrice)}
          className="original_price"
          style={{ cursor: "pointer" }}
        >
          {original
            ? numberFormatter(
                original * (globalUnitaire === "unitaire" ? 1 : quantity),

                2
              ) + " Dh"
            : ""}
        </h4>
        {showNetPrice && (
          <h4 className="brut_price">
            {price
              ? numberFormatter(
                  price * (globalUnitaire === "unitaire" ? 1 : quantity),
                  2
                ) + " Dh"
              : ""}
          </h4>
        )}
      </div>
    </Tooltip>
  );
};

export default Price;
