import { Form } from "antd";
import cuid from "cuid";
import get from "lodash/get";
import React, { useState } from "react";
import { graphql } from "react-apollo";
import { AUTO_GENERATE } from "./index";

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
};

const getKeyByValue = (object, value) =>
  Object.keys(object).filter((key) => object[key] === value);

const fillObjectInKey = (keys, data) => {
  if (keys.length === 1) {
    data[keys[0]] = cuid();
  } else {
    data[keys[0]] = fillObjectInKey(keys.slice(1, keys.length), data[keys[0]]);
  }

  return data;
};

const getObjectToSendToForm = (keys, value) => {
  let data = { ...value };
  keys.forEach((key) => {
    const keyValue = key.split(".");
    data = fillObjectInKey(keyValue, data);
  });
  return data;
};

export const FormItemValidatorWrapper = ({
  code,
  validators,
  getFieldDecorator,
  initialValue,
}) => (children) => {
  return getFieldDecorator(code, {
    rules: [get(validators, `${code}.rule`)].filter((_) => _),
    initialValue,
  })(
    React.cloneElement(children, {
      placeholder: get(validators, `${code}.label`) || code,
    })
  );
};
const defaultParser = (value) => value;
const parseValuesByValidators = (data, validators) => {
  const parsedDataEntries = Object.entries(data).map(([key, value]) => {
    const parser = validators[key].parser || defaultParser;
    return [key, parser(value)];
  });
  return Object.fromEntries(parsedDataEntries);
};

export const applyHandleSubmit = (
  action,
  validators,
  onComplete = () => {},
  onError = () => {}
) => (component) => {
  const Component = component;
  const MountedForm = (props) => {
    const [result, setResult] = useState(undefined);
    const { form, createForm } = props;
    const { getFieldsError, getFieldDecorator } = form;
    const getFieldValidator = { validators, getFieldDecorator };
    const onSubmit = (e) => {
      e.preventDefault();
      const { validateFields } = form;
      validateFields(async (err, values) => {
        const data = parseValuesByValidators({ ...values }, validators);
        const keys = getKeyByValue(validators, AUTO_GENERATE);
        const value = getObjectToSendToForm(keys, data);
        if (err) {
          onError(err);
        }

        try {
          const result = await createForm({
            variables: {
              data: value,
            },
          });
          setResult(result);
          onComplete(result);
        } catch (e) {
          onError(e);
        }
      });
    };

    return (
      <Component
        submitting={hasErrors(getFieldsError())}
        onSubmit={onSubmit}
        getFieldValidator={getFieldValidator}
        result={result}
        {...props}
      />
    );
  };
  const WrapperForm = Form.create({ name: "graphql_form" })(MountedForm);
  return graphql(action, {
    name: "createForm",
  })(WrapperForm);
};

export const useSubmit = (
  action,
  validators,
  onComplete = () => {},
  onError = () => {}
) => (component) => {
  const Component = component;
  const MountedForm = (props) => {
    const [result, setResult] = useState(undefined);
    const { form } = props;
    const { getFieldsError, getFieldDecorator } = form;
    const getFieldValidator = { validators, getFieldDecorator };
    const onSubmit = (e) => {
      e.preventDefault();
      const { validateFields } = form;
      validateFields(async (err, values) => {
        if (err) {
          return onError(err);
        }
        try {
          const result = await action(values);
          setResult(result);
          return onComplete(result);
        } catch (e) {
          return onError(e);
        }
      });
    };

    return (
      <Component
        submitting={hasErrors(getFieldsError())}
        onSubmit={onSubmit}
        getFieldValidator={getFieldValidator}
        result={result}
        {...props}
      />
    );
  };
  return Form.create({ name: "graphql_form" })(MountedForm);
};
