import { Tabs } from "antd";
import React, { memo, useContext } from "react";
import { SideBarContext } from "../../../../../Context/SideBar";
import Motor from "./Motor";
import Search from "./Search";
import Vin from "./Vin";

const { TabPane } = Tabs;

const Vehicles = (props) => {
  const { sideBarStatut, setSideBarStatut } = useContext(SideBarContext);

  const {
    collapsed,
    selectedTabNavigation,
    selectedTabRechercheHistorique,
    selectedTabRecherche,
    selectedTabHistorique,
  } = sideBarStatut;

  return (
    <Tabs
      defaultActiveKey={selectedTabRecherche}
      onChange={(activeKey) => {
        // console.log("change");
        setSideBarStatut("selectedTabRecherche", activeKey);
      }}
      className="text-center global__search__tab "
      type={"card"}
      tabBarGutter={0}
    >
      <TabPane
        className="tapSwitch "
        tab={<span>Véhicules</span>}
        key="vehicles"
      >
        <div className={"global__search__tab__item"}>
          <Search {...props} />
        </div>
      </TabPane>
      <TabPane className="tapSwitch" tab={<span>VIN</span>} key="Vin">
        <div
          className={"global__search__tab__item"}
          style={{ marginBottom: 20 }}
        >
          <Vin {...props} active={selectedTabRecherche === "Vin"}></Vin>
        </div>
      </TabPane>
      <TabPane className="tapSwitch" tab={<span>Code Moteur</span>} key="Motor">
        <div className={"global__search__tab__item"}>
          <Motor {...props} active={selectedTabRecherche === "Motor"}></Motor>
        </div>
      </TabPane>
    </Tabs>
  );
};

export default memo(Vehicles);
