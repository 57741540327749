import PropTypes from "prop-types";
import React, { useMemo } from "react";
import Table from "./Table"
import Card from "./Card"
const MODES = {
  card: Card,
  table: Table
};
const AssemblyGroups = ({ mode, ...props }) => {
  const View = useMemo(() => MODES[mode], [mode]);
  return <View  {...props} />;
};
AssemblyGroups.propTypes = {
  mode: PropTypes.oneOf(Object.keys(MODES)),
};
export default AssemblyGroups;