import { get, isEmpty } from "lodash";
import React from "react";
import { Carousel } from "react-bootstrap";
import DirectArticle from "../../../../../../../entities/direct-article";
import Loader from "../../../../../../../shared/components/Loader";

export default ({
  thumbnails,
  code,
  photo,
  photoVersion,
  photo2,
  photoVersion2,
  itemId,
  brand,
  itemWareHouses,
  quantity,
  size,
  oem,
  checkIfMdds,
}) => {
  if (checkIfMdds())
    return (
      <Carousel indicators={false} controls={false}>
        <Carousel.Item key={itemId + code + "1"}>
          <img
            style={{
              height: size === "normal" ? "70px" : "40px",
            }}
            alt={"MDD"}
            className="card_article_carousel_image"
            src={
              photo
                ? `http://81.192.102.38:31849/sap-tecdoc-synchronisation/inc/dynamique/article/${
                    itemId + photo + "?v=" + photoVersion
                  }`
                : `https://upload.wikimedia.org/wikipedia/commons/a/af/Image_non_disponible.png`
            }
          />
        </Carousel.Item>

        {photo2 && (
          <Carousel.Item key={itemId + code + "2"}>
            <img
              style={{
                height: size == "normal" ? 70 : 40,
              }}
              alt={"MDD"}
              className="card_article_carousel_image"
              src={`http://81.192.102.38:31849/sap-tecdoc-synchronisation/inc/dynamique/article2/${
                itemId + photo2 + "?v=" + photoVersion2
              }`}
            />
          </Carousel.Item>
        )}
      </Carousel>
    );

  if (!isEmpty(thumbnails))
    return (
      <Carousel indicators={false} controls={false}>
        {thumbnails.map(({ id, url, name }) => (
          <Carousel.Item key={id}>
            <img
              style={{
                height: size === "normal" ? 70 : 40,
              }}
              className="card_article_carousel_image"
              src={`https://digital-assets.tecalliance.services/images/200/${url}`}
              alt={name}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  return (
    <DirectArticle
      NoData={() => (
        <img
          className={`card_article_carousel_image `}
          src={`https://upload.wikimedia.org/wikipedia/commons/a/af/Image_non_disponible.png`}
          alt="no img available"
          style={{
            height: size == "normal" ? 70 : 40,
            alignSelf: "center",
          }}
        />
      )}
      filter={{
        where: { type: "REFERENCE", reference: code },
        like: {},
      }}
      options={{ fetchPolicy: "cache-first" }}
      View={Loader(({ data }) => {
        const article = data.find(
          (item) => item.code === code && item?.brand?.name === brand
        );

        if (!isEmpty(get(article, "thumbnails") || []))
          return (
            <Carousel indicators={false} controls={false}>
              {(get(article, "thumbnails") || []).map(({ id, url, name }) => (
                <Carousel.Item key={id}>
                  <img
                    style={{
                      height: size == "normal" ? 70 : 40,
                    }}
                    className="card_article_carousel_image"
                    src={`https://digital-assets.tecalliance.services/images/200/${url}`}
                    alt={name}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          );
        return (
          <>
            <img
              className={`card_article_carousel_image `}
              src={`https://upload.wikimedia.org/wikipedia/commons/a/af/Image_non_disponible.png`}
              alt="no img available"
              style={{
                height: size == "normal" ? 70 : 40,
                alignSelf: "center",
              }}
            />
          </>
        );
      })}
    ></DirectArticle>
  );
};
