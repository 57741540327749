import React from "react";
import iconLogoPlus from "../../../assets/images/dynamique/logoPlus.png";

import "./styles.scss";

export default ({ sombre }) => (
  <img
    src={sombre ? iconLogoPlus : iconLogoPlus}
    className="autoplusIcon"
    alt="logo"
  />
);
