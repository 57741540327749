import get from "lodash/get";
import React from "react";
import DirectArticle from "../../../../../../../entities/direct-article";
import Loader from "../../../../../../../shared/components/Loader";

const Attribute = ({ attributes = [], hidden, showList }) => {
  return (
    <div className="article--attribute">
      <div className="article--attribute--list">
        {attributes.slice(0, !hidden ? 0 : 0).map(({ name, value }) => {
          return (
            <div
              className="article--attribute--list--item"
              key={`attribute-${name}${value}`}
            >
              <label className="key">
                {name} {" : "}
              </label>
              <label className="value">{value}</label>
            </div>
          );
        })}
        {showList &&
          attributes
            .slice(!hidden ? 0 : 0, attributes.length)
            .map(({ name, value }) => {
              return (
                <div
                  className="article--attribute--list--item"
                  key={`attribute-${name}`}
                >
                  <label className="key">
                    {name} {" : "}
                  </label>
                  <label className="value">{value}</label>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default ({ attributes, code, hidden, showList }) => {
  if (attributes)
    return (
      <Attribute attributes={attributes} hidden={hidden} showList={showList} />
    );

  return (
    <DirectArticle
      NoData={() => null}
      filter={{
        where: { type: "REFERENCE", reference: code },
        like: {},
      }}
      options={{ fetchPolicy: "cache-first" }}
      View={Loader(({ data }) => {
        return (
          <Attribute
            showList={showList}
            hidden={hidden}
            attributes={
              get(
                data.find(({ code: codeArticle }) => code === codeArticle),
                "attributes"
              ) || []
            }
          />
        );
      })}
    ></DirectArticle>
  );
};
