import React from "react";
import { Redirect, Route } from "react-router-dom";
import { PATHS } from "../../utils/constants";
import { Store } from "../store";

const PrivateRoute = ({ component: Component, store, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!store.hasPrivateAccess) return <Redirect to={PATHS.LOGIN} />;
        return <Component {...props} />;
      }}
    />
  );
};
export default Store(PrivateRoute);
