export const loginValidator = {
  username: {
    label: "Username",
    rule: { required: true, message: "Veuillez inserer username" }
  },
  password: {
    label: "Password",
    rule: { required: true, message: "Veuillez remplir la password" }
  }
};
