import React, { memo } from "react";
import ReferenceAutocomplete from "../../../../shared/components/Autocomplete/Recherche";
import Vehicles from "./Vehicles";

const GlobalSearch = (props) => {
  return (
    <div className="text-center side-bar-search ">
      <div className="side-bar-search__item">
        <div
          className="global__search__tab__item"
          style={{ marginBottom: 30, marginTop: 30 }}
        >
          <ReferenceAutocomplete />
        </div>
      </div>

      <div className="side-bar-search__item">
        <Vehicles />
      </div>
    </div>
  );
};

export default memo(GlobalSearch);
