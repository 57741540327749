import { Col, Descriptions, Row, Spin } from "antd";
import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import ItemsEntity from "../../../../../entities/item";
import { StoreContext } from "../../../../../shared/store";
import { FILES, SCREENS, TABS_ACTIONS } from "../../../../../utils/constants";
import { priceAndDiscount } from "../../../../../utils/helpers";
import Loader from "../../../Loader";
import ItemWareHouses from "./ItemWareHouses";
export default ({ articleInfo, dispatch }) => {
  const {
    user: { account },
  } = useContext(StoreContext);

  const [quantity, setQuantity] = useState(1);

  return (
    <div
      onClick={() => {
        return dispatch({
          type: TABS_ACTIONS.HISTORY_PUSH,
          payload: {
            screen: SCREENS.ARTICLES,
            props: {
              search: {
                id: articleInfo.catalogCode,
                code: articleInfo.catalogCode,
                item: {
                  catalogCode: articleInfo.catalogCode,
                  id: articleInfo.id,
                },
                type: "mdd",
              },
            },
          },
        });
      }}
      className="test-card"
    >
      <div className="container-fluid">
        <Row>
          <Col span={23}>
            <h4> {articleInfo.name}</h4>
          </Col>

          <Col span={1}>
            <ItemsEntity
              filter={{
                where: { id: articleInfo.id },
              }}
              View={Loader(
                ({ data: { prices = [], discount, itemWareHouses = [] } }) => {
                  return (
                    <ItemWareHouses
                      item={articleInfo}
                      itemWareHouses={itemWareHouses}
                      quantity={quantity}
                      setQuantity={setQuantity}
                    ></ItemWareHouses>
                  );
                }
              )}
              Loading={() => <Spin></Spin>}
            ></ItemsEntity>
          </Col>
        </Row>

        <div className="row align-items-center">
          {isEmpty(articleInfo.thumbnail) ? (
            <div className="col-md-4">
              <img src={FILES.noAttachementUploaded} alt="article" />
            </div>
          ) : (
            <div className="col-md-4">
              <img
                src={`https://digital-assets.tecalliance.services/images/800/${articleInfo.thumbnail}`}
                alt="article"
              />
            </div>
          )}

          <div className="col-md-8">
            <div className="box_info box_info2 bg_panel">
              <Descriptions className="description_details">
                <Descriptions.Item label="N° commercial d'article">
                  {articleInfo.id}
                </Descriptions.Item>
                <Descriptions.Item label="N° article">
                  {articleInfo.catalogCode}
                </Descriptions.Item>
                <Descriptions.Item label="Equipementier">
                  {articleInfo.oem}
                </Descriptions.Item>
                <Descriptions.Item label="Prix de Vente Brut Unitaire(TTC)">
                  <ItemsEntity
                    filter={{
                      where: { id: articleInfo.id },
                    }}
                    View={Loader(
                      ({
                        data: { prices = [], discount, itemWareHouses = [] },
                      }) => {
                        return (
                          <span>
                            {
                              priceAndDiscount({
                                quantity,
                                prices: prices,
                                discount: discount,
                                account,
                              }).original
                            }
                          </span>
                        );
                      }
                    )}
                    Loading={() => <Spin></Spin>}
                  ></ItemsEntity>
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
