import React, { Fragment } from "react";
import { withRouter } from "react-router-dom"; // TODO: needs to be injected
import { Store } from "../../../shared/store";
import { PATHS } from "../../../utils/constants";
import BottomHeader from "./../stories/Header/BottomHeader";
const horizontalItems = [
  {
    title: "Accueil",
    url: PATHS.HOME,
    key: "home",
    icon: "shopping-cart",
    type: "simple",
  },
  {
    title: "Catalogue",
    url: "/catalog",
    key: "catalog",
    icon: "shopping-cart",
    type: "simple",
    unique: true,
  },
  {
    title: "Entrées",
    url: PATHS.ENTRIES,
    key: "entries",
    icon: "shopping-cart",
    type: "simple",
  },
  {
    title: "Promotions",
    url: "/promotions",
    key: "partners",
    icon: "mail",
    type: "simple",
  },
  {
    title: "À propos de GAP",
    url: "/#/quiSommesNous",
    // external: true,
    key: "claim",
    icon: "mail",
    type: "simple",
  },
  {
    title: "Aide & contact",
    url: "/contact-us",
    key: "contact-us",
    icon: "bank",
    type: "simple",
  },
];

const HeaderBar = (props) => {
  const { verticalProps, middleProps } = props;
  const horizontalProps = { items: horizontalItems };
  // console.log("render header");

  return (
    <Fragment>
      {/*  <MiddleHeader {...middleProps} /> */}
      <BottomHeader
        title={"Nos produits"}
        horizontal={horizontalProps}
        vertical={verticalProps}
        middleProps={middleProps}
        buttons={middleProps.buttons}
      />
    </Fragment>
  );
};

export default Store(withRouter(HeaderBar));
