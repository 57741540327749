import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cuid from "cuid";
import React from "react";
import { Collapse, ListGroup, ListGroupItem } from "react-bootstrap";
import AssemblyGroups from "../../../../../../entities/assembly-groups";
import Loader from "../../../../../../shared/components/Loader";
import Item from "./item";
import "./styles.scss";

const GroupeItem = ({
  assemblyGroupId,
  name,
  linkingTarget,
  hasChildren,
  focusedItems,
  setFocusedItems,
  padding = 10,
  onPress,
  link,
  logo,
}) => (
  <>
    <ListGroupItem
      key={cuid()}
      className="cardProduct__list-item "
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Item
        name={name}
        assemblyGroupId={assemblyGroupId}
        linkingTarget={linkingTarget}
        onPress={onPress}
        link={link}
        logo={logo}
      ></Item>

      {hasChildren ? (
        <FontAwesomeIcon
          className="m-left-10 "
          icon={
            focusedItems.includes(assemblyGroupId) ? faChevronUp : faChevronDown
          }
          onClick={() => {
            if (focusedItems.includes(assemblyGroupId)) {
              return setFocusedItems(
                focusedItems.filter((id) => id !== assemblyGroupId)
              );
            }
            return setFocusedItems([...focusedItems, assemblyGroupId]);
          }}
        />
      ) : null}
    </ListGroupItem>
    {hasChildren && focusedItems.includes(assemblyGroupId) ? (
      <Collapse expandIconPosition={"right"} style={{ paddingLeft: 20 }}>
        <AssemblyGroups
          filter={{
            where: {
              linkingTarget,
              parent: { id: assemblyGroupId },
            },
          }}
          View={Loader(({ data }) => (
            <ListGroup>
              {data.map(
                ({
                  id: subGroupId,
                  name: subGroupeName,
                  hasChildren: subGroupHasChilder,
                }) => (
                  <div style={{ paddingLeft: parseInt(padding) }}>
                    <GroupeItem
                      key={subGroupId + "-" + subGroupeName}
                      assemblyGroupId={subGroupId}
                      name={subGroupeName}
                      hasChildren={subGroupHasChilder}
                      setFocusedItems={setFocusedItems}
                      focusedItems={focusedItems}
                      padding={padding + 10}
                      onPress={onPress}
                      linkingTarget={linkingTarget}
                      logo={false}
                    ></GroupeItem>
                  </div>
                )
              )}
            </ListGroup>
          ))}
        ></AssemblyGroups>
      </Collapse>
    ) : null}
  </>
);
export default GroupeItem;
