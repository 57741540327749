import gql from "graphql-tag";

export const ENTRIES = gql`
  query Entries(
    $isIn: [EntryWhereInput]
    $isNotIn: [EntryWhereInput]
    $where: EntryWhereInput
    $like: EntryWhereInput
    $skip: Int
    $take: Int
    $gt: EntryWhereInput
    $lt: EntryWhereInput
    $gte: EntryWhereInput
    $lte: EntryWhereInput
    $orderBy: [JSONObject]
  ) {
    entries(
      isNotIn: $isNotIn
      isIn: $isIn
      where: $where
      like: $like
      skip: $skip
      take: $take
      gt: $gt
      lt: $lt
      gte: $gte
      lte: $lte
      orderBy: $orderBy
    ) {
      data {
        id
        stocks {
          id
          supplier {
            id
            name
            image
          }
          arrivalDate
          item {
            id
          }
        }
      }
      count
    }
  }
`;

export const ENTRY = gql`
  query Entry($where: EntryWhereInput) {
    entry(where: $where) {
      id
      stocks {
        id
        arrivalDate
        supplier {
          id
          name
          image
        }
        item {
          id
        }
      }
    }
  }
`;

export default {
  ENTRIES,
  ENTRY,
};
