/* eslint-disable */
import { ApolloProvider as ApolloProviderHooks } from "@apollo/react-hooks";
import { ConfigProvider } from "antd";
import frFR from "antd/es/locale/fr_FR";
import "moment/locale/fr";
import React from "react";
import { ApolloProvider } from "react-apollo";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { PanierProvider } from "./Context/Panier";
import { SideBarProvider } from "./Context/SideBar";
import { TabProvider } from "./Context/Tab";

import { UrlProvider } from "./Context/Url";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import client from "./shared/graphql";
import { StoreProvider } from "./shared/store";

const Component = () => {
  return (
    <ApolloProvider client={client}>
      <ApolloProviderHooks client={client}>
        <UrlProvider>
          <BrowserRouter>
            <PanierProvider>
              <SideBarProvider>
                <TabProvider>
                  <StoreProvider>
                    <ConfigProvider locale={frFR}>
                      <App />
                    </ConfigProvider>
                  </StoreProvider>
                </TabProvider>
              </SideBarProvider>
            </PanierProvider>
          </BrowserRouter>
        </UrlProvider>
      </ApolloProviderHooks>
    </ApolloProvider>
  );
};

const render = () => {
  ReactDOM.render(<Component />, document.getElementById("root"));
};

render();

serviceWorker.unregister();
