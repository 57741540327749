// export const apiUrl = 'http://127.0.0.1:9800/graphql';
// export const apiSubscriptionUrl = 'ws://127.0.0.1:9800/subscription';
// export const authApiUrl = 'http://127.0.0.1:3006/graphql';
// export const authSubscriptionUrl = 'ws://127.0.0.1:3006/subscriptions';

// export const rootApiUrl = '127.0.0.1:9800';
// export const apiUrl = `http://127.0.0.1:9800/graphql`;
// export const apiSubscriptionUrl = `ws://api.${rootApiUrl}/graphql`;
// export const authApiUrl = `https://auth.${rootApiUrl}/graphql`;
// export const authSubscriptionUrl = `wss://auth.${rootApiUrl}/subscription`;

const { REACT_APP_API_ROOT_URL, REACT_APP_ID_ROOT_URL } = process.env;
export const apiRootUrl = REACT_APP_API_ROOT_URL;
export const rootApiUrl = REACT_APP_API_ROOT_URL;

export const idRootUrl = REACT_APP_ID_ROOT_URL;
export const apiUrl = `https://${apiRootUrl}/graphql`;
export const apiSubscriptionUrl = `wss://${apiRootUrl}/graphql`;
export const authApiUrl = `https://${idRootUrl}/graphql`;
export const authSubscriptionUrl = `wss://${idRootUrl}/graphql`;
