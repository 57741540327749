import { Icon, Input, Modal, Popconfirm, Tooltip } from "antd";
import cuid from "cuid";
import React, { useContext, useState } from "react";
import CloseableTabs from "react-closeable-tabs";
import Loadable from "react-loadable";
import { TabContext } from "../../../Context/Tab";
import Path from "../../../Hoc/Path";
import Loading from "../../../shared/components/Loading";
import {
  MAX_OPNED_TABS,
  PATHS,
  SCREENS,
  TABS_ACTIONS,
} from "./../../../utils/constants";
import Articles from "./Articles/List";
import AssemblyGroups from "./AssemblyGroups/List";
import Manufacturers from "./Manufacturers/List";
import Models from "./Models/List";
import Vehicles from "./Vehicles/List";
import "./styles.scss";
const Home = Loadable({
  loader: () => import("./Home"),
  loading: Loading,
});

const Screens = {
  [SCREENS.ARTICLES]: (props) => <Articles {...props}></Articles>,
  [SCREENS.MODELS]: (props) => <Models {...props}></Models>,
  [SCREENS.VEHICLES]: (props) => <Vehicles {...props}></Vehicles>,
  [SCREENS.CATALOG]: (props) => <Home {...props}></Home>,
  [SCREENS.ASSEMBLYGROUPS]: (props) => (
    <AssemblyGroups {...props}></AssemblyGroups>
  ),
  [SCREENS.MANFACTURERS]: (props) => <Manufacturers {...props}></Manufacturers>,
};
const Catalog = ({ tab, ...rest }) => {
  const { screen, props } = tab;

  const Component = Screens[screen];
  return <Component screen={screen} {...rest} {...props}></Component>;
};
const CalogWithPath = Path(Catalog);
const CatalogTabs = ({ history }) => {
  const {
    tabs: { items, activeTab },

    dispatch,
  } = useContext(TabContext);

  const [visible, setVisible] = useState(false);
  const [tabName, setTabName] = useState("");

  return (
    <div className="div-vertical" style={{ position: "relative", flexGrow: 1 }}>
      <Modal
        title="Saisir le nom de l’onglet"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => {
          dispatch({
            type: TABS_ACTIONS.EDIT_TAB,
            payload: { tabName: tabName },
          });
        }}
      >
        <Input
          maxlength="17"
          size={"large"}
          onChange={(_) => {
            setTabName(_.target.value);
          }}
        />
      </Modal>

      <CloseableTabs
        onTabClick={(_, newIndex, __) =>
          dispatch({ type: TABS_ACTIONS.SWITCH_TAB, payload: newIndex })
        }
        tabPanelColor="#fafafa"
        tabPanelClass="panell"
        mainClassName="panell-main"
        tabContentClass="panell-content"
        data={[
          {
            id: cuid(),
            closeable: false,
            tab: (
              <div
                className="div-horizontal center center-justified p-left-10 p-right-10"
                style={{ height: "100%" }}
                onClick={(e) => e.stopPropagation()}
              >
                <Popconfirm
                  placement="bottom"
                  title="Êtes-vous sûr de vouloir fermer tous les onglets ?"
                  onConfirm={() => dispatch({ type: TABS_ACTIONS.CLEAR })}
                  okText="Oui"
                  cancelText="Non"
                >
                  <Tooltip title="close all tabs">
                    <Icon className="iconTable3" type="close" />
                  </Tooltip>
                </Popconfirm>
                <Tooltip title="edit tab">
                  <Icon
                    className="iconTable3"
                    type="edit"
                    onClick={() => {
                      setVisible(true);
                    }}
                  />
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title="back"
                  className="arrows__multi-onglet--left"
                >
                  <Icon
                    className="iconTable3"
                    type="left"
                    onClick={() =>
                      dispatch({ type: TABS_ACTIONS.HISTORY_BACK })
                    }
                  />
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title="next"
                  className="arrows__multi-onglet--right"
                >
                  <Icon
                    className="iconTable3"
                    type="right"
                    onClick={() =>
                      dispatch({ type: TABS_ACTIONS.HISTORY_NEXT })
                    }
                  />
                </Tooltip>
              </div>
            ),
            component: <div></div>,
          },

          ...items.map((item, index) => {
            return {
              ...item,
              component: (
                <div>
                  <CalogWithPath
                    key={item.id}
                    indexTab={index}
                    tab={item.history[item.activeHistory]}
                    history={history}
                  ></CalogWithPath>
                </div>
              ),
            };
          }),
          {
            id: cuid(),
            closeable: false,
            tab: (
              <Tooltip placement="bottom" title="add" className="tab-plus">
                <div
                  hidden={items.length > MAX_OPNED_TABS}
                  className="iconTable5"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Icon
                    visible={false}
                    className="iconTable4"
                    type="plus"
                    onClick={() => {
                      dispatch({ type: TABS_ACTIONS.ADD_TAB });
                    }}
                  />
                </div>
              </Tooltip>
            ),
            component: <div></div>,
          },
        ]}
        onCloseTab={(id, _, __) =>
          dispatch({ type: TABS_ACTIONS.CLOSE_TAB, payload: id })
        }
        activeIndex={activeTab + 1}
      />
    </div>
  );
};

const routes = [
  {
    path: PATHS.CATALOG,
    exact: true,
    component: CatalogTabs,
  },
];

export default routes;
