import cuid from "cuid";
import { useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  MAX_OPNED_TABS,
  PATHS,
  SCREENS,
  TABS_ACTIONS,
} from "../../utils/constants";

const INIT_STATE = {
  activeTab: 0,
  tabNumber: 1,
  items: [
    {
      history: [{ screen: SCREENS.CATALOG, props: { tab: "technicalData" } }],
      activeHistory: 0,
      tab: SCREENS.CATALOG,
      id: cuid(),
      closeable: true,
    },
  ],
};

const action = {
  change: (tabs, index) => {
    const { items, tabNumber } = tabs;
    const tabIndex = tabNumber || 0;
    const newState = { items, activeTab: index - 1, tabIndex };
    localStorage.setItem("tabs", JSON.stringify(newState));

    return newState;
  },
  close: (tabs, idTab) => {
    const { items, activeTab, tabNumber } = tabs;
    const tabIndex = tabNumber || 0;
    if (items.length === 1) {
      localStorage.setItem("tabs", JSON.stringify(tabs));

      return { ...tabs };
    }
    const index = items.map(({ id }) => id).indexOf(idTab);

    const newState = {
      items: items.filter((_, i) => i !== index),
      activeTab:
        index > activeTab || (index == activeTab && index < items.length - 1)
          ? activeTab
          : activeTab - 1,
      tabNumber: tabIndex,
    };

    localStorage.setItem("tabs", JSON.stringify(newState));

    return newState;
  },
  clear: () => {
    localStorage.setItem("tabs", JSON.stringify(INIT_STATE));
    return INIT_STATE;
  },
  add: (
    tabs,
    { pathname, push },
    history = {
      screen: SCREENS.CATALOG,
      props: { tab: "technicalData" },
    }
  ) => {
    const { items, tabNumber } = tabs;

    const tabIndex = tabNumber || 0;

    pathname !== PATHS.CATALOG && push(PATHS.CATALOG);
    const newState = {
      items: [
        ...items,
        {
          history: [history],
          activeHistory: 0,
          tab: `${
            history?.props?.search?.forcedName
              ? history?.props?.search?.forcedName
              : history?.props?.search?.type === "direct"
              ? history.props.search.reference
              : history?.props?.search?.type === "supplierAssembly"
              ? history.props.search.assemblyGroupBean.name +
                " (" +
                history.props.search.brandBean.name +
                ")"
              : history?.props?.search?.type === "articlesAssembly"
              ? history.props.search.assemblyGroupBean.name +
                " (Critère: " +
                history.props.search.critereBean.name +
                " = " +
                history.props.search.critereBean.formattedValue +
                ")"
              : history?.props?.search?.type === "mdd" ||
                history?.props?.search?.type === "catalog"
              ? history.props.search.code
              : history?.props?.search?.manufacturerBean
              ? history.props.search.manufacturerBean.name +
                (history?.props?.search?.modelBean
                  ? " > " +
                    history.props.search.modelBean.name +
                    (history?.props?.search?.motorizationBean
                      ? " > " +
                        history.props.search.motorizationBean.name +
                        (history?.props?.search?.assemblyGroup
                          ? " > " + history.props.search.assemblyGroup.name
                          : "")
                      : "")
                  : "")
              : "Recherche " + (tabIndex + 1)
          }`,

          id: cuid(),
          closeable: true,
        },
      ],
      activeTab: items.length,
      tabNumber: tabIndex + 1,
    };
    localStorage.setItem("tabs", JSON.stringify(newState));

    return newState;
  },
  edit: (tabs, payload) => {
    const { items, activeTab, tabNumber } = tabs;
    const { tabName } = payload;

    const tabIndex = tabNumber || 0;
    items[activeTab].tab = tabName;

    const newState = {
      items: items,
      activeTab,
      tabNumber: tabIndex,
    };

    localStorage.setItem("tabs", JSON.stringify(newState));

    return newState;
  },
};

const history = {
  push: (tabs, location, history) => {
    const { items, activeTab, tabNumber } = tabs;
    const { pathname, push } = location;

    const tabIndex = tabNumber || 0;
    if (location.pathname !== PATHS.CATALOG && items.length <= MAX_OPNED_TABS) {
      return action.add(tabs, location, history);
    } else {
      push(PATHS.CATALOG);
      const newState = {
        items: items.map((item, index) =>
          activeTab !== index
            ? item
            : {
                ...item,
                history: [
                  ...item.history.slice(0, item.activeHistory + 1),
                  history,
                ],
                activeHistory: item.activeHistory + 1,
                tab: `${
                  history?.props?.search?.forcedName
                    ? history?.props?.search?.forcedName
                    : history?.props?.search?.type === "direct"
                    ? history.props.search.reference
                    : history?.props?.search?.type === "supplierAssembly"
                    ? history.props.search.assemblyGroupBean.name +
                      " (" +
                      history.props.search.brandBean.name +
                      ")"
                    : history?.props?.search?.type === "articlesAssembly"
                    ? history.props.search.assemblyGroupBean.name +
                      " (Critère: " +
                      history.props.search.critereBean.name +
                      " = " +
                      history.props.search.critereBean.formattedValue +
                      ")"
                    : history?.props?.search?.type === "mdd" ||
                      history?.props?.search?.type === "catalog"
                    ? history.props.search.code
                    : history?.props?.search?.manufacturerBean
                    ? history.props.search.manufacturerBean.name +
                      (history?.props?.search?.modelBean
                        ? " > " +
                          history.props.search.modelBean.name +
                          (history?.props?.search?.motorizationBean
                            ? " > " +
                              history.props.search.motorizationBean.name +
                              (history?.props?.search?.assemblyGroup
                                ? " > " +
                                  history.props.search.assemblyGroup.name
                                : "")
                            : "")
                        : "")
                    : item.tab
                }`,
              }
        ),
        activeTab,
        tabNumber: tabIndex,
      };
      localStorage.setItem("tabs", JSON.stringify(newState));

      return newState;
    }
  },
  update: (tabs, props) => {
    const { items, activeTab, tabNumber } = tabs;

    const tabIndex = tabNumber || 0;
    const newState = {
      items: items.map((item, index) =>
        index !== activeTab
          ? item
          : {
              ...item,
              history: item.history.map((element, indx) =>
                indx !== item.activeHistory
                  ? element
                  : {
                      ...element,
                      props: {
                        ...element.props,
                        ...props,
                      },
                    }
              ),
            }
      ),
      activeTab,
      tabNumber: tabIndex,
    };
    localStorage.setItem("tabs", JSON.stringify(newState));
    return newState;
  },
  back: (tabs) => {
    const { items, activeTab, tabNumber } = tabs;
    const tabIndex = tabNumber || 0;
    if (items[activeTab].activeHistory === 0) {
      localStorage.setItem("tabs", JSON.stringify(tabs));
      return tabs;
    }
    const newState = {
      items: items.map((item, index) =>
        index !== activeTab
          ? item
          : {
              ...item,
              tab: item.tab,
              activeHistory: item.activeHistory - 1,
            }
      ),
      activeTab,
      tabNumber: tabIndex,
    };
    localStorage.setItem("tabs", JSON.stringify(newState));

    return newState;
  },

  initTab: (tabs) => {
    const { items, activeTab, tabNumber } = tabs;
    const tabIndex = tabNumber || 0;
    if (items[activeTab].activeHistory === 0) {
      localStorage.setItem("tabs", JSON.stringify(tabs));
      return tabs;
    }
    const newState = {
      items: items.map((item, index) =>
        index !== activeTab
          ? item
          : {
              ...item,
              tab: item.history[0].screen,
              activeHistory: 0,
            }
      ),
      activeTab,
      tabNumber: tabIndex,
    };
    localStorage.setItem("tabs", JSON.stringify(newState));
    return newState;
  },
  next: (tabs) => {
    const { items, activeTab, tabNumber } = tabs;
    const tabIndex = tabNumber || 0;
    if (
      items[activeTab].activeHistory ===
      items[activeTab].history.length - 1
    ) {
      localStorage.setItem("tabs", JSON.stringify(tabs));

      return tabs;
    }
    const newState = {
      items: items.map((item, index) =>
        index !== activeTab
          ? item
          : {
              ...item,
              tab: item.tab,
              activeHistory: item.activeHistory + 1,
            }
      ),
      activeTab,
      tabNumber: tabIndex,
    };
    localStorage.setItem("tabs", JSON.stringify(newState));

    return newState;
  },
};
function reducer(state, { type, payload, location }) {
  switch (type) {
    case TABS_ACTIONS.ADD_TAB:
      return action.add(state, location, payload);
    case TABS_ACTIONS.EDIT_TAB:
      return action.edit(state, payload);
    case TABS_ACTIONS.CLOSE_TAB:
      return action.close(state, payload);
    case TABS_ACTIONS.CLEAR:
      return action.clear();
    case TABS_ACTIONS.SWITCH_TAB:
      return action.change(state, payload);
    case TABS_ACTIONS.HISTORY_PUSH:
      return history.push(state, location, payload);
    case TABS_ACTIONS.HISTORY_BACK:
      return history.back(state);
    case TABS_ACTIONS.HISTORY_INIT:
      return history.initTab(state);
    case TABS_ACTIONS.HISTORY_NEXT:
      return history.next(state);
    case TABS_ACTIONS.HISTORY_UPDATE:
      return history.update(state, payload);

    default:
      throw new Error();
  }
}
export const useTab = (INIT_STATE) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  return {
    dispatch: (args) => dispatch({ ...args, location: { pathname, push } }),
    tabs: state,
  };
};
