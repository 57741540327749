import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import React from "react";
import { ManufacturersQueries } from "../shared/graphql/queries";

const { CARS } = ManufacturersQueries;

const Cars = ({ View, filter, ...props }) => {
  const query = useQuery(CARS, {
    variables: filter,
  });
  return (
    <View
      {...{
        ...props,
        ...query,
        type: "cars",
      }}
    />
  );
};

Cars.propTypes = {
  View: PropTypes.func.isRequired,
  filter: PropTypes.object,
};

Cars.defaultProps = {
  filter: {},
};

export default Cars;
