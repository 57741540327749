import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import React from "react";
import { ManufacturersQueries } from "../shared/graphql/queries";

const { MODEL } = ManufacturersQueries;

const Model = ({ View, filter, options, ...props }) => {
  const query = useQuery(MODEL, {
    variables: filter,
    ...options,
  });

  return (
    <View
      {...{
        ...props,
        ...query,
        type: "model",
        meta: "object",
      }}
    />
  );
};

Model.propTypes = {
  View: PropTypes.func.isRequired,
  filter: PropTypes.object,
};

Model.defaultProps = {
  filter: {},
};

export default Model;
