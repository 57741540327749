import { useLazyQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import React from "react";
import { ManufacturersQueries } from "../shared/graphql/queries";

const { ASSEMBLY_GROUPS } = ManufacturersQueries;

const AssemblyGroups = ({ View, ...props }) => {
  const [executeQuery, query] = useLazyQuery(ASSEMBLY_GROUPS);

  return (
    <View
      {...{
        ...props,
        executeQuery,
        ...query,
        type: "assemblyGroups",
      }}
    />
  );
};

AssemblyGroups.propTypes = {
  View: PropTypes.func.isRequired,
};

AssemblyGroups.defaultProps = {};

export default AssemblyGroups;
