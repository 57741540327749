import { Icon } from "antd";
import moment from "moment";
import React from "react";
import { DATE_FILTER_FORMAT } from "../../../../utils/constants";
const JournalItem = (props) => {
  const { journal } = props;

  const convertEnglishDateTimeToDateTimeTitreString = (date) => {
    if (!date) return "-";

    var yyyy = Number(date.match(/^(\d+)/)[1]);
    var MM = Number(date.match(/-(\d+)/)[1]);
    var dd = Number(date.match(/-(\d+) /)[1]);

    var HH = Number(date.match(/ (\d+)/)[1]);
    var mm = Number(date.match(/:(\d+)/)[1]);

    var index = -2;

    if (
      dd === new Date().getDate() &&
      MM === new Date().getMonth() + 1 &&
      yyyy === new Date().getFullYear()
    )
      index = 0;
    else if (
      dd === new Date().getDate() - 1 &&
      MM === new Date().getMonth() + 1 &&
      yyyy === new Date().getFullYear()
    )
      index = -1;

    yyyy = yyyy.toString();
    dd = dd.toString();
    MM = MM.toString();
    HH = HH.toString();
    mm = mm.toString();

    if (dd < 10) dd = "0" + dd;

    if (MM < 10) MM = "0" + MM;

    if (HH < 10) HH = "0" + HH;

    if (mm < 10) mm = "0" + mm;
    var dateString = "";

    dateString =
      index === -1
        ? "Hier"
        : index === 0
          ? "Aujourd'hui"
          : dd + "-" + MM + "-" + yyyy;

    return dateString + " " + HH + ":" + mm;
  };

  return (
    <div
      className="div-nav-notification"
      onClick={() => {
        const win = window.open(journal.url, "_blank");
        win.focus();
      }}
    >
      <span>
        <img
          className="image-notification"
          src={
            journal.imageUrl
              ? journal.imageUrl
              : "https://upload.wikimedia.org/wikipedia/commons/a/af/Image_non_disponible.png"
          }
          alt="Notification"
        />
      </span>
      <div className="div-vertical" style={{ flexGrow: 1 }}>
        <div className="div-horizontal center">
          <label className="titre-notification" style={{ direction: "auto" }}>
            {journal.titre}
          </label>
          <Icon style={{ fontSize: 22 }} type="bell" />
        </div>
        <label
          className="description-notification"
          style={{ direction: "auto" }}
        >
          {journal.description}
        </label>
        <label className="date-notification">
          {"Equipe GAP · " +
            convertEnglishDateTimeToDateTimeTitreString(
              moment(journal.date).format(DATE_FILTER_FORMAT)
            )}
        </label>
      </div>
    </div>
  );
};

export default JournalItem;
