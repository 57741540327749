import gql from "graphql-tag";

export const ADDRESSES = gql`
  query Addresses($where: AddressWhereInput) {
    addresses(where: $where) {
      data {
        id
        name
        type
        account {
          id
          title
          description
          city
          address
          firstName
          lastName
          birthday
          company
          idFiscal
          patent
          rc
          ice
          cnss
          agency
          referent
        }
      }
      count
    }
  }
`;

export default {
  ADDRESSES,
};
