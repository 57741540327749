import { HeartOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";

export default ({ data, count }) => {
  return (
    <Link to="/wish-list-items">
      <HeartOutlined />
      <span className="wishlist_count">{count > 99 ? "+99" : count}</span>
    </Link>
  );
};
