import gql from "graphql-tag";

export const LOCAL_ARTICLES = gql`
  query LocalArticles(
    $where: LocalArticlesWhereInput
    $isIn: LocalArticlesWhereInput
  ) {
    localArticles(where: $where, isIn: $isIn) @client(always: true) {
      data {
        id
        referenceArticle
        name
        code
        brand {
          id
          name
        }
        attributes {
          id
          name
          shortName
          value
        }
        item {
          id
          code
          name
          catalogCode
          manufacturerCode
          oem
          prices {
            id
            value
          }
          discount {
            id
            discountLines {
              id
              min
              max
              startDate
              endDate
              value
              account {
                title
                id
              }
            }
          }
          itemWareHouses {
            id
            quantity
            wareHouse {
              id
              name
              code
              city
            }
          }
        }
        thumbnails {
          id
          name
          url
        }
        oens {
          id
          blockNumber
          brandName
          oenNumber
          sortNumber
        }
        substitutes {
          id
          reference
        }
        assemblyGroups {
          id
          name
        }
      }
    }
  }
`;
export const LOCAL_BRANDS = gql`
  query LocalBrands(
    $where: LocalBrandsWhereInput
    $isIn: LocalBrandsWhereInput
  ) {
    localBrands(where: $where, isIn: $isIn) @client(always: true) {
      data {
        id
        name
      }
    }
  }
`;

export const LOCAL_ASSEMBLY_GROUPS = gql`
  query LocalAssemblyGroups(
    $where: LocalAssemblyGroupsWhereInput
    $isIn: LocalAssemblyGroupsWhereInput
  ) {
    localAssemblyGroups(where: $where, isIn: $isIn) @client(always: true) {
      data {
        id
        name
      }
    }
  }
`;
export default {
  LOCAL_ARTICLES,
  LOCAL_BRANDS,
  LOCAL_ASSEMBLY_GROUPS,
};
