import get from "lodash/get";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import { cacheQueries } from "../../queries";
const { LOCAL_ARTICLES } = cacheQueries;
export const localBrands = (
  { localBrands },
  { isIn: { assemblyGroups = [] }, where: { ours, ...rest } },
  { cache }
) => {
  if (localBrands) return localBrands;

  const data = cache.readQuery({
    query: LOCAL_ARTICLES,
    variables: { where: rest, isIn: {} },
  });

  const articles = (ours) => {
    const condition = (value) => (ours ? !isEmpty(value) : isEmpty(value));
    return {
      __typename: "localBrands",
      data: Object.entries(
        groupBy(get(data, "localArticles.data", []), (item) => item.brand.name)
      )
        .filter(
          ([key, values]) =>
            condition(
              values.filter(({ item: { id } }) => {
                return id !== -1;
              })
            ) &&
            !isEmpty(
              values.filter(({ assemblyGroups: assGroup }) => {
                const checkAssemmbly = isEmpty(assemblyGroups)
                  ? true
                  : assemblyGroups.reduce((prev, curr) => {
                      if (assGroup.map(({ name }) => name).includes(curr)) {
                        prev = prev + 1;
                      }
                      return prev;
                    }, 0) > 0;

                return checkAssemmbly;
              })
            )
        )
        .map(([key, values]) => ({
          __typename: "LocalBrand",
          id: key,
          name: key,
        })),
    };
  };

  return articles(ours);
};
