/* eslint-disable jsx-a11y/anchor-is-valid */
import { BellOutlined, SyncOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/react-hooks";
import { Icon, Spin, Tabs } from "antd";
import { get } from "lodash";
import moment from "moment";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { QUERY_COUNT_JOURNALS } from "../../../../shared/graphql/queries/journal";
import { StoreContext } from "../../../../shared/store";
import { DATE_FILTER_FORMAT } from "../../../../utils/constants";
import Journal from "./journal";
import Notification from "./notification";
const { TabPane } = Tabs;

const NotificationMenu = () => {
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [count, setCount] = useState(false);

  const { lastSeenNotification, setLastSeenNotification } =
    useContext(StoreContext);
  const [executeQuery, { data, loading }] = useLazyQuery(QUERY_COUNT_JOURNALS, {
    fetchPolicy: "network-only",
  });
  const wrapperRef = useRef(null);
  const buttonRef = useRef(null);

  console.log("lastSeenNotification", lastSeenNotification);
  const location = useLocation();

  useEffect(() => {
    console.log("lastSeenNotification", lastSeenNotification);
    executeQuery({
      variables: {
        journalCountInput: {
          lastSeenNotification: lastSeenNotification,
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setCount(get(data, "journalsCount", 0));
  }, [data]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        console.log("You clicked outside of me!", dropDownVisible);
        if (dropDownVisible) setDropDownVisible(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownVisible, wrapperRef]);

  if (loading) return <Spin />;

  return (
    <Fragment>
      <a
        ref={buttonRef}
        className="ant-dropdown-link"
        onClick={(e) => {
          e.preventDefault();
          if (!dropDownVisible) {
            setLastSeenNotification(moment.utc().format(DATE_FILTER_FORMAT));
            setCount(0);
          }

          setDropDownVisible(!dropDownVisible);
        }}
      >
        <BellOutlined />
        <span className="wishlist_count">{count > 99 ? "+99" : count}</span>
      </a>
      <div
        className="nav-dropmenu notification"
        style={{ display: dropDownVisible ? "flex" : "none" }}
        ref={wrapperRef}
      >
        <div
          className="titre-div-horizontal"
          style={{ borderBottom: "1px solid #ddd" }}
        >
          <h3 style={{ margin: 0, flexGrow: 1 }}>Notifications</h3>
          <SyncOutlined
            onClick={() => {
              executeQuery({
                variables: {
                  journalCountInput: {
                    lastSeenNotification: lastSeenNotification,
                  },
                },
              });
            }}
          />
        </div>
        <Tabs defaultActiveKey={"notifications"}>
          <TabPane
            tab={
              <span className="div-horizontal center">
                <Icon style={{ fontSize: 22 }} type="bell" /> Notifications
              </span>
            }
            key="notifications"
          >
            <Notification />
          </TabPane>

          <TabPane
            tab={
              <span className="div-horizontal center">
                <Icon style={{ fontSize: 22 }} type="history" /> Journal
              </span>
            }
            key="journal"
          >
            <Journal />
          </TabPane>
        </Tabs>
      </div>
    </Fragment>
  );
};

export default NotificationMenu;
