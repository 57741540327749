import gql from "graphql-tag";

export const CREATE_STOCK = gql`
  mutation CreateStock($data: StockWhereInput) {
    createStock(data: $data) {
      id
      quantity
      price {
        id
        value
      }
      deleted
      createdDate
      updatedDate
      arrivalDate
      discount {
        id
        value
      }
      supplier {
        id
        name
      }
      item {
        id
        prices {
          id
          value
          updatedDate
        }
        catalogCode
      }

      reason
    }
  }
`;
export const UPDATE_STOCK = gql`
  mutation UpdateStock($data: StockWhereInput) {
    updateStock(data: $data) {
      id
      quantity
      price {
        id
        value
      }
      discount {
        id
        value
      }
      deleted
      createdDate
      updatedDate
      arrivalDate
      supplier {
        id
        name
      }

      item {
        id
        prices {
          id
          value
          updatedDate
        }
        catalogCode
      }

      reason
    }
  }
`;
export const DELETE_STOCK = gql`
  mutation DeleteStock($data: StockWhereInput) {
    deleteStock(data: $data) {
      id
      quantity
      price {
        id
        value
      }
      item {
        id
        prices {
          id
          value
          updatedDate
        }
        catalogCode
      }
      discount {
        id
        value
      }
      deleted
      createdDate
      updatedDate
      arrivalDate
      supplier {
        id
        name
      }

      reason
    }
  }
`;
export const CREATE_STOCKS = gql`
  mutation CreateStocks($items: [StockWhereInput]) {
    createStocks(items: $items) {
      id
      quantity
      price {
        id
        value
      }
      discount {
        id
        value
      }
      deleted
      item {
        id
        prices {
          id
          value
          updatedDate
        }
        catalogCode
      }
      createdDate
      updatedDate
      arrivalDate
      supplier {
        id
        name
      }

      reason
    }
  }
`;
export const UPDATE_STOCKS = gql`
  mutation UpdateStocks($items: [StockWhereInput]) {
    updateStocks(items: $items) {
      id
      quantity
      price {
        id
        value
      }
      deleted
      item {
        id
        prices {
          id
          value
          updatedDate
        }
        catalogCode
      }
      discount {
        id
        value
      }
      createdDate
      updatedDate
      arrivalDate
      supplier {
        id
        name
      }

      reason
    }
  }
`;
export const DELETE_STOCKS = gql`
  mutation DeleteStock($items: [StockWhereInput]) {
    deleteStocks(items: $items) {
      id
      quantity
      price {
        id
        value
      }
      item {
        id
        prices {
          id
          value
          updatedDate
        }
        catalogCode
      }
      deleted
      discount {
        id
        value
      }
      createdDate
      updatedDate
      arrivalDate
      supplier {
        id
        name
      }

      reason
    }
  }
`;
export default {
  CREATE_STOCK,
  UPDATE_STOCK,
  DELETE_STOCK,
  CREATE_STOCKS,
  UPDATE_STOCKS,
  DELETE_STOCKS,
};
