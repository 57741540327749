import gql from "graphql-tag";

export const DISCOUNTS = gql`
  query Discounts(
    $where: DiscountWhereInput
    $like: DiscountWhereInput
    $take: Int
    $skip: Int
  ) {
    discounts(where: $where, like: $like, take: $take, skip: $skip) {
      data {
        id
        name
        discountLines {
          id
          min
          max
          startDate
          endDate
          value
        }
      }
      count
    }
  }
`;

export default {
  DISCOUNTS,
};
