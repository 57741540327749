import { Checkbox, Collapse, List } from "antd";
import React from "react";

const { Panel } = Collapse;

const NouveauTopVente = ({
  nouveauTopVente,
  setNouveauTopVente,
  nouveauTopVenteDataSource,
  search: where = {},
}) => {
  return (
    <>
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIconPosition={"right"}
      >
        <Panel
          header="Nouveau & Top vente"
          key="1"
          className="collapse_filter not-last"
        >
          <div className="widget_list widget_categories ">
            <div className="vehicles_scroll" style={{ padding: "10px" }}>
              <List
                className="empty_list_cross"
                dataSource={nouveauTopVenteDataSource}
                renderItem={(item) => {
                  return (
                    <List.Item>
                      <Checkbox
                        checked={
                          nouveauTopVente.filter(
                            ({ key, value }) => key === item.key
                          ).length > 0
                        }
                        onChange={() => {}}
                        onClick={() => {
                          nouveauTopVente.filter(
                            ({ key, value }) => key === item.key
                          ).length > 0
                            ? setNouveauTopVente(
                                nouveauTopVente.filter(
                                  (element) => element.key !== item.key
                                )
                              )
                            : setNouveauTopVente([...nouveauTopVente, item]);
                        }}
                      >
                        <span className={"listStyle"}>
                          {item.value + " (" + item.count + ")"}
                        </span>
                      </Checkbox>
                    </List.Item>
                  );
                }}
              />
            </div>
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default React.memo((props) => {
  const {
    groups: { type },
  } = props.filter;

  return <NouveauTopVente {...props} />;
});
