import { useQuery } from "@apollo/react-hooks";
import { Checkbox, Collapse, Input, List, Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { Fragment, useEffect, useState } from "react";
import {
  ManufacturersQueries,
  cacheQueries,
} from "../../../../../../shared/graphql/queries";

const { LOCAL_ASSEMBLY_GROUPS } = cacheQueries;
const { Panel } = Collapse;
const { ASSEMBLY_GROUPS, SEARCH } = ManufacturersQueries;

const PanelContent = ({ assemblyGroups, onClick }) => (
  <div className="widget_list widget_categories">
    <Fragment>
      <List
        dataSource={assemblyGroups}
        renderItem={(item) => (
          <Tooltip placement="topLeft" title={item.id} arrowPointAtCenter>
            <List.Item className="listStyle" onClick={() => onClick(item)}>
              {item.name}
            </List.Item>
          </Tooltip>
        )}
      />
    </Fragment>
  </div>
);

const Article = ({
  assemblyGroups,
  setAssemblyGroups,
  assemblyGroupsList,
  oems,
  search: where = {},
}) => {
  const [input, setInput] = useState("");
  const { data } = useQuery(LOCAL_ASSEMBLY_GROUPS, {
    variables: {
      where,
      isIn: {
        ...(!isEmpty(oems) && { oems }),
      },
    },
    fetchPolicy: "cache-first",
  });

  const { data: ours } = useQuery(LOCAL_ASSEMBLY_GROUPS, {
    variables: {
      where: { ours: true, ...where },
      isIn: {
        ...(!isEmpty(oems) && {
          oems: oems,
        }),
      },
    },
    fetchPolicy: "cache-first",
  });

  //const assemblyGroupsList = get(data, "localAssemblyGroups.data", []);
  useEffect(() => {
    setInput("");
    setAssemblyGroups(
      assemblyGroups.filter((ass) =>
        assemblyGroupsList.map(({ name }) => name).includes(ass)
      )
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIconPosition={"right"}
      >
        <Panel header="Familles" key="1" className="collapse_filter not-last">
          <div className="widget_list widget_categories ">
            <div style={{ margin: "16px 10px" }}>
              <Input
                value={input}
                placeholder={"Rechercher une famille"}
                onChange={(_) => {
                  setInput(_.target.value);
                }}
              />
            </div>

            <div className="vehicles_scroll" style={{ padding: "10px" }}>
              <List
                className="empty_list_cross"
                dataSource={assemblyGroupsList.filter(({ name }) =>
                  input
                    ? name.toLowerCase().includes(input.toLowerCase())
                    : true
                )}
                renderItem={(item) => {
                  return (
                    <List.Item>
                      <Checkbox
                        checked={assemblyGroups.includes(item.name)}
                        onClick={() => {
                          setInput("");
                          assemblyGroups.includes(item.name)
                            ? setAssemblyGroups(
                                assemblyGroups.filter(
                                  (element) => element !== item.name
                                )
                              )
                            : setAssemblyGroups([...assemblyGroups, item.name]);
                        }}
                        onChange={() => {}}
                      >
                        <span
                          className={
                            "listStyle" +
                            (item.local === "our" ? " our-brand" : "")
                          }
                        >
                          {item.name.charAt(0).toUpperCase() +
                            item.name.slice(1)}
                        </span>
                      </Checkbox>
                    </List.Item>
                  );
                }}
              />
            </div>
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default React.memo((props) => {
  const {
    groups: { type },
  } = props.filter;

  return <Article {...props} />;
});
