import gql from "graphql-tag";

export const SUPPORTS = gql`
  query Supports($where: SupportWhereInput) {
    supports(where: $where) {
      data {
        id
        type
        context
        appointed
        user {
          id
          account {
            id
            title
          }
        }
      }
      count
    }
  }
`;

export default {
  SUPPORTS,
};
