import { Modal, Tabs } from "antd";
import React, { useState } from "react";
import Motor from "../../../screens/Customer/Catalog/GlobalSearch/Vehicles/Motor";
import Vin from "../../../screens/Customer/Catalog/GlobalSearch/Vehicles/Vin";

import Critere from "../../../screens/Customer/Catalog/GlobalSearch/Vehicles/Critere";
import Search from "./Search";

const { TabPane } = Tabs;

const ModalTabs = (props) => {
  const [activeTab, setActiveTable] = useState("vehicles");
  console.log("props", props);
  return (
    <Tabs
      defaultActiveKey={"vehicles"}
      className="text-center "
      onChange={(tab) => setActiveTable(tab)}
    >
      <TabPane tab={<span>Chercher par véhicule</span>} key="vehicles">
        <div style={{ padding: "15px" }}>
          <Search {...props} />
        </div>
      </TabPane>
      <TabPane
        tab={<span>Chercher par Vin</span>}
        className="tapSwitch"
        key="Vin"
      >
        <div
          style={{
            padding: "15px",
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <Vin />
        </div>
      </TabPane>

      <TabPane
        tab={<span>Chercher par Code moteur</span>}
        key="Car"
        className="tapSwitch"
      >
        <div style={{ padding: "15px", paddingBottom: 0, paddingTop: 0 }}>
          <Motor {...props} active={activeTab === "Car"}></Motor>
        </div>
      </TabPane>

      {props.assemblyGroupId && (
        <TabPane
          tab={<span>Chercher par Critère</span>}
          key="Critere"
          className="tapSwitch"
        >
          <div
            style={{
              padding: "15px",
              paddingBottom: 0,
              height: "100%",
              paddingTop: 0,
            }}
          >
            <Critere {...props} />
          </div>
        </TabPane>
      )}
    </Tabs>
  );
};

export default (props) => (
  <Modal
    visible={props.visible}
    footer={null}
    onCancel={() => props.setVisible(false)}
    width={1000}
    bodyStyle={{ height: 320 }}
    centered
  >
    <ModalTabs {...props}></ModalTabs>
  </Modal>
);
