import React from "react";
import Heading from "../../../shared/components/stories/Heading";

export default () => {
  return (
    <div className="privacy_policy_bg">
      <div className="container">
        <div className="privacy_policy_main_area">
          <div className="row">
            <div className="col-12">
              <div className="privacy_policy_header">
                <Heading title="Termes et conditions" />
              </div>
              <div className="privacy_content">
                <h2>PREAMBULE</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Le Vendeur exerce une activité de commerce électronique et
                  propose un service de vente de Produits en ligne sur le site
                  Internet www.gap-maroc.com. Les présentes conditions générales
                  (ci-après dénommées « Conditions ») sont exclusivement
                  réservées aux Acheteurs professionnels agissant en relation
                  directe avec leur exercice professionnel.
                </p>
              </div>
              <div className="privacy_content ">
                <h2>ARTICLE 1 – DEFINITIONS</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Les termes utilisés au sein des Conditions auront la
                  signification qui leur est donnée ci-après :
                </p>
                <h3>Acheteur</h3>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Personne physique ou morale exerçant la profession de
                  revendeurs de pièces de rechange et acquérant des Produits par
                  le biais du Site en vue de l'exercice de sa profession.
                </p>
                <h3>Commande</h3>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Ordre d’achat de l’Acheteur portant sur un ou plusieurs
                  produits et acceptépar le Vendeur en application des
                  Conditions.
                </p>
                <h3>Courriel</h3>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Document informatisé qu'un utilisateur saisit, envoie ou
                  consulte en différé parl'intermédiaire d'un réseau de
                  télécommunication.
                </p>
                <h3>Internet</h3>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  réseau mondial associant des ressources de télécommunication
                  et desordinateurs serveurs et clients, destiné à l'échange de
                  messages électroniques, d'informations multimédias et de
                  fichiers. Il fonctionne en utilisant un protocole commun qui
                  permet l'acheminement de proche en proche de messages découpés
                  en paquets indépendants.
                </p>
                <h3>Partie(s)</h3>
                <p>L’Acheteur et/ou le Vendeur.</p>
                <h3>Produit(s)</h3>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Bien(s) proposé(s) à la vente sur le Site par le Vendeur, les
                  descriptifs, ficheséventuelles présentant sur le Site les
                  Produits et photographies n'entrant pas dans le champ
                  contractuel et ne constituent qu'une présentation des
                  Produits.
                </p>
                <h3>Site</h3>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Site Internet accessible à l’adresse{" "}
                  <a href="www.gap-maroc.com" target="_blank">
                    www.gap-maroc.com
                  </a>
                  sur lequel le Vendeur propose les Produits à la vente.
                </p>
                <h3>Vendeur</h3>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  GAP, Boulevard Ahl Loghlam, Route de Zenata, Sidi Bernoussi,
                  Casablanca, S.A. au capital social de 4 000 000 DHS,
                  RC.............., TVA.............. . tél.
                  :......................., e-mail :.......................
                </p>
              </div>
              <div className="privacy_content">
                <h2>ARTICLE 2 – OBJET</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Les Conditions ont pour objet de définir les droits et
                  obligations du Vendeur et de l’Acheteur dans le cadre de la
                  vente des Produits par le biais du Site.
                </p>
              </div>
              <div className="privacy_content">
                <h2>ARTICLE 3 – CHAMP D’APPLICATION</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Sauf conditions particulières conclues entre les Parties, les
                  Conditions s’appliquent à toutes les ventes de Produits par le
                  Vendeur à l’Acheteur résultant de commandes effectuées par le
                  biais du Site, après acceptation préalable des Conditions par
                  l’Acheteur.
                  <br />
                  L’Acheteur reconnaît que les Conditions sont mises à sa
                  disposition d’une manière permettant leur conservation et leur
                  reproduction, <br />
                  Les Conditions prévalent sur les conditions généralesd’achat.
                </p>
              </div>
              <div className="privacy_content">
                <h2>ARTICLE 4 – COMMANDE</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  L’Acheteur déclare avoir pris connaissance des Conditions
                  préalablement à la passation de sa Commande et reconnaît que
                  la validation de sa Commande implique l’acceptation de leurs
                  termes.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  L’Acheteur passe sa commande par le biais du Site en
                  fournissant au Vendeur les données le concernant, en
                  remplissant le formulaire en ligne accessible sur le Site et
                  en communiquant son adresse électronique exacte.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Jusqu’à la validation de la Commande s’effectuant en cliquant
                  sur le bouton "Valider", valant signature électronique de la
                  Commande, l’Acheteur a la possibilité de revenir aux pages
                  précédentes et de corriger et modifier sa Commande et les
                  informations fournies préalablement.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Un Courriel de confirmation, accusant réception de la Commande
                  et reprenant l’ensemble de ces informations, sera alors envoyé
                  à l’Acheteur dans les plus brefs délais.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Le contrat de vente entre le Vendeur et l’Acheteur est formé
                  lorsque l’Acheteur clique sur le bouton «Valider » lors de la
                  confirmation de sa Commande,{" "}
                  <b>
                    le transfert de propriété du Produit commandé au profit de
                    l'Acheteur n'ayant toutefois lieu qu'après complet
                    encaissement du prix par le Vendeur.
                  </b>
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Toute Commande donne lieu à l’établissement d’une facture
                  délivrée à l’Acheteur dès la formation du contrat de vente.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  L’ensemble des informations contractuelles est présenté en
                  langue française et fera l’objet d’une confirmation au plus
                  tard au moment de la livraison.
                </p>
              </div>
              <div className="privacy_content">
                <h2>ARTICLE 5 – PRIX ET PAIEMENT</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Les prix des Produits indiqués sur les pages du Site
                  correspondent aux prix bruts, hors taxes et transport non
                  compris, valables uniquement au Maroc.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Les frais de port sont forfaitaires et selon le transport
                  demandé ou ceux contractuel avec GAP. Pour toute commande
                  supérieure à 25 000 dhs, les frais de port sont offerts.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Les Produits seront facturés à l’Acheteur sur la base des
                  tarifs en vigueur au moment de la formation du contrat de
                  vente résultant de la validation de la Commande par
                  l'Acheteur.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Le règlement de la Commande s’effectuera selon les modalités
                  de paiement convenues sur système :
                </p>
              </div>
              <div className="privacy_content">
                <h2>ARTICLE 6 – LIVRAISON – DELAI</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Le Vendeur s’engage à traiter la Commande dans le délai de 2
                  jours ouvrables à compter du jour suivant la validation de la
                  Commande, à l’exception de certaines catégories de produits
                  non gérées en stock dont les articles personnalisés. Le
                  dépassement de ce délai ne saurait entraîner une quelconque
                  pénalité de retard.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Les délais de livraison indiqués lors de la Commande sont
                  purement indicatifs et dépendent des possibilités
                  d’approvisionnement et de transport.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  La livrais on s’effectue uniquement au Maroc. La livraison est
                  effectuée à l'adresse indiquée par l’Acheteur lors de sa
                  Commande.
                </p>
              </div>
              <div className="privacy_content">
                <h2>ARTICLE 7 - RECEPTION</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  En cas d’avarie ou de perte partielle des Produits, il
                  appartient à l’Acheteur de formuler des réserves auprès du
                  transporteur et d'en informer immédiatement le Vendeur.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  L’Acheteur reconnaît que toute action à l’encontre du
                  transporteur et du Vendeur en cas d'avarie ou perte de
                  Produits est éteinte si l'Acheteur n’a pas, par acte
                  extrajudiciaire ou lettre recommandée, notifié sa réclamation
                  motivée au transporteur dans les trois jours suivant la
                  réception des Produits et informé le Vendeur par lettre
                  recommandée, dans le même délai.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Non obstant les cas d'avaries ou de perte des produits par le
                  transporteur, l’Acheteur peut former des réclamations auprès
                  du Vendeur, dans les 3 jours suivant la réception{" "}
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Des Produits, en cas de vices apparents, de Produits manquants
                  ou de non-conformité{" "}
                </p>
                <p>Des Produits livrés aux spécifications de la Commande.</p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Cette réclamation doit être formée par l’envoi au Vendeur
                  d’une lettre recommandée avec avis de réception.
                </p>
              </div>

              <div className="privacy_content">
                <h2>ARTICLE 8 – RESPONSABILITE</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Le Vendeur est responsable de plein droit à l’égard de
                  l’Acheteur de la bonne exécution de la Commande. Toutefois, la
                  responsabilité du Vendeur ne saurait être engagée s’il apporte
                  la preuve que l’inexécution ou la mauvaise exécution de ses
                  obligations est imputable à l’Acheteur, au fait imprévisible
                  et insurmontable d’un tiers étranger à la fourniture des
                  prestations prévues aux Conditions, ou à un cas de force
                  majeure. Le Vendeur ne saurait engager sa responsabilité pour
                  des dommages résultant d’une faute de l’Acheteur dans le cadre
                  de l’usage ou du stockage des Produits.
                </p>
              </div>

              <div className="privacy_content">
                <h2>ARTICLE 9 – PROPRIETE INTELLECTUELLE</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  L’ensemble des éléments édités au sein du Site, tels que sons,
                  images, Photographies, vidéos, écrits, animations, programmes,
                  charte graphique, utilitaires, bases de données, logiciels,
                  marques est protégé par les dispositions du Code de la
                  propriété intellectuelle et appartiennent au Vendeur.
                  L’Acheteur s’interdit de porter atteinte aux droits de
                  propriété intellectuelle afférents à ces éléments et notamment
                  de les reproduire, représenter, modifier, adapter, traduire,
                  d’en extraire et/ou de réutiliser une partie qualitativement
                  ou quantitativement substantielle, à l’exclusion des actes
                  nécessaires à leur usage normal et conforme ou de ceux
                  expressément et préalablement autorisés par le Vendeur. Le
                  Vendeur interdit toute apposition d’un lien hypertexte à
                  destination du Site, sans autorisation expresse et préalable.
                </p>
              </div>

              <div className="privacy_content">
                <h2>ARTICLE 10 – DONNEES PERSONNELLES</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  L’Acheteur est informé que, lors de sa navigation et dans le
                  cadre de la Commande, des données à caractère personnel le
                  concernant sont collectées et traitées par le Vendeur en tant
                  que responsable du traitement, notamment par le biais du
                  formulaire en ligne destiné à passer la Commande.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  L’Acheteur est informé que la Commande ne pourra être passée
                  si ce formulaire n’est pas correctement rempli .Ce formulaire
                  contient l’indication du caractère facultatif ou obligatoire
                  des champs à renseigner.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Les données ainsi recueillies seront utilisées uniquement par
                  le Vendeur uniquement pour le traitement de la Commande et de
                  ses suites. Elles ne sont pas destinées à être transmises à
                  des tiers.
                </p>
              </div>
              <div className="privacy_content">
                <h2>ARTICLE 11 – CONVENTION SUR LA PREUVE</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Il est expressément convenu que les Parties peuvent
                  communiquer entre elles par voie électronique pour les besoins
                  des Conditions.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Les deux Parties conviennent que sauf dispositions
                  particulières prévues dans les Conditions, les Courriels
                  échangés entre elles prouvent valablement la teneur de leurs
                  échanges et, le cas échéant, de leurs engagements, notamment
                  en ce qui concerne la transmission et l’acceptation de
                  Commandes.
                </p>
              </div>
              <div className="privacy_content">
                <h2>ARTICLE 12 – INVALIDITE PARTIELLE</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Si une ou plusieurs des stipulations des Conditions étaient
                  jugées illicites ou nulles, cette nullité n’aurait pas pour
                  effet d’entraîner la nullité des autres dispositions de ces
                  Conditions, sauf si ces dispositions présentaient un caractère
                  indissociable avec la stipulation invalidée.
                </p>
              </div>
              <div className="privacy_content">
                <h2>ARTICLE 13 – LOI APPLICABLE</h2>
                <p>Les Conditions sont régies par la loi marocaine.</p>
              </div>
              <div className="privacy_content">
                <h2>ARTICLE 14 – ATTRIBUTION DE COMPETENCE</h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Les Parties conviennent qu’en cas de litige pouvant surgir
                  concernant l’exécution ou l’interprétation des Conditions,
                  elles s’efforceront de trouver une solution amiable.
                </p>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  En cas d’échec de cette tentative de résolution amiable du
                  différend, celui-ci sera porté devant le tribunal de commerce
                  de Casablanca.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
