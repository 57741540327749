import gql from "graphql-tag";

export const CREATE_CONTACT_IDENTIFIER = gql`
  mutation createContactIdentifier($data: ContactIdentifierWhereInput) {
    createContactIdentifier(data: $data) {
      id
      identifier
    }
  }
`;

export const UPDATE_CONTACT_IDENTIFIER = gql`
  mutation updateContactIdentifier(
    $where: ContactIdentifierWhereInput
    $data: ContactIdentifierWhereInput
  ) {
    updateContactIdentifier(where: $where, data: $data) {
      id
      isDefault
      identifier
    }
  }
`;

export const SET_DEFAULT_CONTACT_IDENTIFIER = gql`
  mutation setDefaultContactIdentifier($data: ContactIdentifierWhereInput) {
    setDefaultContactIdentifier(data: $data) {
      id
      identifier
      isDefault
    }
  }
`;

export const DELETE_CONTACT_IDENTIFIER = gql`
  mutation deleteContactIdentifier(
    $data: ContactIdentifierWhereInput
  ) {
    deleteContactIdentifier(data: $data) {
      id
      isDefault
      identifier
    }
  }
`;

export default {
  CREATE_CONTACT_IDENTIFIER,
  UPDATE_CONTACT_IDENTIFIER,
  SET_DEFAULT_CONTACT_IDENTIFIER,
  DELETE_CONTACT_IDENTIFIER,
};
