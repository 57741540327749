import { Collapse } from "antd";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import { TabContext } from "../../../../../../../Context/Tab";
import { SCREENS, TABS_ACTIONS } from "../../../../../../../utils/constants";

const { Panel } = Collapse;

export default React.memo(({ article }) => {
  const { dispatch } = useContext(TabContext);

  const { usageNumbers } = article;
  return (
    <div className="descriptions-div">
      <Collapse
        bordered={false}
        expandIconPosition={"right"}
        defaultActiveKey={["1"]}
      >
        <Panel header={"Numéros d'utilisation"} key="1">
          {isEmpty(usageNumbers) ? (
            <h4 className="not-found-message">
              Aucun numéro d'utilisation trouvé
            </h4>
          ) : (
            <div className="div-horizontal center wrapped">
              {usageNumbers.map((usageNumber) => {
                return (
                  <span
                    className="tag-span"
                    style={{ marginBottom: 10, cursor: "pointer" }}
                    onClick={() => {
                      dispatch({
                        type: TABS_ACTIONS.HISTORY_PUSH,
                        payload: {
                          screen: SCREENS.ARTICLES,
                          props: {
                            search: {
                              reference: usageNumber.code,
                              type: "trade",
                            },
                          },
                        },
                      });
                    }}
                  >
                    {usageNumber.code}
                  </span>
                );
              })}
            </div>
          )}
        </Panel>
      </Collapse>
    </div>
  );
});
