import Loadable from "react-loadable";
import Loading from "../../../../shared/components/Loading";

const List = Loadable({
  loader: () => import("./List"),
  loading: Loading,
});
const Create = Loadable({
  loader: () => import("./Create"),
  loading: Loading,
});
const Delete = Loadable({
  loader: () => import("./Delete"),
  loading: Loading,
});

const DeleteMany = Loadable({
  loader: () => import("./DeleteMany"),
  loading: Loading,
});

const routes = [
  {
    path: "/wish-list-items",
    exact: true,
    component: List,
  },
  {
    path: "/wish-list-items/create",
    exact: true,
    component: Create,
  },
  {
    path: `/wish-list-items/delete/many`,
    exact: true,
    component: DeleteMany,
  },
  {
    path: `/wish-list-items/delete/:id`,
    exact: true,
    component: Delete,
  },
];

export default routes;
