import { Modal } from "antd";
import React from "react";

export default ({ selectedPaiement, setSelectedPaiement }) => {
  return (
    <Modal
      className="form-modal"
      title="PRIX ET PAIEMENT"
      visible={selectedPaiement}
      width={800}
      onOk={() => setSelectedPaiement(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => setSelectedPaiement(false)}
    >
      <p>
        Les prix des Produits indiqués sur les pages du Site correspondent aux
        prix nets TTC, incluant la remise de 35% et des remises supplémentaires
        éventuelles. Le transport est non compris.
      </p>
      <p>
        Les frais de port sont forfaitaires et selon le transport demandé ou
        ceux contractuel avec GAP. Pour toute commande supérieure à 25 000 dhs,
        les frais de port sont offerts.
      </p>
      <p>
        Les Produits seront facturés à l'Acheteur sur la base des tarifs en
        vigueur au moment de la formation du contrat de vente résultant de la
        validation de la Commande par l'Acheteur.
        <br />
        Le règlement de la Commande s'effectuera selon les modalités de paiement
        convenues sur système.
      </p>
      <p>
        Les Produits sont fournis aux tarifs en vigueur figurant sur le Site au
        jour de l'enregistrement de la commande par GAP. Les prix sont exprimés
        en dirhams, nets et Toutes Taxes Comprises (TTC). Les prix ne
        comprennent pas les frais d'expédition, de transport et de livraison,
        préalablement indiqués au consommateur, qui sont facturés en supplément
        selon l'option choisie par le Client.
      </p>
      <p>
        GAP se réserve le droit de modifier ses tarifs à tout moment. Les prix
        indiqués sur le site sont ceux en vigueur au jour de l'e-réservation du
        Produit sur le site.
      </p>
      <p>
        GAP garantit que le prix pratiqué en centre est identique à celui
        pratiqué sur le site. Le prix à payer par le Client sera donc celui
        appliqué le jour de l'achat du produit réservé.
      </p>
      <p>
        Pour assurer la disponibilité de nos produits à l'égard de l'ensemble de
        sa clientèle notamment dans le cadre d'opérations promotionnelles, GAP
        se réserve le droit de refuser de traiter tout ou partie d'une
        e-réservation pour motifs légitimes notamment en cas de demande jugée
        anormale au regard des quantités de produits habituellement commandées
        par le Client, en cas d'acte illicite ou frauduleux ou en cas de
        violation par le Client des conditions liées à ce service.
      </p>
      <p>
        Par ailleurs, les indications sur la disponibilité des produits sont
        fournies par GAP au moment de la validation de la réservation par le
        Client. A ce titre, le Client a connaissance du fait que la
        disponibilité des Produits est affichée à titre informatif et que malgré
        la fréquence de mise à jour des stocks, il peut exister un écart entre
        l'information fournie au consommateur et le stock réel de GAP, les
        produits pouvant être vendus entre le moment de la réservation par le
        Client et la validation de cette e-réservation par le Centre GAP.
      </p>
      <p>
        En cas de rupture de stock, le centre GAP en informera alors le Client
        par téléphone ou par courrier électronique pour modifier le contenu de
        la réservation ou procéder à son annulation totale ou partielle au choix
        du Client.
      </p>
    </Modal>
  );
};
