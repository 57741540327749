import gql from "graphql-tag";

export const CONTACT_IDENTIFIERS = gql`
  query ContactIdentifiers($where: ContactIdentifierWhereInput) {
    contactIdentifiers(where: $where) {
      data {
        id
        identifier
        user {
          id
        }
        isDefault
      }
      count
    }
  }
`;

export default {
  CONTACT_IDENTIFIERS
};
