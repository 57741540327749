import { Spin, Tag } from "antd";
import { get } from "lodash";
import React, { useContext } from "react";
import { TabContext } from "../../../../../Context/Tab";
import Cars from "../../../../../entities/cars";
import Loader from "../../../../../shared/components/Loader";
import { SCREENS, TABS_ACTIONS } from "../../../../../utils/constants";

const Motor = (props) => {
  const { dispatch } = useContext(TabContext);

  const { motor } = props.search;
  const name = get(motor, "name");
  if (!name) return null;
  return (
    <Cars
      NoData={() => null}
      filter={{ where: { motor: { name } } }}
      Loading={() => <Spin />}
      View={Loader(({ data }) => {
        return (
          <div className="home_section_bg">
            <div className="vin_tag">
              {data.map((car) => (
                <Tag
                  className="vin_tag_item"
                  key={car.id}
                  onClick={() => {
                    return dispatch({
                      type: TABS_ACTIONS.HISTORY_PUSH,
                      payload: {
                        screen: SCREENS.VEHICLES,
                        props: {
                          search: {
                            car: get(car, "id", ""),
                          },
                        },
                      },
                    });
                  }}
                >
                  {car.id}) {car.name}
                </Tag>
              ))}
            </div>
          </div>
        );
      })}
    ></Cars>
  );
};
export default Motor;
