import gql from "graphql-tag";

export const CREATE_SUPPORT = gql`
  mutation CreateSupport($data: SupportWhereInput) {
    createSupport(data: $data) {
      id
      context
      type
    }
  }
`;

export default {
  CREATE_SUPPORT
};
