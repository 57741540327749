import { Select as AntSelect } from "antd";
import cuid from "cuid";
import React from "react";

const { Option } = AntSelect;

const Select = ({
  options,
  icons,
  placeholder,
  mode,
  onChange,
  defaultValue,
  ...rest
}) => {
  return (
    <AntSelect
      defaultValue={defaultValue}
      mode={mode}
      placeholder={placeholder}
      onChange={onChange}
      {...rest}
    >
      {options.map((option) => (
        <Option key={cuid()} value={option.value}>
          <div>
            {icons[option.value]} {option.title}
          </div>
        </Option>
      ))}
    </AntSelect>
  );
};

Select.defaultProps = {
  icons: {},
};

export default Select;
