import React from "react";
import { Input as AntInput, InputNumber as AntInputNumber } from "antd";

const InputBuilder = ({ type, ...rest }) => {
  switch (type) {
    case "password":
      return <AntInput.Password {...rest} />;
    case "number":
      return <AntInputNumber {...rest} />;
    case "textArea":
      return <AntInput.TextArea {...rest} />;
    default:
      return <AntInput {...rest} />;
  }
};

export default ({ size, class_style, type, ...rest }) => (
  <InputBuilder type={type} size={size} {...rest} />
);
