import Loadable from "react-loadable";
import Loading from "../../../shared/components/Loading";
import { PATHS } from "../../../utils/constants";
const View = Loadable({
  loader: () => import("./List"),
  loading: Loading,
});

const routes = [
  {
    path: PATHS.ENTRIES,
    exact: true,
    component: View,
  },
];
export default routes;
