import get from "lodash/get";
import React, { useContext } from "react";
import { TabContext } from "../../Context/Tab";
import Manufacturer from "../../entities/manufacturer";
import Model from "../../entities/model";
import Vehicles from "../../entities/vehicles";
import Loader from "../../shared/components/Loader";
import { SCREENS, TABS_ACTIONS } from "../../utils/constants";
import Path from "./Path";

const ViewVehicule = ({ data, car }) => {
  const { dispatch } = useContext(TabContext);
  //  

  
  

  const path = [
    {
      onPress: () =>
        dispatch({
          type: TABS_ACTIONS.HISTORY_PUSH,
          payload: {
            screen: SCREENS.MODELS,
            props: {
              search: {
                manufacturer: get(data[0], "manufacturer", {}).id,
                manufacturerBean: get(data[0], "manufacturer", {}),
              },
            },
          },
        }),
      text: get(data[0], "manufacturer", {}).name,
    },
    {
      onPress: () =>
        dispatch({
          type: TABS_ACTIONS.HISTORY_PUSH,
          payload: {
            screen: SCREENS.VEHICLES,
            props: {
              search: {
                car: car,
              },
            },
          },
        }),
      text: get(data[0], "model", {}).name,
    },
  ];
  return <Path path={path}></Path>;
};

const ViewManufacturer = ({ data, manufacturer }) => {
  
  

  const { dispatch } = useContext(TabContext);

  const path = [
    {
      onPress: () =>
        dispatch({
          type: TABS_ACTIONS.HISTORY_PUSH,
          payload: {
            screen: SCREENS.MODELS,
            props: {
              search: {
                manufacturer,
                manufacturerBean: data,
              },
            },
          },
        }),
      text: data.name,
    },
  ];
  return <Path path={path}></Path>;
};

const ViewModel = ({ data, model, manufacturer,manufacturerBean }) => {
  const { dispatch } = useContext(TabContext);
  

  
  
  

  

  const path = [
    {
      onPress: () =>
        dispatch({
          type: TABS_ACTIONS.HISTORY_PUSH,
          payload: {
            screen: SCREENS.VEHICLES,
            props: {
              search: {
                manufacturer,
                model: model,
                modelBean: data,
                manufacturerBean,

              },
            },
          },
        }),
      text: data.name,
    },
  ];
  return <Path path={path}></Path>;
};

export default ({
  manufacturer,
  model,
  car,
  manufacturerBean,
  modelBean,
  motorizationBean,
}) => {
  const variablesModel = {
    where: { id: model, manufacturer: { id: manufacturer } },
  };

  
  
  

  const variablesManufacturer = { where: { id: manufacturer } };

  const variablesCar = { where: { cars: [{ id: car }] } };
  const pathGenrator = {
    car: () => (
      <Vehicles
        filter={variablesCar}
        View={Loader(ViewVehicule)}
        car={car}
      ></Vehicles>
    ),
    manufacturer: () => (
      <>
        <Manufacturer
          manufacturer={manufacturer}
          filter={variablesManufacturer}
          View={Loader(ViewManufacturer)}
        ></Manufacturer>
        <Model
          filter={variablesModel}
          View={Loader(ViewModel)}
          manufacturer={manufacturer}
          manufacturerBean={manufacturerBean}
          modelBean={modelBean}

          model={model}



        ></Model>
      </>
    ),
    default: () => null,
  };
  const key = car ? "car" : manufacturer && model ? "manufacturer" : "default";
  const PathElement = pathGenrator[key];
  return <PathElement></PathElement>;
};
