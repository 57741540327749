import React, { useState } from "react";
import { Button, Row, Col } from "antd";
import { useMutation } from "@apollo/react-hooks";
import Input from "../../../shared/form/Field/Input";
import { AuthMutations } from "../../../shared/graphql/mutations";

const { GENERATE_PIN_CODE } = AuthMutations;

const Challenge = props => {
  const { history } = props;
  const [value, setValue] = useState();
  const [error, setError] = useState(false);

  const [generateNewCode] = useMutation(GENERATE_PIN_CODE, {
    onCompleted: () => {
      history.push("/reset-password/verify");
    },
    onError: error => setError(error)
  });
  return (
    <Row>
      <Col span={12}>
        <Input
          placeholder="username"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </Col>
      <Col>
        {error && <span style={{ color: "red" }}>invalid username !</span>}
      </Col>
      <Button
        onClick={() =>
          generateNewCode({ variables: { data: { username: value } } })
        }
      >
        submit
      </Button>
    </Row>
  );
};

export default Challenge;
