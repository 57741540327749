import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Button, Col, Input, Row } from "antd";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as generateUuid } from "uuid";
import { AuthMutations } from "../../../shared/graphql/mutations";
import { AuthQueries } from "../../../shared/graphql/queries";
import { Store } from "../../../shared/store";
import { PATHS } from "../../../utils/constants";

const { CREATE_DEVICE } = AuthMutations;
const { ACCESS } = AuthQueries;

const Access = ({
  store: {
    grantPrivateAccess,
    user: { id: userId },
    setUsersUid,
  },
}) => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [addDevice, { loading: loadingAddDevice }] = useMutation(
    CREATE_DEVICE,
    {
      onCompleted: () => {
        grantPrivateAccess();
        return history.push(PATHS.HOME);
      },
      onError: () => setError(true),
    }
  );
  const [
    getAccess,
    { loading: loadingAccess, error: errorQuery },
  ] = useLazyQuery(ACCESS, {
    onCompleted: (result) => {
      const { access } = result;
      if (isNil(access)) return setError(true);
      const { device } = access;
      const uuid = generateUuid();
      setUsersUid(userId, uuid);
      if (isNil(device))
        return addDevice({
          variables: {
            data: {
              guid: uuid,
              access: { id: access.id },
            },
          },
        });
      const { guid: accessGuid } = device;
      if (accessGuid !== uuid) return setError(true);
      grantPrivateAccess();
      return history.push(PATHS.HOME);
    },
  });

  if (errorQuery) return `Error! ${errorQuery.message}`;
  if (loadingAddDevice || loadingAccess) return <div>Vérification...</div>;
  return (
    <Row>
      <Col>
        <Input onChange={(_) => setAccessCode(_.target.value)} />
      </Col>
      {error && (
        <Col>
          <span>Erreur du jeton d'accès</span>
        </Col>
      )}
      <Col>
        <Button
          type="primary"
          onClick={() => {
            const variables = {
              where: { code: accessCode, user: { id: userId } },
            };
            getAccess({ variables });
          }}
        >
          Vérifier
        </Button>
      </Col>
    </Row>
  );
};

export default Store(Access);
