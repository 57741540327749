import { Modal } from "antd";
import React from "react";
import { Carousel } from "react-bootstrap";

import SideBySideMagnifier from "../../../../../../shared/components/Zoom/SideBySideMagnifier";
import Heading from "../../../../../../shared/components/stories/Heading";

export default ({
  visible,
  setVisible,
  thumbnails,
  photo,
  photoVersion,
  photo2,
  photoVersion2,
  itemId,
  oem,
  checkIfMdds,
}) => {
  const closeModal = () => {
    setVisible(!visible);
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      centered
      title={<Heading title={"Aperçu détaillé de l'image"} />}
      className="form-modal full-screen-modal center-body"
      onCancel={closeModal}
    >
      <Carousel indicators={true} controls={true} className="blacked">
        {checkIfMdds()
          ? photo2
            ? [
                {
                  url: photo
                    ? `http://81.192.102.38:31849/sap-tecdoc-synchronisation/inc/dynamique/article/${
                        itemId + photo + "?v=" + photoVersion
                      }`
                    : `https://upload.wikimedia.org/wikipedia/commons/a/af/Image_non_disponible.png`,
                },
                {
                  url: `http://81.192.102.38:31849/sap-tecdoc-synchronisation/inc/dynamique/article2/${
                    itemId + photo2 + "?v=" + photoVersion2
                  }`,
                },
              ]
            : [
                {
                  url: photo
                    ? `http://81.192.102.38:31849/sap-tecdoc-synchronisation/inc/dynamique/article/${
                        itemId + photo + "?v=" + photoVersion
                      }`
                    : `https://upload.wikimedia.org/wikipedia/commons/a/af/Image_non_disponible.png`,
                },
              ].map(({ dossier, url }, index) => (
                <Carousel.Item key={index}>
                  <SideBySideMagnifier imageUrl={url} magnification={3} />
                </Carousel.Item>
              ))
          : thumbnails.map(({ id, url, name }) => (
              <Carousel.Item key={id}>
                <SideBySideMagnifier
                  imageUrl={`https://digital-assets.tecalliance.services/images/3200/${url}`}
                  magnification={3}
                />
              </Carousel.Item>
            ))}
      </Carousel>
    </Modal>
  );
};
