import React from "react";

export default () => {
  return (
    <iframe
      title="sales"
      width="100%"
      height={900}
      src="https://web2.carparts-cat.com/default.aspx?10=BF5C1A03B0D448CBBADD0A8F1D535C3B426006&14=6&12=1003"
    />
  );
};
