import { Card, Col, Collapse, Row } from "antd";
import { isEmpty } from "lodash";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import React, { useContext } from "react";
import { TabContext } from "../../../../../../../Context/Tab";
import { SCREENS, TABS_ACTIONS } from "../../../../../../../utils/constants";

const { Panel } = Collapse;

export default React.memo(({ article }) => {
  const { code } = article;
  const oens = groupBy(get(article, "oens", []), "brandName");
  const { dispatch } = useContext(TabContext);

  return (
    <div className="descriptions-div">
      <Collapse
        bordered={false}
        expandIconPosition={"right"}
        defaultActiveKey={["1"]}
      >
        <Panel header={"Numéros OE"} key="1">
          {isEmpty(Object.entries(oens)) ? (
            <h4 className="not-found-message">Aucun numéro OE trouvé</h4>
          ) : (
            <Row gutter={16}>
              {Object.entries(oens).map(([key, values]) => {
                return (
                  <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{ marginBottom: 10 }}
                  >
                    <Card title={key} className="card-oe">
                      {values.map((value) => (
                        <p key={`${value.oenNumber}`} className="card-oe_item">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              dispatch({
                                type: TABS_ACTIONS.HISTORY_PUSH,
                                payload: {
                                  screen: SCREENS.ARTICLES,
                                  props: {
                                    search: {
                                      reference: value.oenNumber,
                                      type: "direct",
                                    },
                                  },
                                },
                              });
                            }}
                          >
                            {value.oenNumber}
                          </span>
                        </p>
                      ))}
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
        </Panel>
      </Collapse>
    </div>
  );
});
