import { CarOutlined, CopyOutlined, HeartOutlined } from "@ant-design/icons";
import { Button, Icon, Input, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import WishlistIcon from "../../../../../../../../../shared/assets/images/wishlist.svg";
import Loader from "../../../../../../../../../shared/components/Loader";
import { StoreContext } from "../../../../../../../../../shared/store";
import { numberFormatter } from "../../../../../../../../../utils/formatter-functions";
import { priceAndDiscount } from "../../../../../../../../../utils/helpers";
import Equivalence from "../../Equivalence";
import WAREHOUSES from "../../WareHouses";

export const Card = ({
  referenceArticle,
  code,
  name,
  brandName,
  assemblyGroup,
  itemId,
  itemCode,
  data,
  oens,
  account,
  detailLink,
  quantity,
  setQuantity,
  handleCartPress,
  addToWishList,
  cartItemLoading,
  itemLoading,
  setShowList,
  showList,
  checkIfMdds,
  dateArrivage,
  application1,
  application2,
  application3,
  nouveau,
  topVente,
  article,
  articleAvecPrix,
  setArticleAvecPrix,
}) => {
  const { user = {} } = useContext(StoreContext);
  const [displayedNet, setDisplayedNet] = useState(false);

  const toggleDisplayedNet = () => {
    setDisplayedNet(!displayedNet);
  };

  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500); // Cacher le tooltip après 1.5 secondes
  };


  const {
    substitutes,
    attributes,
    prices = [],
    discount,
    itemWareHouses = [],
  } = data || {};

  // // console.log("data: ", data);

  useEffect(() => {
    if (data)
      setArticleAvecPrix({
        ...article,
        quantity: isNaN(quantity) ? 1 : parseInt(quantity),
        item: {
          ...article.item,
          prices,
          discount,
          itemWareHouses,
        },
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { price, original, formula } = priceAndDiscount({
    quantity: isNaN(quantity) ? 1 : parseInt(quantity),
    prices,
    discount,
    account: user.account,
  });

  return (
    <div className="div-horizontal growed">
      <div
        className={
          "card_article_description compact " +
          (topVente === "Y" || nouveau === "Y" ? " new" : "")
        }
      >
        <div
          className="div-horizontal center"
          style={{
            margin: "0px 15px 0px 0px",

            height: "30px",
            position: "relative",
          }}
        >
          <div
            className="div-horizontal center growed"
            style={{ marginRight: 15 }}
          >
            <Equivalence
              oens={oens}
              referenceArticle={referenceArticle}
              code={code}
              itemId={itemId}
            ></Equivalence>
            <Tooltip title={assemblyGroup}>
              <h4
                style={{ cursor: "pointer", margin: "0" }}
                onClick={() => detailLink(articleAvecPrix)}
              >
                {assemblyGroup}
              </h4>
            </Tooltip>
          </div>
          <div className="div-horizontal center">
            <div
              className="card_article_price"
              style={{ flexGrow: 0, marginRight: 10 }}
            >
              <div className="card_article_price_input">
                {itemId !== -1 && !isNaN(original) && (
                  <>
                    <div
                      className="div-vertical"
                      onClick={() => toggleDisplayedNet()}
                    >
                      {
                        <Tooltip
                          title={
                            numberFormatter(price, 2) + " Dh (" + formula + ")"
                          }
                        >
                          <h4 className="original_price">
                            {numberFormatter(original, 2) + " Dh"}
                          </h4>
                        </Tooltip>
                      }
                      {displayedNet && (
                        <label
                          style={{
                            color: "green",
                            fontWeight: "600",
                            margin: "0",
                          }}
                        >
                          {numberFormatter(price, 2) + " Dh"}
                        </label>
                      )}
                    </div>
                    <label className="operation_math">X</label>
                  </>
                )}
                <Input
                  style={{
                    width: 70,
                    height: 26,

                    textAlign: "center",
                  }}
                  value={quantity}
                  type="number"
                  min="1"
                  onChange={(e) => setQuantity(parseInt(e.target.value))}
                />

                <Button
                  type="primary"
                  className="ajouter-au-panier-button"
                  disabled={cartItemLoading || itemLoading ? true : false}
                  onClick={handleCartPress}
                >
                  <Icon style={{ fontSize: "22px" }} type="shopping-cart" />
                </Button>
              </div>
            </div>
            {WAREHOUSES({ itemWareHouses, account, prices, dateArrivage })}
          </div>
        </div>

        <div
          className="div-horizontal center"
          style={{ marginRight: "15px", position: "relative" }}
        >
          <div
            className="div-vertical growed"
            style={{ alignItems: "flex-start" }}
          >
            <div className="div-horizontal center growed">
              <h5 className="card_article_description_code_tecdoc">
                <div
                  style={{ cursor: "pointer" }}
                  className="ref"
                  onClick={() => detailLink(articleAvecPrix)}
                >
                  {!checkIfMdds() && (
                    <Tooltip title="N° article ">
                      <b>{itemId === -1 ? code : itemCode}</b>
                    </Tooltip>
                  )}
                </div>
              </h5>
              <div className="div-horizontal center">

                <h5 className="card_article_description_code">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => detailLink(articleAvecPrix)}
                  >
                    <Tooltip title="N° commercial d'article ">
                      <b>{itemId === -1 ? "(Non géré)" : "(" + itemId + ")"} </b>
                    </Tooltip>
                  </div>
                </h5>
                <Tooltip
                  title={
                    copied
                      ? (itemId === -1 ? code : itemId) + " copié !"
                      : "Copier au presse-papiers"
                  }
                  placement="top"
                >
                  <CopyOutlined
                    style={{ marginLeft: 5, fontSize: 16 }}
                    onClick={() =>
                      copyToClipboard(itemId === -1 ? code : itemId)
                    }
                  />
                </Tooltip>

              </div>

              <HeartOutlined
                style={{
                  marginLeft: 15,

                  fontSize: "25px",
                }}
                onClick={() => {
                  addToWishList(itemWareHouses, prices);
                }}
                src={WishlistIcon}
              />
            </div>
          </div>
        </div>
        {(application1 || application2 || application3) && <Tooltip title={<div className="div-vertical">
          <label className="application-label">{application1}</label>
          {application2 && <>
            <label className="application-label">{application2}</label></>}
          {application3 && <>
            <label className="application-label">{application3}</label></>}</div>}> <div className="application-div">
            <CarOutlined style={{ margin: 0, marginRight: 8, fontSize: 22 }} />
            <label className="application-label">{application1}</label>
            {application2 && <>  <label className="application-separator">/</label>
              <label className="application-label">{application2}</label></>}
            {application3 && <>  <label className="application-separator">/</label>
              <label className="application-label">{application3}</label></>}
          </div></Tooltip>}
      </div>
    </div>
  );
};

export const CardLoader = Loader(Card);
