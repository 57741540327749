import gql from "graphql-tag";
export const CREATE_SUPPLIER = gql`
  mutation CreateSupplier($data: SupplierWhereInput) {
    createSupplier(data: $data) {
      id
    }
  }
`;
export const EXPORT_SUPPLIER = gql`
  mutation exportSupplier($data: SupplierWhereInput, $gt:SupplierWhereInput, $lt:SupplierWhereInput) {
    exportSupplier(data: $data, gt:$gt, lt:$lt)
  }
`;

export const DELETE_SUPPLIER = gql`
  mutation DeleteSupplier($data: SupplierWhereInput) {
    deleteSupplier(data: $data) {
      id
    }
  }
`;

export const UPDATE_SUPPLIER = gql`
  mutation UpdateSupplier($data: SupplierWhereInput) {
    updateSupplier(data: $data) {
      id
    }
  }
`;
export default {
  CREATE_SUPPLIER,
  EXPORT_SUPPLIER,
  DELETE_SUPPLIER,
  UPDATE_SUPPLIER
};

