import React, { Component } from "react";

export const SideBarContext = React.createContext("SideBar");

export const SideBarConsumer = SideBarContext.Consumer;

const INIT_STATE = {
  collapsed: false,
  selectedTabNavigation: "1",
  selectedTabRechercheHistorique: "1",
  selectedTabRecherche: "1",
  selectedTabHistorique: "1",
};

export class SideBarProvider extends Component {
  constructor() {
    super();

    this.state = INIT_STATE;
  }
  setSideBarStatut = (key, value) => {
    // console.log(key, value);
    this.setState({ ...this.state, [key]: value });

    // console.log(this.state);
  };

  render() {
    const { children } = this.props;
    const sideBarStatut = this.state;
    return (
      <SideBarContext.Provider
        value={{
          sideBarStatut,
          setSideBarStatut: this.setSideBarStatut,
        }}
      >
        {children}
      </SideBarContext.Provider>
    );
  }
}
