import gql from "graphql-tag";
export const CREATE_QUOTE = gql`
  mutation CreateQuote($data: QuoteWhereInput) {
    createQuote(data: $data){
      id
    }
  }
`;
export default {
  CREATE_QUOTE
};
