import cuid from "cuid";
import React from "react";
import { SCREENS } from "../../utils/constants";
import { useTab } from "./useTab";
export const TabContext = React.createContext("tab");
export const TabConsumer = TabContext.Consumer;

const INIT_STATE = {
  activeTab: 0,
  items: [
    {
      history: [{ screen: SCREENS.CATALOG, props: { tab: "technicalData" } }],
      activeHistory: 0,
      tab: SCREENS.CATALOG,
      id: cuid(),
      closeable: true,
    },
  ],
};
export const TabProvider = (props) => {
  const { tabs, dispatch } = useTab(
    localStorage.getItem("tabs")
      ? JSON.parse(localStorage.getItem("tabs"))
      : INIT_STATE
  );

  return (
    <TabContext.Provider
      value={{
        tabs,
        dispatch,
      }}
    >
      {props.children}
    </TabContext.Provider>
  );
};
