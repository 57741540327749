import gql from "graphql-tag";

export const CRON_JOB = gql`
  query cronJob($where: CronJobWhereInput) {
    cronJob(where: $where) {
      id
      type
      deleted
      createdDate
      updatedDate
      once
      interval
      status
      payload
    }
  }
`;

export const CRON_JOBS = gql`
  query cronJobs($where: CronJobWhereInput, $skip: Int, $take: Int) {
    cronJobs(where: $where, skip: $skip, take: $take) {
      data {
        id
        type
        deleted
        createdDate
        updatedDate
        once
        interval
        status
        payload
      }
      count
    }
  }
`;

export const CRON_JOB_LOGS = gql`
  query cronJobLogs($where: CronJobLogWhereInput, $skip: Int, $take: Int) {
    cronJobLogs(where: $where, skip: $skip, take: $take) {
      data {
        id
        message
        status
        createdDate
      }
      count
    }
  }
`;

export default {
  CRON_JOB,
  CRON_JOBS,
  CRON_JOB_LOGS,
};
