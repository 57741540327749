import React, { useState } from "react";
import { Button, Row, Col } from "antd";
import { useMutation } from "@apollo/react-hooks";
import Joi from "@hapi/joi";
import Input from "../../../shared/form/Field/Input";

import { AuthMutations } from "../../../shared/graphql/mutations";

const { CHANGE_PASSWORD_AFTER_VALIDATION } = AuthMutations;

const PinCode = props => {
  const { history } = props;
  const [values, setValue] = useState({ code: 0, password: "" });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const [validate, setValidate] = useState(false);

  const result = Joi.validate(values.password, confirmPassword);

  const [changePassword] = useMutation(CHANGE_PASSWORD_AFTER_VALIDATION, {
    onCompleted: () => {
      history.push("/");
    },
    onError: error => setError(error)
  });

  return (
    <Row>
      <Col>
        <Input
          placeholder="entrer votre pin"
          value={values.code}
          type="number"
          onChange={e => {
            const code = e;
            setValue({ ...values, code });
          }}
        />
      </Col>
      <Col>
        {error && (
          <span style={{ color: "red" }}>sorry are you sure it's you !?</span>
        )}
      </Col>
      <Col>
        <Input
          placeholder="entrer le nouveau mot de passe"
          type="password"
          value={values.password}
          onChange={e => {
            const password = e.target.value;
            setValue({ ...values, password });
          }}
        />
      </Col>
      <Col>
        <Input
          placeholder="confirmer le nouveau mot de passe"
          type="password"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
        />
      </Col>
      <Col>
        {validate && (
          <span style={{ color: "red" }}>Passwords are not identical !</span>
        )}
      </Col>
      <Button
        disabled={error}
        onClick={() => {
          result.error
            ? setValidate(true)
            : changePassword({
                variables: {
                  data: { code: values.code, password: values.password }
                }
              });
        }}
      >
        submit
      </Button>
    </Row>
  );
};

export default PinCode;
