import gql from "graphql-tag";

export const PAYMENT_ACCOUNTS = gql`
  query paymentAccounts($where: PaymentAccountWhereInput) {
    paymentAccounts(where: $where) {
      data {
        id
        balance
        activity
        condition
        account {
          id
          title
          description
          city
          address
          firstName
          lastName
          birthday
          company
          idFiscal
          patent
          rc
          ice
          cnss
          agency
          referent
        }
      }
      count
    }
  }
`;

export default {
  PAYMENT_ACCOUNTS,
};
