import gql from "graphql-tag";

export const UPDATE_WISH_LIST = gql`
  mutation UpdateWishList($data: WishListWhereInput) {
    updateWishList(data: $data) {
      id
      article {
        id
        name
      }
    }
  }
`;

export default {
  UPDATE_WISH_LIST
};
