import React from "react";
import Car from "./Car";
import Manufacturer from "./Manufacturer";
import Motor from "./Motor";
import Vin from "./Vin";

export default (props) => {
  return (
    <>
      <Vin {...props} />
      <Motor {...props} />
      <Car {...props} />
      <Manufacturer {...props} />
    </>
  );
};
