import b01 from "./../../shared/assets/images/familles/001.jpg";
import b02 from "./../../shared/assets/images/familles/002.jpg";
import b03 from "./../../shared/assets/images/familles/003.jpg";
import b04 from "./../../shared/assets/images/familles/005.jpg";
import b05 from "./../../shared/assets/images/familles/006.jpg";
import b06 from "./../../shared/assets/images/familles/007.jpg";
import b1 from "./../../shared/assets/images/familles/accessoires.png";
import b2 from "./../../shared/assets/images/familles/alimentationcarburant.png";
import b3 from "./../../shared/assets/images/familles/allumage-pre.png";
import b4 from "./../../shared/assets/images/familles/Antigel.png";
import b5 from "./../../shared/assets/images/familles/Batterie.png";
import b6 from "./../../shared/assets/images/familles/boite-de-vitesse.png";
import b7 from "./../../shared/assets/images/familles/Carburation.png";
import b8 from "./../../shared/assets/images/familles/carrosserie.png";
import b9 from "./../../shared/assets/images/familles/chauffage-ventilation.png";
import b10 from "./../../shared/assets/images/familles/climatisation.png";
import b11 from "./../../shared/assets/images/familles/commandeacouroie.png";
import b12 from "./../../shared/assets/images/familles/direction.png";
import b13 from "./../../shared/assets/images/familles/echappement.png";
import b14 from "./../../shared/assets/images/familles/Embrayage.png";
import b15 from "./../../shared/assets/images/familles/entrainement-d'essieu.png";
import b16 from "./../../shared/assets/images/familles/Entrainementdesroues.png";
import b17 from "./../../shared/assets/images/familles/equipement-interieur.png";
import b18 from "./../../shared/assets/images/familles/Feux.png";
import b19 from "./../../shared/assets/images/familles/Filtre.png";
import b20 from "./../../shared/assets/images/familles/Freinage.png";
import b21 from "./../../shared/assets/images/familles/Huiles.png";
import b22 from "./../../shared/assets/images/familles/moteur.png";
import b23 from "./../../shared/assets/images/familles/nettoyage-des-phares.png";
import b24 from "./../../shared/assets/images/familles/Nettoyagedesvitres.png";
import b25 from "./../../shared/assets/images/familles/piecesdemaintenance.png";
import b26 from "./../../shared/assets/images/familles/Refroidissement.png";
import b27 from "./../../shared/assets/images/familles/Suspension-dessieu-Guidage-des-roues-Roues.png";
import b28 from "./../../shared/assets/images/familles/SuspensionAmortissement.png";
import b29 from "./../../shared/assets/images/familles/Systeme-de-securite.png";
import b30 from "./../../shared/assets/images/familles/Systeme-electrique.png";
import b31 from "./../../shared/assets/images/familles/systemedinformationetdecommunication.png";
import b32 from "./../../shared/assets/images/familles/systemepneumatique.png";
import b33 from "./../../shared/assets/images/familles/Systemes-de-confort.png";
import b34 from "./../../shared/assets/images/familles/Verrouillage.png";

export const Logos = {
  100001: b8,
  100002: b22,
  100004: b13,
  100005: b19,
  100006: b20,
  100007: b26,
  100008: b3,
  100010: b30,
  100011: b28,
  100012: b12,
  100013: b27,
  100014: b25,
  100016: b11,
  100018: b24,
  100019: b25,
  100042: b5,
  100043: b18,
  100050: b14,
  100214: b2,
  100238: b6,
  100241: b9,
  100243: b10,
  100254: b7,
  100335: b33,
  100339: b31,
  100341: b17,
  100342: b23,
  100400: b15,
  100417: b29,
  100685: b34,
  100733: b1,
  101994: b21,
  102205: b4,
  103099: b16,
  103168: b32,
};
export const TitleLogos = {
  Moteurs: b01,
  "Châssis & Entraînements": b02,
  "Accessoires & Diverses": b03,
  Carrosseries: b04,
  "Chauffages et refroidissements": b05,
  "Systèmes électriques": b06,
};
