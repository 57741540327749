import { useLazyQuery } from "@apollo/react-hooks";
import { Button, Select, Spin } from "antd";
import { get } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { TabContext } from "../../../../../Context/Tab";
import { CRITERIAS } from "../../../../../shared/graphql/queries/manufacturers";
import { SCREENS, TABS_ACTIONS } from "../../../../../utils/constants";
import { format } from "../../../../../utils/helpers";
const { Option } = Select;

export default (props) => {
  const { assemblyGroupName, assemblyGroupId } = props;
  const { tabs, dispatch } = useContext(TabContext);

  const [executeLazyQuery, { data, loading: loadingQuery }] =
    useLazyQuery(CRITERIAS);
  const [filtredData, setFiltredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueSuggestion, setSearchValueSuggestion] = useState("");

  const [loading, setLoading] = useState(false);
  const [selectedCritere, setSelectedCritere] = useState(null);

  const [critereValue, setCritereValue] = useState({
    rawValue: "",
    formattedValue: " ",
  });

  useEffect(() => {
    if (assemblyGroupId && assemblyGroupId !== "") {
      setSelectedCritere(null);
      setCritereValue({
        rawValue: "",
        formattedValue: " ",
      });

      executeLazyQuery({
        variables: {
          where: {
            assemblyGroupId: assemblyGroupId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assemblyGroupId]);

  useEffect(() => {
    setLoading(true);

    const filtred = get(data, "criterias.data", [])
      .filter((critere) => format(critere.name).includes(format(searchValue)))
      .slice(0, 100);

    setFiltredData(filtred);
    setLoading(false);
  }, [searchValue, data]);

  const handleChange = (valueParam) => {
    //setValue(valueParam);

    setSelectedCritere(
      get(data, "criterias.data", []).filter(
        (critere) => critere.id === valueParam.key
      )[0]
    );
    setSearchValue("");
    setCritereValue({ rawValue: "", formattedValue: " " });

    // setLoading(false);
  };

  const searchChange = (valueParam) => {
    setSearchValue(valueParam);
  };

  const searchChangeSuggestion = (valueParam) => {
    setSearchValueSuggestion(valueParam);
  };
  const handleChangeSuggestion = (valueParam) => {
    setCritereValue({
      rawValue: valueParam.key,
      formattedValue: valueParam.label,
    });
    setSearchValueSuggestion("");
  };

  const submitForm = () => {
    console.log("critereValue", critereValue);
    console.log("selectedCritere", selectedCritere);

    console.log("click 100 supplierAssembly");

    dispatch({
      type: TABS_ACTIONS.HISTORY_PUSH,
      payload: {
        screen: SCREENS.ARTICLES,
        props: {
          search: {
            type: "articlesAssembly",
            critereBean: {
              id: selectedCritere.id,
              name: selectedCritere.name,
              rawValue: critereValue.rawValue,
              formattedValue: critereValue.formattedValue,
            },
            critere: {
              id: selectedCritere.id,
              rawValue: critereValue.rawValue,
            },
            assemblyGroupBean: {
              id: assemblyGroupId,
              name: assemblyGroupName,
            },
            assemblyGroup: { id: assemblyGroupId },
          },
        },
      },
    });
  };

  return (
    <Spin spinning={loadingQuery}>
      <div className="div-vertical" style={{ height: "100%" }}>
        <h3 style={{ textAlign: "left", marginBottom: 30 }}>
          {assemblyGroupName}
        </h3>

        <Select
          showSearch
          labelInValue
          size={"large"}
          // value={value}
          placeholder="Selectionner un critère"
          notFoundContent={loading ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={searchChange}
          onChange={handleChange}
          style={{ width: "100%" }}
        >
          {filtredData.map((critere) => (
            <Option key={critere.id}>{critere.name}</Option>
          ))}
        </Select>

        {selectedCritere ? (
          <div className="div-vertical">
            <div
              className="div-horizontal center"
              style={{ marginTop: 20, flexGrow: 1 }}
            >
              <Select
                showSearch
                size={"large"}
                placeholder="Suggestion"
                labelInValue
                notFoundContent={null}
                filterOption={false}
                onSearch={searchChangeSuggestion}
                onChange={handleChangeSuggestion}
                style={{ width: "100%" }}
              >
                {get(selectedCritere, "values", [])
                  .filter((valueItem) =>
                    format(valueItem.formattedValue).includes(
                      format(searchValueSuggestion)
                    )
                  )
                  .map(({ formattedValue, rawValue }) => (
                    <Option key={rawValue}>
                      {formattedValue + " " + get(selectedCritere, "unity", "")}
                    </Option>
                  ))}
              </Select>
            </div>
            <Button
              onClick={submitForm}
              style={{ marginTop: 20 }}
              size={"large"}
              type="primary"
              disabled={critereValue.rawValue === ""}
            >
              Valider
            </Button>
          </div>
        ) : (
          <div
            className="div-vertical center center-justified"
            style={{ padding: "40px 20px", width: "100%", height: "100%" }}
          >
            <h3>Sélectionnez un critère</h3>
          </div>
        )}
      </div>
    </Spin>
  );
};
