import gql from "graphql-tag";

export const UPDATE_USER = gql`
  mutation UpdateUser($data: UserWhereInput) {
    updateUser(data: $data) {
      id
      firstName
      lastName
      email
      phoneNumber
      firstName
      lastName
    }
  }
`;
export const EXPORT_USER = gql`
  mutation ExportUser(
    $data: UserWhereInput
    $gt: UserWhereInput
    $lt: UserWhereInput
  ) {
    exportUser(data: $data, gt: $gt, lt: $lt)
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($data: UserWhereInput) {
    createUser(data: $data) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($data: UserWhereInput) {
    deleteUser(data: $data) {
      id
    }
  }
`;

export default {
  UPDATE_USER,
  EXPORT_USER,
  CREATE_USER,
  DELETE_USER,
};
