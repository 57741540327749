import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const getResponsive = ({
  NumberOfMobile,
  NumberOfTablet,
  NumberOfDesktop,
}) => ({
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: NumberOfDesktop,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: NumberOfTablet,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: NumberOfMobile,
  },
});

const CustomCarousel = ({
  items = [],
  NumberOfMobile = 1,
  NumberOfTablet = 1,
  NumberOfDesktop = 3,
  showDots = false,
}) => {
  const config = getResponsive({
    NumberOfMobile,
    NumberOfTablet,
    NumberOfDesktop,
    showDots,
  });

  const deviceType = isMobile ? "mobile" : isTablet ? "tablet" : "desktop";
  return (
    <Carousel
      NumberOfMobile={NumberOfMobile}
      NumberOfTablet={NumberOfTablet}
      NumberOfDesktop={NumberOfDesktop}
      infinite={true}
      responsive={config}
      swipeable={true}
      draggable={true}
      showDots={showDots}
      autoPlaySpeed={3500}
      keyBoardControl={true}
      renderButtonGroupOutside={true}
      renderDotsOutside={true}
      // removeArrowOnDeviceType={["tablet", "mobile"]}
      slidesToSlide={config[deviceType].items}
    >
      {items.map((item, index) => (
        <div key={index} style={{ padding: "10px 0px" }}>
          {item}
        </div>
      ))}
    </Carousel>
  );
};
export default CustomCarousel;
