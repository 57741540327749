import { ShoppingCartOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TabContext } from "../../../../Context/Tab";
import { StoreContext } from "../../../../shared/store";
import "./index.css";

export default React.memo(({ data, count, setSkip, skip }) => {
  const quantities = data
    .map(({ item, quantity }) => ({
      quantity,
      discountId: item.id,
    }))
    .reduce((prev, cur) => {
      prev[[cur.discountId]] =
        (parseInt(prev[[cur.discountId]]) || 0) + parseInt(cur.quantity);
      return prev;
    }, {});

  const { dispatch } = useContext(TabContext);
  const { user = {} } = useContext(StoreContext);

  return (
    <Link to="/cart-items">
      <ShoppingCartOutlined />
      <span className="wishlist_count">{count > 99 ? "+99" : count}</span>
    </Link>
  );
});
