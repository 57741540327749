import gql from "graphql-tag";

export const PROMOTIONS = gql`
  query Promotions($where: PromotionWhereInput) {
    promotions(where: $where) {
      data {
        id
        name
        url
        image
        file
        video
        deleted
        promotionDiscounts {
          id
          discount {
            id
            name
            items {
              id
              catalogCode
              name
            }
          }
          deleted
        }
      }
      count
    }
  }
`;

export const PROMOTION = gql`
  query Promotion($where: PromotionWhereInput) {
    promotion(where: $where) {
      id
      name
      url
      image
      file
      video
      deleted
      promotionDiscounts {
        id
        discount {
          id
          name
          items {
            id
            catalogCode
            name
          }
        }
        deleted
      }
    }
  }
`;

export default {
  PROMOTION,
  PROMOTIONS,
};
