import gql from "graphql-tag";

export const PARTS = gql`
  query Parts($where: UserWhereInput) {
    parts(where: $where) {
      data {
        id
      }
      count
    }
  }
`;

export const PART = gql`
  query Part($where: UserWhereUniqueInput!) {
    part(where: $where) {
      id
    }
  }
`;

export default {
  PARTS,
  PART
};
