import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEmpty from "lodash/isEmpty";
import React, { useEffect } from "react";
import { Col, Dropdown, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Formatter from "../../../shared/components/Formatter";
import { Logos } from "../../../utils/Logos/AssemblyGroups";
import { COLOR } from "../../../utils/colors";
import { DEFAULT_LINKING_TARGET_ID } from "../../../utils/constants";

const LogosRender = Object.entries(Logos).reduce(
  (prev, [key, value]) => {
    prev[[key]] = () => (
      <img className="menu__list--item--logo" src={value} alt={key} />
    );
    return prev;
  },
  { default: () => null }
);
export default Formatter(
  ({
    data,
    executeQuery,
    onClick,
    loading,
    groups,
    setGroups,
    focusedItem,
    setFocusedItem,
  }) => {
    const history = useHistory();

    useEffect(() => {
      if (!isEmpty(data)) {
        setGroups([...groups, data]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
      <Row className="gutter" style={{ marginLeft: "8px" }}>
        {groups
          .map((level, indexGroup) =>
            level.map((item) => {
              return {
                key: item.id,
                onClick: () => {
                  onClick({ id: item.id, name: item.name });
                },
                style: {
                  backgroundColor: focusedItem.includes(item.id)
                    ? COLOR.ACTIVE
                    : COLOR.TRANSPARENT,
                },
                onMouseEnter: () => {
                  focusedItem[indexGroup] = item.id;
                  setFocusedItem([...focusedItem.slice(0, indexGroup + 1)]);
                  setGroups(groups.slice(0, indexGroup + 1));
                  const variables = {
                    where: {
                      linkingTarget: { id: DEFAULT_LINKING_TARGET_ID },
                      parent: { id: item.id },
                    },
                  };
                  return executeQuery({ variables });
                },
                name: item.name,
                hasChildren: item.hasChildren,

                rightIcon: item.hasChildren ? (
                  focusedItem[focusedItem.length - 1] === item.id && loading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      variant="danger"
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    <FontAwesomeIcon
                      className="m-left-10 "
                      icon={faChevronRight}
                    />
                  )
                ) : null,
              };
            })
          )
          .map((subItem, index) => (
            <Col sm={6} md={3} className="our_products--group">
              {subItem.map(
                ({
                  key,
                  id,
                  onMouseEnter,
                  style,
                  name,
                  onClick,
                  rightIcon,
                }) => {
                  const assemblyId =
                    index === 0 && Object.keys(Logos).includes(key)
                      ? key
                      : "default";
                  const Logo = LogosRender[assemblyId];
                  return (
                    <Dropdown.Item
                      key={id}
                      onClick={onClick}
                      className="p-10 name__category menu__list"
                      style={style}
                      onMouseEnter={onMouseEnter}
                    >
                      <div className="menu__list--item">
                        <Logo></Logo>
                        <span className="m-left-10 ">{name}</span>
                      </div>
                      {rightIcon}
                    </Dropdown.Item>
                  );
                }
              )}
            </Col>
          ))}
      </Row>
    );
  }
);
