import React, { useContext, useState } from "react";
import { TabContext } from "../../../../../../Context/Tab";
import AssemblyGroups from "../../../../../../entities/assembly-groups";
import { SCREENS, TABS_ACTIONS } from "../../../../../../utils/constants";
import { LoaderItem } from "./LoaderItem";

export default (props) => {
  const { dispatch } = useContext(TabContext);
  const { search } = props;
  const {
    linkingTarget,
    manufacturer,
    model,
    manufacturerBean,
    modelBean,
    motorizationBean,
  } = search;

  const [filtredItem, setFiltredItem] = useState("");
  const variables = {
    where: {
      linkingTarget: { id: linkingTarget },
      linked: true,
    },
  };
  const onPress = ({ id, name }) => {
    return dispatch({
      type: TABS_ACTIONS.HISTORY_PUSH,
      payload: {
        screen: SCREENS.ARTICLES,
        props: {
          search: {
            name,
            assemblyGroup: { id, name },
            car: { id: linkingTarget },
            manufacturer,
            model,
            manufacturerBean,
            modelBean,
            motorizationBean,
          },
        },
      },
    });
  };

  return (
    <AssemblyGroups
      filter={variables}
      filtredItem={filtredItem}
      setFiltredItem={setFiltredItem}
      search={search}
      onPress={onPress}
      View={LoaderItem}
    ></AssemblyGroups>
  );
};
