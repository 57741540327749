import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import React from "react";
import { ItemQueries } from "../shared/graphql/queries";

const { ITEM } = ItemQueries;

const Item = ({ View, filter, options, ...props }) => {
  const query = useQuery(ITEM, {
    variables: filter,
    ...options,
  });
  return (
    <View
      {...{
        ...props,
        ...query,
        type: "item",
        meta: "object",
      }}
    />
  );
};

Item.propTypes = {
  View: PropTypes.func.isRequired,
  filter: PropTypes.object,
};

Item.defaultProps = {
  filter: {},
};

export default Item;
