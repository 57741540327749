import gql from "graphql-tag";

export const QUERY_LAST_RECHERCHE_AND_COUNT = gql`
  query lastRechercheAndCount($lastRechercheInput: LastRechercheInput!) {
    lastRechercheAndCount(lastRechercheInput: $lastRechercheInput) {
      reference
      vin
      codeMoteur
      lastVisitedDate
      count
      famille {
        id
        designation
      }
      equipementier {
        id
        designation
      }
      motorisation {
        id
        designation
        modele {
          id
          designation
          marque {
            id
            designation
          }
        }
      }
    }
  }
`;

export const RECHERCHES = gql`
  query recherches($recherchesInput: RecherchesInput) {
    recherches(recherchesInput: $recherchesInput)
  }
`;

export const STATISTIQUES_RECHERCHE_MENSUEL = gql`
  query statistiquesRechercheMensuel($recherchesInput: RecherchesInput) {
    statistiquesRechercheMensuel(recherchesInput: $recherchesInput)
  }
`;

export const STATISTIQUES_RECHERCHE_QUOTIDIEN = gql`
  query statistiquesRechercheQuotidien($recherchesInput: RecherchesInput) {
    statistiquesRechercheQuotidien(recherchesInput: $recherchesInput)
  }
`;

export const STATISTIQUES_RECHERCHE_CLASSEMENT_REFERENCE = gql`
  query statistiquesRechercheClassementReference(
    $recherchesInput: RecherchesInput
  ) {
    statistiquesRechercheClassementReference(recherchesInput: $recherchesInput)
  }
`;

export const STATISTIQUES_RECHERCHE_CLASSEMENT_MARQUE = gql`
  query statistiquesRechercheClassementMarque(
    $recherchesInput: RecherchesInput
  ) {
    statistiquesRechercheClassementMarque(recherchesInput: $recherchesInput)
  }
`;

export const STATISTIQUES_RECHERCHE_CLASSEMENT_EQUIPEMENTIER = gql`
  query statistiquesRechercheClassementEquipementier(
    $recherchesInput: RecherchesInput
  ) {
    statistiquesRechercheClassementEquipementier(
      recherchesInput: $recherchesInput
    )
  }
`;

export const STATISTIQUES_RECHERCHE_CLASSEMENT_FAMILLE = gql`
  query statistiquesRechercheClassementFamille(
    $recherchesInput: RecherchesInput
  ) {
    statistiquesRechercheClassementFamille(recherchesInput: $recherchesInput)
  }
`;

export const STATISTIQUES_RECHERCHE_CLASSEMENT_CLIENT = gql`
  query statistiquesRechercheClassementClient(
    $recherchesInput: RecherchesInput
  ) {
    statistiquesRechercheClassementClient(recherchesInput: $recherchesInput)
  }
`;

export const STATISTIQUES_RECHERCHE_REPARTITION_TYPE = gql`
  query statistiquesRechercheRepartitionType(
    $recherchesInput: RecherchesInput
  ) {
    statistiquesRechercheRepartitionType(recherchesInput: $recherchesInput)
  }
`;
