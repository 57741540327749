import flatten from "lodash/flatten";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import { cacheQueries } from "../../queries";
const { LOCAL_ARTICLES } = cacheQueries;
export const localAssemblyGroups = (
  { localAssemblyGroups },
  { isIn: { oems = [] }, where },
  { cache }
) => {
  if (localAssemblyGroups) return localAssemblyGroups;

  const data = cache.readQuery({
    query: LOCAL_ARTICLES,
    variables: { where, isIn: {} },
  });

  return {
    __typename: "localAssemblyGroups",
    data: uniqBy(
      flatten(
        get(data, "localArticles.data", [])
          .filter(({ brand: { name } }) => {
            return isEmpty(oems) ? true : oems.includes(name);
          })
          .map(({ assemblyGroups }) => assemblyGroups)
      ),
      "name"
    ),
  };
};
