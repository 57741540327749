import gql from "graphql-tag";

export const CREATE_COMPLAINT = gql`
  mutation CreateComplaint($data: ComplaintWhereInput) {
    createComplaint(data: $data) {
      id
    }
  }
`;

export default {
  CREATE_COMPLAINT
};
