import Loadable from "react-loadable";
import Loading from "../../../../shared/components/Loading";
import { PATHS } from "./../../../../utils/constants";

const List = Loadable({
  loader: () => import("./List"),
  loading: Loading,
});
const Delete = Loadable({
  loader: () => import("./Delete"),
  loading: Loading,
});
const DeleteMany = Loadable({
  loader: () => import("./DeleteMany"),
  loading: Loading,
});

const routes = [
  {
    path: PATHS.CUSTOMER.CART.ITEMS.LIST,
    exact: true,
    component: List,
  },
  {
    path: `${PATHS.CUSTOMER.CART.ITEMS.DELETE}/many`,
    exact: true,
    component: DeleteMany,
  },
  {
    path: `${PATHS.CUSTOMER.CART.ITEMS.DELETE}/:id`,
    exact: true,
    component: Delete,
  },
];

export default routes;
