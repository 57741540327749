import { useMutation } from "@apollo/react-hooks";
import { InputNumber } from "antd";
import { uniqBy } from "lodash";
import React, { useState } from "react";
import { WishListItemsMutations } from "../../../../../shared/graphql/mutations";
import { WishListItemsQueries } from "../../../../../shared/graphql/queries";

const { UPDATE_WISH_LIST_ITEM } = WishListItemsMutations;
const { WISH_LIST_ITEMS } = WishListItemsQueries;

export default (props) => {
  const { record } = props;
  const [item, setItem] = useState(record);
  const [updateWishListItem] = useMutation(UPDATE_WISH_LIST_ITEM);
  const onChange = (value) => {
    setItem({ ...item, quantity: value });
    const { id } = item;
    updateWishListItem({
      variables: {
        data: { id, quantity: value },
        update: (cache, { data: { updateWishListItem } }) => {
          const {
            wishListItems,
            wishListItems: { data, count },
          } = cache.readQuery({
            query: WISH_LIST_ITEMS,
            variables: {
              where: { deleted: false },
            },
          });
          cache.writeQuery({
            query: WISH_LIST_ITEMS,
            variables: {
              where: { deleted: false },
            },
            data: {
              wishListItems: {
                ...wishListItems,
                data: uniqBy([...data, updateWishListItem], "id"),
                count: count,
              },
            },
          });
        },
      },
    });
  };
  return (
    <InputNumber min={1} defaultValue={item.quantity} onChange={onChange} />
  );
};
