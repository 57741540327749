import React, { Component } from "react";

export const PanierContext = React.createContext("panier");

export const PanierConsumer = PanierContext.Consumer;

const INIT_STATE = {
  articlesPourPanier: [],
};

export class PanierProvider extends Component {
  constructor() {
    super();

    this.state = INIT_STATE;
  }
  setArticlesPourPanier = (articlesPourPanier) => {
    this.setState({ ...this.state, articlesPourPanier });
  };

  render() {
    const { children } = this.props;
    const { articlesPourPanier } = this.state;
    return (
      <PanierContext.Provider
        value={{
          articlesPourPanier,
          setArticlesPourPanier: this.setArticlesPourPanier,
        }}
      >
        {children}
      </PanierContext.Provider>
    );
  }
}
