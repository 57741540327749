import flatten from "lodash/flatten";
import React, { useState } from "react";
import AssemblyGroups from "../../../lazy-entities/assembly-groups";
import { PRODUCTS } from "../../../utils/constants";
import View from "./view";
const items = flatten(
  PRODUCTS.map((product) => {
    return product.items
      .map(({ id, name, order, hasChildren }) => {
        return {
          id,
          name,
          order,
          hasChildren,
        };
      })
      .filter((x) => x);
  })
).sort((a, b) => a.order - b.order);

export const ProductItems = ({ onClick }) => {
  const [groups, setGroups] = useState([items]);
  const [focusedItem, setFocusedItem] = useState([]);
  return (
    <AssemblyGroups
      onClick={onClick}
      View={View}
      groups={groups}
      setGroups={setGroups}
      focusedItem={focusedItem}
      setFocusedItem={setFocusedItem}
    ></AssemblyGroups>
  );
};
