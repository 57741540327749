const a_3 = require("./../../shared/assets/images/ADLY MOTORCYCLES.png");
const a_4 = require("./../../shared/assets/images/AEON MOTORCYCLES.png");
const a_13 = require("./../../shared/assets/images/ASIA MOTORS.png");
const a_14 = require("./../../shared/assets/images/ASIAWING MOTORCYCLES.png");
const a_18 = require("./../../shared/assets/images/AUSTIN-HEALEY.png");
const a_21 = require("./../../shared/assets/images/BAOTIAN MOTORCYCLES.jpg");
const a_24 = require("./../../shared/assets/images/BEELINE MOTORCYCLES.png");
const a_28 = require("./../../shared/assets/images/BETA MOTORCYCLES.jpg");
const a_33 = require("./../../shared/assets/images/BOND.jpg");
const a_36 = require("./../../shared/assets/images/BRIXTON MOTORCYCLES.png");
const a_37 = require("./../../shared/assets/images/BUELL MOTORCYCLES.png");
const a_42 = require("./../../shared/assets/images/CALLAWAY.jpg");
const a_43 = require("./../../shared/assets/images/CARBODIES.png");
const a_45 = require("./../../shared/assets/images/CCM MOTORCYCLES.png");
const a_46 = require("./../../shared/assets/images/CEZETA MOTORCYCLES.jpg");
const a_47 = require("./../../shared/assets/images/CF MOTO MOTORCYCLES.png");
const a_49 = require("./../../shared/assets/images/CHECKER.jpg");
const a_55 = require("./../../shared/assets/images/DAELIM MOTORCYCLES.jpg");
const a_61 = require("./../../shared/assets/images/DE LOREAN.png");
const a_63 = require("./../../shared/assets/images/DERBI MOTORCYCLES.png");
const a_67 = require("./../../shared/assets/images/ECM MOTORCYCLES.jpg");
const a_68 = require("./../../shared/assets/images/FANTIC MOTORCYCLES.png");
const a_76 = require("./../../shared/assets/images/GASGAS MOTORCYCLES.png");
const a_79 = require("./../../shared/assets/images/GENERIC MOTORCYCLES.png");
const a_81 = require("./../../shared/assets/images/GILERA MOTORCYCLES.jpg");
const a_83 = require("./../../shared/assets/images/GLAS.png");
const a_85 = require("./../../shared/assets/images/GOVECS MOTORCYCLES.png");
const a_89 = require("./../../shared/assets/images/HERCULES MOTORCYCLES.png");
const a_91 = require("./../../shared/assets/images/HMRacing MOTORCYCLES.jpg");
const a_92 = require("./../../shared/assets/images/HOBBYCAR.jpg");
const a_95 = require("./../../shared/assets/images/HOREX MOTORCYCLES.jpg");
const a_97 = require("./../../shared/assets/images/HUSABERG MOTORCYCLES.png");
const a_98 = require("./../../shared/assets/images/HUSQVARNA MOTORCYCLE.png");
const a_99 = require("./../../shared/assets/images/HYOSUNG MOTORCYCLES.png");
const a_102 = require("./../../shared/assets/images/INDIGO.jpg");
const a_105 = require("./../../shared/assets/images/IRAN KHODRO IKCO.png");
const a_106 = require("./../../shared/assets/images/IRMSCHER.jpg");
const a_108 = require("./../../shared/assets/images/ISORIVOLTA.png");
const a_110 = require("./../../shared/assets/images/ITALJET MOTORCYCLES.jpg");
const a_112 = require("./../../shared/assets/images/IZH.jpg");
const a_114 = require("./../../shared/assets/images/JAWA MOTORCYCLES.png");
const a_118 = require("./../../shared/assets/images/KEEWAY MOTORCYCLES.png");
const a_120 = require("./../../shared/assets/images/KREIDLER MOTORCYCLES.png");
const a_121 = require("./../../shared/assets/images/KSR MOTO MOTORCYCLES.jpg");
const a_124 = require("./../../shared/assets/images/KUBA MOTOR MC.png");
const a_125 = require("./../../shared/assets/images/KYMCO MOTORCYCLES.jpg");
const a_131 = require("./../../shared/assets/images/LAVERDA MOTORCYCLES.jpg");
const a_132 = require("./../../shared/assets/images/LDV.jpg");
const a_136 = require("./../../shared/assets/images/LML MOTORCYCLES.png");
const a_138 = require("./../../shared/assets/images/LTI.png");
const a_139 = require("./../../shared/assets/images/LUXXON MOTORCYCLES.png");
const a_141 = require("./../../shared/assets/images/MAICO MOTORCYCLES.png");
const a_142 = require("./../../shared/assets/images/MALAGUTI MOTORCYCLES.jpg");
const a_145 = require("./../../shared/assets/images/MASH MOTORCYCLES.png");
const a_148 = require("./../../shared/assets/images/MBK MOTORCYCLES.png");
const a_150 = require("./../../shared/assets/images/MEGA.png");
const a_151 = require("./../../shared/assets/images/MEGELLI MOTORCYCLES.png");
const a_153 = require("./../../shared/assets/images/METROCAB.png");
const a_154 = require("./../../shared/assets/images/MG.png");
const a_155 = require("./../../shared/assets/images/MIDDLEBRIDGE.jpg");
const a_156 = require("./../../shared/assets/images/MINELLI.jpg");
const a_162 = require("./../../shared/assets/images/MOSKVICH.png");
const a_164 = require("./../../shared/assets/images/MOTO-MORINI MC.png");
const a_165 = require("./../../shared/assets/images/MOTOBI MOTORCYCLES.jpg");
const a_166 = require("./../../shared/assets/images/MOTOWELL MC.png");
const a_168 = require("./../../shared/assets/images/MZ MOTORCYCLES.png");
const a_169 = require("./../../shared/assets/images/NIPPONIA MOTORCYCLES.jpg");
const a_171 = require("./../../shared/assets/images/NIU MOTORCYCLES.png");
const a_172 = require("./../../shared/assets/images/NSU.png");
const a_178 = require("./../../shared/assets/images/PANTHER.jpg");
const a_181 = require("./../../shared/assets/images/PGO MOTORCYCLES.jpg");
const a_183 = require("./../../shared/assets/images/PIAGGIO MOTORCYCLES.png");
const a_191 = require("./../../shared/assets/images/PUCH MOTORCYCLES.png");
const a_192 = require("./../../shared/assets/images/QUADRO MOTORCYCLES.png");
const a_193 = require("./../../shared/assets/images/RANGER.jpg");
const a_194 = require("./../../shared/assets/images/RAYTON FISSORE.png");
const a_195 = require("./../../shared/assets/images/REGAL RAPTOR MC.jpg");
const a_196 = require("./../../shared/assets/images/RELIANT.png");
const a_199 = require("./../../shared/assets/images/REX MOTORCYCLES.jpg");
const a_201 = require("./../../shared/assets/images/RIVERO MOTORCYCLES.png");
const a_206 = require("./../../shared/assets/images/SAAB.png");
const a_207 = require("./../../shared/assets/images/SACHS MOTORCYCLES.png");
const a_208 = require("./../../shared/assets/images/SAMSUNG.png");
const a_209 = require("./../../shared/assets/images/SANGLAS MOTORCYCLES.jpg");
const a_210 = require("./../../shared/assets/images/SANTANA.png");
const a_211 = require("./../../shared/assets/images/SCOMADI MOTORCYCLES.jpg");
const a_213 = require("./../../shared/assets/images/SFM MOTORCYCLE.png");
const a_215 = require("./../../shared/assets/images/SIMSON MOTORCYCLES.png");
const a_216 = require("./../../shared/assets/images/SIPANI.jpg");
const a_219 = require("./../../shared/assets/images/SOLO MOTORCYCLES.png");
const a_220 = require("./../../shared/assets/images/SOMMER MOTORCYCLES.png");
const a_221 = require("./../../shared/assets/images/SPECTRE.jpg");
const a_224 = require("./../../shared/assets/images/STANDARD.jpg");
const a_225 = require("./../../shared/assets/images/STEYR.png");
const a_226 = require("./../../shared/assets/images/STREETSCOOTER.jpg");
const a_230 = require("./../../shared/assets/images/SWM MOTORCYCLES.jpeg");
const a_231 = require("./../../shared/assets/images/SYM MOTORCYCLES.jpg");
const a_234 = require("./../../shared/assets/images/TAZZARI.png");
const a_236 = require("./../../shared/assets/images/TGB MOTORCYCLES.png");
const a_237 = require("./../../shared/assets/images/THINK.png");
const a_238 = require("./../../shared/assets/images/TOMOS MOTORCYCLES.jpg");
const a_239 = require("./../../shared/assets/images/TORNAX MOTORCYCLES.png");
const a_241 = require("./../../shared/assets/images/TRABANT.png");
const a_244 = require("./../../shared/assets/images/TURBHO MOTORCYCLES.png");
const a_247 = require("./../../shared/assets/images/UM MOTORCYCLES.png");
const a_248 = require("./../../shared/assets/images/UMM.jpg");
const a_251 = require("./../../shared/assets/images/VESPA MOTORCYCLES.jpg");
const a_252 = require("./../../shared/assets/images/VICTORY MOTORCYCLES.jpg");
const a_254 = require("./../../shared/assets/images/VW.png");
const a_258 = require("./../../shared/assets/images/WOLSELEY.jpg");
const a_263 = require("./../../shared/assets/images/ZERO MOTORCYCLES.png");

export const images = {
  "ADLY MOTORCYCLES": a_3,
  "AEON MOTORCYCLES": a_4,
  "ASIA MOTORS": a_13,
  "ASIAWING MOTORCYCLES": a_14,
  "AUSTIN-HEALEY": a_18,
  "BAOTIAN MOTORCYCLES": a_21,
  "BEELINE MOTORCYCLES": a_24,
  "BETA MOTORCYCLES": a_28,
  BOND: a_33,
  "BRIXTON MOTORCYCLES": a_36,
  "BUELL MOTORCYCLES": a_37,
  CALLAWAY: a_42,
  CARBODIES: a_43,
  "CCM MOTORCYCLES": a_45,
  "CEZETA MOTORCYCLES": a_46,
  "CF MOTO MOTORCYCLES": a_47,
  CHECKER: a_49,
  "DAELIM MOTORCYCLES": a_55,
  "DE LOREAN": a_61,
  "DERBI MOTORCYCLES": a_63,
  "ECM MOTORCYCLES": a_67,
  "FANTIC MOTORCYCLES": a_68,
  "GASGAS MOTORCYCLES": a_76,
  "GENERIC MOTORCYCLES": a_79,
  "GILERA MOTORCYCLES": a_81,
  GLAS: a_83,
  "GOVECS MOTORCYCLES": a_85,
  "HERCULES MOTORCYCLES": a_89,
  "HMRacing MOTORCYCLES": a_91,
  HOBBYCAR: a_92,
  "HOREX MOTORCYCLES": a_95,
  "HUSABERG MOTORCYCLES": a_97,
  "HUSQVARNA MOTORCYCLE": a_98,
  "HYOSUNG MOTORCYCLES": a_99,
  INDIGO: a_102,
  "IRAN KHODRO(IKCO)": a_105,
  IRMSCHER: a_106,
  ISORIVOLTA: a_108,
  "ITALJET MOTORCYCLES": a_110,
  IZH: a_112,
  "JAWA MOTORCYCLES": a_114,
  "KEEWAY MOTORCYCLES": a_118,
  "KREIDLER MOTORCYCLES": a_120,
  "KSR MOTO MOTORCYCLES": a_121,
  "KUBA MOTOR MC": a_124,
  "KYMCO MOTORCYCLES": a_125,
  "LAVERDA MOTORCYCLES": a_131,
  LDV: a_132,
  "LML MOTORCYCLES": a_136,
  LTI: a_138,
  "LUXXON MOTORCYCLES": a_139,
  "MAICO MOTORCYCLES": a_141,
  "MALAGUTI MOTORCYCLES": a_142,
  "MASH MOTORCYCLES": a_145,
  "MBK MOTORCYCLES": a_148,
  MEGA: a_150,
  "MEGELLI MOTORCYCLES": a_151,
  METROCAB: a_153,
  MG: a_154,
  MIDDLEBRIDGE: a_155,
  MINELLI: a_156,
  MOSKVICH: a_162,
  "MOTO-MORINI MC": a_164,
  "MOTOBI MOTORCYCLES": a_165,
  "MOTOWELL MC": a_166,
  "MZ MOTORCYCLES": a_168,
  "NIPPONIA MOTORCYCLES": a_169,
  "NIU MOTORCYCLES": a_171,
  NSU: a_172,
  PANTHER: a_178,
  "PGO MOTORCYCLES": a_181,
  "PIAGGIO MOTORCYCLES": a_183,
  "PUCH MOTORCYCLES": a_191,
  "QUADRO MOTORCYCLES": a_192,
  RANGER: a_193,
  "RAYTON FISSORE": a_194,
  "REGAL RAPTOR MC": a_195,
  RELIANT: a_196,
  "REX MOTORCYCLES": a_199,
  "RIVERO MOTORCYCLES": a_201,
  SAAB: a_206,
  "SACHS MOTORCYCLES": a_207,
  SAMSUNG: a_208,
  "SANGLAS MOTORCYCLES": a_209,
  SANTANA: a_210,
  "SCOMADI MOTORCYCLES": a_211,
  "SFM MOTORCYCLE": a_213,
  "SIMSON MOTORCYCLES": a_215,
  SIPANI: a_216,
  "SOLO MOTORCYCLES": a_219,
  "SOMMER MOTORCYCLES": a_220,
  SPECTRE: a_221,
  STANDARD: a_224,
  STEYR: a_225,
  STREETSCOOTER: a_226,
  "SWM MOTORCYCLES": a_230,
  "SYM MOTORCYCLES": a_231,
  TAZZARI: a_234,
  "TGB MOTORCYCLES": a_236,
  THINK: a_237,
  "TOMOS MOTORCYCLES": a_238,
  "TORNAX MOTORCYCLES": a_239,
  TRABANT: a_241,
  "TURBHO MOTORCYCLES": a_244,
  "UM MOTORCYCLES": a_247,
  UMM: a_248,
  "VESPA MOTORCYCLES": a_251,
  "VICTORY MOTORCYCLES": a_252,
  VW: a_254,
  WOLSELEY: a_258,
  "ZERO MOTORCYCLES": a_263,
};

export const links = {
  ABARTH: "https://www.carlogos.org/logo/Abarth-logo.png",
  ACURA: "https://www.carlogos.org/logo/Acura-logo.png",
  AIXAM: "https://www.carlogos.org/logo/Aixam-logo.png",
  "ALFA ROMEO": "https://www.carlogos.org/logo/Alfa-Romeo-logo.png",
  ALPINA: "https://www.carlogos.org/logo/Alpina-logo.png",
  ALPINE: "https://www.carlogos.org/logo/Alpine-logo.png",
  AMC: "https://www.carlogos.org/logo/American-Motors-logo.png",
  ARO: "https://www.carlogos.org/logo/ARO-logo.png",
  ARTEGA: "https://www.carlogos.org/logo/Artega-logo.png",
  "ASTON MARTIN": "https://www.carlogos.org/logo/Aston-Martin-logo.png",
  AUDI: "https://www.carlogos.org/logo/Audi-logo.png",
  AUSTIN: "https://www.carlogos.org/logo/Austin-logo.png",
  "AUTO UNION": "https://www.carlogos.org/logo/DKW-Auto-Union-logo-640x532.jpg",
  AUTOBIANCHI: "https://www.carlogos.org/logo/Autobianchi-logo.png",
  BARKAS:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/IFA_Barkas_Logo.svg/800px-IFA_Barkas_Logo.svg.png",
  BEDFORD:
    "https://seeklogo.com/images/B/bedford-logo-8E18B8A644-seeklogo.com.png",
  BENTLEY: "https://www.carlogos.org/logo/Bentley-logo.png",
  BERTONE: "https://www.carlogos.org/logo/Bertone-logo.png",
  BITTER: "https://www.carlogos.org/logo/Bitter-logo.png",
  BMW: "https://www.carlogos.org/logo/BMW-logo.png",
  BORGWARD: "https://www.carlogos.org/logo/Borgward-logo.png",
  BRISTOL: "https://www.carlogos.org/logo/Bristol-Cars-logo.png",
  BUGATTI: "https://www.carlogos.org/logo/Bugatti-logo.png",
  BUICK: "https://www.carlogos.org/logo/Buick-logo.png",
  CADILLAC: "https://www.carlogos.org/logo/Cadillac-logo.png",
  CATERHAM: "https://www.carlogos.org/logo/Caterham-logo.png",
  CHANGAN: "https://www.carlogos.org/logo/Changan-logo.png",
  CHERY: "https://www.carlogos.org/logo/Chery-logo.png",
  CHEVROLET: "https://www.carlogos.org/logo/Chevrolet-logo.png",
  CHRYSLER: "https://www.carlogos.org/logo/Chrysler-logo.png",
  CITROËN: "https://www.carlogos.org/logo/Citroen-logo.png",
  DACIA: "https://www.carlogos.org/logo/Dacia-logo.png",
  DAEWOO: "https://www.carlogos.org/logo/Daewoo-logo.png",
  DAF: "https://www.carlogos.org/logo/DAF-logo.png",
  DAIHATSU: "https://www.carlogos.org/logo/Daihatsu-logo.png",
  DAIMLER: "https://www.carlogos.org/logo/Daimler-logo.png",
  "DE TOMASO": "https://www.carlogos.org/logo/De-Tomaso-logo.png",
  DODGE: "https://www.carlogos.org/logo/Dodge-logo.png",
  DS: "https://www.carlogos.org/logo/DS-logo.png",
  FERRARI: "https://www.carlogos.org/logo/Ferrari-logo.png",
  FIAT: "https://www.carlogos.org/logo/Fiat-logo.png",
  FISKER: "https://www.carlogos.org/logo/Fisker-logo.png",
  FORD: "https://www.carlogos.org/logo/Ford-logo.png",
  "FORD OTOSAN": "https://www.carlogos.org/logo/Ford-logo.png",
  "FORD USA": "https://www.carlogos.org/logo/Ford-logo.png",
  FSO: "https://cdn.freebiesupply.com/logos/large/2x/fso-logo-png-transparent.png",
  GAZ: "https://www.carlogos.org/logo/GAZ-logo.png",
  GEELY: "https://www.carlogos.org/logo/Geely-logo.png",
  GEO: "https://www.carlogos.org/logo/Geo-logo.png",
  GINETTA: "https://www.carlogos.org/logo/Ginetta-logo.png",
  GMC: "https://www.carlogos.org/logo/GMC-logo.png",
  HINDUSTAN: "https://www.carlogos.org/logo/Hindustan-Motors-logo.png",
  HONDA: "https://www.carlogos.org/logo/Honda-logo.png",
  "GREAT WALL": "https://www.carlogos.org/logo/Great-Wall-logo.png",
  HAFEI: "https://www.carlogos.org/logo/Hafei-logo.png",
  HUMMER: "https://www.carlogos.org/logo/Hummer-logo.png",
  HYUNDAI: "https://www.carlogos.org/logo/Hyundai-logo.png",
  INFINITI: "https://www.carlogos.org/logo/Infiniti-logo.png",
  INNOCENTI: "https://www.carlogos.org/logo/Innocenti-logo.png",
  ISDERA: "https://www.carlogos.org/logo/Isdera-logo.png",
  ISUZU: "https://www.carlogos.org/logo/Isuzu-logo.png",
  IVECO: "https://www.carlogos.org/logo/Iveco-logo.png",
  JAGUAR: "https://www.carlogos.org/logo/Jaguar-logo.png",
  JEEP: "https://www.carlogos.org/logo/Jeep-logo.png",
  JENSEN: "https://www.carlogos.org/logo/Jensen-logo.png",
  KIA: "https://www.carlogos.org/logo/Kia-logo.png",
  KTM: "https://www.carlogos.org/logo/KTM-logo.png",
  LADA: "https://www.carlogos.org/logo/Lada-logo.png",
  LAMBORGHINI: "https://www.carlogos.org/logo/Lamborghini-logo.png",
  LANCIA: "https://www.carlogos.org/logo/Lancia-logo.png",
  "LAND ROVER": "https://www.carlogos.org/logo/Land-Rover-logo.png",
  "LANDWIND (JMC)": "https://www.carlogos.org/logo/Landwind-logo.png",
  LEXUS: "https://www.carlogos.org/logo/Lexus-logo.png",
  LIGIER: "https://www.carlogos.org/logo/Ligier-logo.png",
  LINCOLN: "https://www.carlogos.org/logo/Lincoln-logo.png",
  LOTUS: "https://www.carlogos.org/logo/Lotus-logo.png",
  MAHINDRA: "https://www.carlogos.org/logo/Mahindra-logo.png",
  MARCOS: "https://www.carlogos.org/logo/Marcos-logo.png",
  MASERATI: "https://www.carlogos.org/logo/Maserati-logo.png",
  MAYBACH: "https://www.carlogos.org/logo/Maybach-logo.png",
  MAZDA: "https://www.carlogos.org/logo/Mazda-logo.png",
  MCLAREN: "https://www.carlogos.org/logo/McLaren-logo.png",
  "MERCEDES-BENZ": "https://www.carlogos.org/logo/Mercedes-Benz-logo.png",
  MINI: "https://www.carlogos.org/logo/Mini-logo.png",
  MITSUBISHI: "https://www.carlogos.org/logo/Mitsubishi-logo.png",
  MITSUOKA: "https://www.carlogos.org/logo/Mitsuoka-logo.png",
  MORGAN: "https://www.carlogos.org/logo/Morgan-logo.png",
  MORRIS: "https://www.carlogos.org/logo/Morris-logo.png",
  NISSAN: "https://www.carlogos.org/logo/Nissan-logo.png",
  OLDSMOBILE: "https://www.carlogos.org/logo/Oldsmobile-logo.png",
  OLTCIT: "https://www.carlogos.org/logo/Oltcit-logo.png",
  OPEL: "https://www.carlogos.org/logo/Opel-logo.png",
  OSCA: "https://www.carlogos.org/logo/OSCA-logo.png",
  PANOZ: "https://www.carlogos.org/logo/Panoz-logo.png",
  PEUGEOT: "https://www.carlogos.org/logo/Peugeot-logo.png",
  "PEUGEOT MOTORCYCLES": "https://www.carlogos.org/logo/Peugeot-logo.png",
  PININFARINA: "https://www.carlogos.org/logo/Pininfarina-logo.png",
  PLYMOUTH: "https://www.carlogos.org/logo/Plymouth-logo.png",
  PONTIAC: "https://www.carlogos.org/logo/Pontiac-logo.png",
  PORSCHE: "https://www.carlogos.org/logo/Porsche-logo.png",
  PREMIER: "https://www.carlogos.org/logo/Premier-logo.png",
  PROTON: "https://www.carlogos.org/logo/Proton-logo.png",
  RENAULT: "https://www.carlogos.org/logo/Renault-logo.png",
  "RENAULT TRUCKS": "https://www.carlogos.org/logo/Renault-logo.png",
  RILEY: "https://www.carlogos.org/logo/Riley-logo.png",
  "ROLLS-ROYCE": "https://www.carlogos.org/logo/Rolls-Royce-logo.png",
  ROVER: "https://www.carlogos.org/logo/Rover-logo.png",
  RUF: "https://www.carlogos.org/logo/Ruf-logo.png",
  SEAT: "https://www.carlogos.org/logo/SEAT-logo.png",
  SHELBY: "https://www.carlogos.org/logo/Shelby-logo.png",
  SKODA: "https://www.carlogos.org/logo/Skoda-logo.png",
  SMART: "https://www.carlogos.org/logo/Smart-logo.png",
  SPYKER: "https://www.carlogos.org/logo/Spyker-logo.png",
  SSANGYONG: "https://www.carlogos.org/logo/SsangYong-logo.png",
  SUBARU: "https://www.carlogos.org/logo/Subaru-logo.png",
  SUZUKI: "https://www.carlogos.org/logo/Suzuki-logo.png",
  "SUZUKI MOTORCYCLES":
    "https://www.carlogos.org/reviews-images/20180412-Suzuki-logo-2200x500.png",
  TALBOT: "https://www.carlogos.org/logo/Talbot-logo.png",
  TATA: "https://www.carlogos.org/logo/Tata-logo.png",
  TESLA: "https://www.carlogos.org/logo/Tesla-logo.png",
  TOYOTA: "https://www.carlogos.org/logo/Toyota-logo.png",
  TRIUMPH: "https://www.carlogos.org/logo/Triumph-logo.png",
  TVR: "https://www.carlogos.org/logo/TVR-logo.png",
  UAZ: "https://www.carlogos.org/logo/UAZ-logo.png",
  VAUXHALL: "https://www.carlogos.org/logo/Vauxhall-logo.png",
  VECTOR: "https://www.carlogos.org/logo/Vector-Motors-logo.png",
  VOLVO: "https://www.carlogos.org/logo/Volvo-logo.png",
  WARTBURG: "https://www.carlogos.org/logo/Wartburg-logo.png",
  WESTFIELD: "https://www.carlogos.org/logo/Westfield-logo.png",
  WIESMANN: "https://www.carlogos.org/logo/Wiesmann-logo.png",
  YULON: "https://www.carlogos.org/logo/Yulon-logo.png",
  ZASTAVA: "https://www.carlogos.org/logo/Zastava-logo.png",
  ZAZ: "https://www.carlogos.org/logo/ZAZ-logo.png",
  "APRILIA MOTORCYCLES":
    "https://www.carlogos.org/reviews-images/20180412-Aprilia-logo-3550x1798.png",
  "BENELLI MOTORCYCLES":
    "https://www.carlogos.org/reviews-images/20180412-Benelli-logo-1920x1080.png",
  "BIMOTA MOTORCYCLES":
    "https://www.carlogos.org/reviews-images/20180412-Bimota-logo-640x415.jpg",
  "BMW MOTORCYCLES": "https://www.carlogos.org/logo/BMW-logo.png",
  "CAGIVA MOTORCYCLES":
    "https://www.carlogos.org/reviews-images/20180412-Cagiva-logo-640x480.jpg",
  "INDIAN MOTORCYCLES":
    "https://www.carlogos.org/reviews-images/20180412-Indian-Motorcycle-logo-640x224.jpg",
  "HONDA MOTORCYCLES":
    "https://www.carlogos.org/reviews-images/20180412-Honda-Motorcycle-logo-3000x2500.png",
  "DUCATI MOTORCYCLES":
    "https://www.carlogos.org/reviews-images/20180412-Ducati-logo-3000x2500.png",
  "KAWASAKI MOTORCYCLES":
    "https://www.carlogos.org/reviews-images/20180412-Kawasaki-logo-2000x1000.png",
  "HARLEY-DAVIDSON MC":
    "https://www.carlogos.org/reviews-images/20180412-Harley-Davidson-logo-1920x1080.png",
  "ROYAL ENFIELD MC":
    "https://www.carlogos.org/reviews-images/20180412-Royal-Enfield-logo-4000x2000.png",
  "MV AGUSTA MOTORCYCLE":
    "https://www.carlogos.org/reviews-images/20180412-MV-Agusta-logo-1920x1080.png",
  "MOTO GUZZI MC":
    "https://www.carlogos.org/reviews-images/20180412-Moto-Guzzi-logo-2500x1000.png",
  "KTM MOTORCYCLES":
    "https://www.carlogos.org/reviews-images/20180412-KTM-logo-2000x785.png",
  "YAMAHA MOTORCYCLES":
    "https://www.carlogos.org/reviews-images/20180412-Yamaha-Motorcycles-logo-5000x2000.png",
  "TRIUMPH MOTORCYCLES": "https://www.carlogos.org/logo/Triumph-logo.png",
  default: "https://www.freeiconspng.com/uploads/no-image-icon-13.png",
};

export default { links, images };
