import SingIn from "./Signin";
import Access from "./Access";
import Challenge from "./ForgotPassword/Challenge";
import Verify from "./ForgotPassword/Verify";
import PublicRoute from "../../shared/authorization/PublicRoute";
import { PATHS } from "../../utils/constants";

const routes = [
  {
    path: PATHS.LOGIN,
    exact: true,
    component: SingIn
  },
  {
    path: "/access",
    exact: true,
    component: Access
  },
  {
    path: "/reset-password/challenge",
    exact: true,
    component: Challenge
  },
  {
    path: "/reset-password/verify",
    exact: true,
    component: Verify
  }
];

export default routes.map(({ component: Component, ...rest }) => ({
  component: () => PublicRoute({ component: Component }),
  ...rest
}));
