import { Spin } from "antd";
import React, { useContext } from "react";
import { TabContext } from "../../Context/Tab";
import Manufacturer from "../../entities/manufacturer";
import Loader from "../../shared/components/Loader";
import { SCREENS, TABS_ACTIONS } from "../../utils/constants";
import Path from "./Path";

const View = ({ manufacturer, data }) => {
  const { dispatch } = useContext(TabContext);

  const path = [
    {
      text: data.name,
      onPress: () =>
        dispatch({
          type: TABS_ACTIONS.HISTORY_PUSH,
          payload: {
            screen: SCREENS.MODELS,
            props: {
              search: {
                manufacturer,
                manufacturerBean: data,
              },
            },
          },
        }),
    },
  ];
  return <Path path={path}></Path>;
};

export default ({ manufacturer }) => {
  const variables = { where: { id: manufacturer } };

  return (
    <Manufacturer
      manufacturer={manufacturer}
      filter={variables}
      NoData={() => null}
      View={Loader(View)}
      Loading={() => <Spin size="large" />}
    ></Manufacturer>
  );
};
