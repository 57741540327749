import get from "lodash/get";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import Breadcrumb from "../../../../../../shared/components/Breadcrumb";
import Loader from "../../../../../../shared/components/Loader";
import ProductCard from "../../../../../../shared/components/stories/ProductCard";
import {
  Logos,
  TitleLogos,
} from "../../../../../../utils/Logos/AssemblyGroups";
import { PRODUCTS } from "../../../../../../utils/constants";
import LinkedAssamblyGroup from "./LinkedAssamblyGroup";
import GroupeItem from "./view";

const Item = ({ data, search, filtredItem, setFiltredItem, onPress }) => {
  const { linkingTarget } = search;
  const [focusedItems, setFocusedItems] = useState([]);
  return (
    <div className="section full-height">
      <div className="div-vertical growed">
        <div className="titre-div-horizontal">
          <Breadcrumb location={{ search }} />

          <LinkedAssamblyGroup search={search} />
        </div>
        <div className="container">
          <div className="row m-top-10 m-bottom-20">
            {PRODUCTS.map((product) => {
              return (
                <div key={product.id} className="col-md-6 col-lg-4">
                  <ProductCard
                    title={product.title}
                    image={TitleLogos[`${product.title}`] || Logos["default"]}
                    items={product.items
                      .filter((item) =>
                        item.name
                          .toLocaleLowerCase()
                          .includes(filtredItem.toLocaleLowerCase())
                      )
                      .map((item) =>
                        data.find(
                          (assemblyGroup) =>
                            assemblyGroup.id === item.id &&
                            get(item, "hide", false) === false
                        )
                      )
                      .filter((assemblyGroup) => !isNil(assemblyGroup))
                      .map(({ id, name, hasChildren }) => (
                        <GroupeItem
                          key={id + "-" + name}
                          assemblyGroupId={id}
                          name={name}
                          hasChildren={hasChildren}
                          setFocusedItems={setFocusedItems}
                          focusedItems={focusedItems}
                          linkingTarget={{ id: linkingTarget }}
                          onPress={onPress}
                          logo={true}
                        ></GroupeItem>
                      ))}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const LoaderItem = Loader(Item);
