import gql from "graphql-tag";

export const CLAIMS = gql`
  query Claims(
    $where: ClaimWhereInput
    $skip: Int
    $like: ClaimWhereInput
    $take: Int
    $gt: ClaimWhereInput
    $lt: ClaimWhereInput
  ) {
    claims(
      where: $where
      skip: $skip
      take: $take
      like: $like
      gt: $gt
      lt: $lt
    ) {
      data {
        id
        reference
        message
        status
        motif
        createdDate
        updatedDate
        file
        deliveryAddress
        claimInvoiceLines {
          id
          invoiceLine {
            id
            quantity
            invoice {
              id
              reference
            }
          }
        }
      }
      count
    }
  }
`;

export const CLAIM = gql`
  query Claim($where: ClaimWhereInput) {
    claim(where: $where) {
      id
      reference
      message
      status
      motif
      createdDate
      updatedDate
      file
      deliveryAddress
      claimInvoiceLines {
        id
        invoiceLine {
          id
          quantity
          invoice {
            id
            reference
          }
        }
      }
    }
  }
`;

export default {
  CLAIMS,
  CLAIM,
};
