import gql from "graphql-tag";

export const CREATE_ITEM = gql`
  mutation CreateItem($data: ItemWhereInput) {
    createItem(data: $data) {
      id
    }
  }
`;

export const SET_VEDETTE = gql`
  mutation setVedette($setVedetteInput: SetVedetteInput) {
    setVedette(setVedetteInput: $setVedetteInput) {
      message
    }
  }
`;

export default {
  CREATE_ITEM,
};
