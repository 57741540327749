import gql from "graphql-tag";

export const EXPORT_INVOICE = gql`
  mutation exportInvoice($data: InvoiceWhereInput,$gt:InvoiceWhereInput,$lt:InvoiceWhereInput) {
    exportInvoice(data: $data, gt:$gt , lt:$lt)
  }
`;

export default {
  EXPORT_INVOICE
};
