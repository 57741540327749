import gql from "graphql-tag";

export const NOTIFY_CLIENT = gql`
  mutation NotifyClient($data: notificationWhereInput) {
    notifyClient(data: $data) 
  }
`;

export default {
  NOTIFY_CLIENT
};