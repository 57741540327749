import gql from "graphql-tag";

export const UPDATE_CART = gql`
  mutation UpdateCart($data: CartWhereInput) {
    updateCart(data: $data) {
      id
      article {
        id
        name
      }
    }
  }
`;

export default {
  UPDATE_CART
};
