import React from "react";
import WishListItems from "../../../../entities/wishListItems";
import Loader from "../../../../shared/components/Loader";
import View from "./View";

export default () => {
  const variables = {
    where: { deleted: false },
  };
  return (
    <WishListItems
      filter={variables}
      View={Loader(View)}
      NoData={View}
    ></WishListItems>
  );
};
