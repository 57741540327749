import Loadable from "react-loadable";
import Loading from "../../../shared/components/Loading";
import Me from "./Me";

const Password = Loadable({
  loader: () => import("./Password"),
  loading: Loading,
});

const routes = [
  ...Me,
  {
    path: "/profile/reset-password",
    exact: true,
    component: Password,
  },
];

export default routes;
