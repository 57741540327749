import gql from "graphql-tag";

export const CREATE_WISH_LIST_ITEM = gql`
  mutation CreateWishListItem($data: WishListItemWhereInput) {
    createWishListItem(data: $data) {
      id
      price
      createdDate
      item {
        id
        prices {
          id
          value
          updatedDate
        }
        thumbnail
        discount {
          id
          discountLines {
            id
            min
            max
            startDate
            endDate
            value
            updatedDate
            account {
              title
              id
            }
          }
        }
        itemWareHouses {
          id
          quantity
          wareHouse {
            id
            name
            code
            city
          }
        }
      }

      description
      wishList {
        id
        account {
          id
        }
      }
      quantity
      image
      deleted
    }
  }
`;

export const UPDATE_WISH_LIST_ITEM = gql`
  mutation UpdateWishListItem($data: WishListItemWhereInput) {
    updateWishListItem(data: $data) {
      id
      price
      item {
        id
        prices {
          id
          value
        }
        thumbnail
        discount {
          id
          discountLines {
            id
            min
            max
            startDate
            endDate
            value
            account {
              title
              id
            }
          }
        }
        itemWareHouses {
          id
          quantity
          wareHouse {
            id
            name
            code
            city
          }
        }
      }
      description
      wishList {
        id
      }
      quantity
      image
      deleted
    }
  }
`;

export const DELETE_WISH_LIST_ITEM = gql`
  mutation DeleteWishListItem($data: WishListItemWhereInput) {
    deleteWishListItem(data: $data) {
      id
      price
      item {
        id
        prices {
          id
          value
        }
        thumbnail
        discount {
          id
          discountLines {
            id
            min
            max
            startDate
            endDate
            value
            account {
              title
              id
            }
          }
        }
        itemWareHouses {
          id
          quantity
          wareHouse {
            id
            name
            code
            city
          }
        }
      }
      description
      wishList {
        id
      }
      quantity
      image
      deleted
    }
  }
`;

export const DELETE_WISH_LIST_ITEMS = gql`
  mutation DeleteWishListItems($items: [WishListItemWhereInput]) {
    deleteWishListItems(items: $items) {
      count
      data {
        id
        price
        item {
          id
          prices {
            id
            value
          }
          thumbnail
          discount {
            id
            discountLines {
              id
              min
              max
              startDate
              endDate
              value
              account {
                title
                id
              }
            }
          }
          itemWareHouses {
            id
            quantity
            wareHouse {
              id
              name
              code
              city
            }
          }
        }
        description
        wishList {
          id
        }
        quantity
        image
        deleted
      }
    }
  }
`;

export const EXPORT_WISH_LIST_ITEMS = gql`
  mutation exportWishListItems(
    $data: WishListItemWhereInput
    $gt: WishListItemWhereInput
    $lt: WishListItemWhereInput
  ) {
    exportWishListItems(data: $data, gt: $gt, lt: $lt)
  }
`;

export default {
  CREATE_WISH_LIST_ITEM,
  UPDATE_WISH_LIST_ITEM,
  DELETE_WISH_LIST_ITEMS,
  DELETE_WISH_LIST_ITEM,
  EXPORT_WISH_LIST_ITEMS,
};
