import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import React from "react";
import { ManufacturersQueries } from "../shared/graphql/queries";

const { ASSEMBLY_GROUPS } = ManufacturersQueries;

const AssemblyGroups = ({ View, filter, ...props }) => {
  const query = useQuery(ASSEMBLY_GROUPS, {
    variables: filter,
  });
  return (
    <View
      {...{
        ...props,
        ...query,
        type: "assemblyGroups",
      }}
    />
  );
};

AssemblyGroups.propTypes = {
  View: PropTypes.func.isRequired,
  filter: PropTypes.object,
};

AssemblyGroups.defaultProps = {
  filter: {},
};

export default AssemblyGroups;
