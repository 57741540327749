import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import "./styles.scss";

const ProductCard = ({ image, title, items }) => {
  // modal assembly productcard

  return (
    <Card className="cardProduct">
      <div className="cardProduct__header">
        <Card.Img src={image} className="cardProduct__header-image" />
        <Card.Body>
          <Card.Title className="cardProduct__header-title">{title}</Card.Title>
        </Card.Body>
      </div>
      <ListGroup className="cardProduct__list">{items}</ListGroup>
    </Card>
  );
};

export default ProductCard;
