import gql from "graphql-tag";

export const CREATE_ORDER = gql`
  mutation CreateOrder($data: OrderWhereInput) {
    createOrder(data: $data) {
      id
      cartItem {
        id
      }
    }
  }
`;

export const REPLAY_ORDER = gql`
  mutation ReplayOrder($data: OrderReplayWhereInput) {
    replayOrder(data: $data) {
      id
      cartItem {
        id
      }
    }
  }
`;

export const EXPORT_ORDER = gql`
  mutation exportOrder(
    $data: OrderWhereInput
    $gt: OrderWhereInput
    $lt: OrderWhereInput
  ) {
    exportOrder(data: $data, gt: $gt, lt: $lt)
  }
`;

export default {
  CREATE_ORDER,
  REPLAY_ORDER,
  EXPORT_ORDER,
};
