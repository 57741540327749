import gql from "graphql-tag";

export const CRON_JOB_ACTION = gql`
  mutation applyActionCronJob($data: CronJobWhereInput) {
    applyActionCronJob(data: $data)
  }
`;

export default {
  CRON_JOB_ACTION,
};
