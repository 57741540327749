import { Collapse } from "antd";
import { isEmpty } from "lodash";
import get from "lodash/get";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { TabContext } from "../../../../../../../Context/Tab";
import { ManufacturersQueries } from "../../../../../../../shared/graphql/queries";
import { Substitute } from "../../../List/Widgets/Card/Substitutes";

const { Panel } = Collapse;

const { ARTICLE } = ManufacturersQueries;

export default React.memo(({ article }) => {
  const substitutes = get(article, "substitutes", []);

  const { dispatch } = useContext(TabContext);
  const history = useHistory();

  return (
    <div className="descriptions-div">
      <Collapse
        bordered={false}
        expandIconPosition={"right"}
        defaultActiveKey={["1"]}
      >
        <Panel header="Numéros d'échanges" key="1">
          {isEmpty(substitutes) ? (
            <h4 className="not-found-message">Aucun numéro d'échange trouvé</h4>
          ) : (
            <div className="card_article_description_tags grand scroll-hidden">
              {substitutes.map((substitute) => (
                <Substitute
                  key={substitute.id}
                  substitute={substitute}
                  history={history}
                />
              ))}
            </div>
          )}
        </Panel>
      </Collapse>
    </div>
  );
});
