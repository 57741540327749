import { useQuery } from "@apollo/react-hooks";
import { Spin } from "antd";
import { get } from "lodash";
import React from "react";
import { QUERY_JOURNALS } from "../../../../shared/graphql/queries/journal";
import JournalItem from "./journalItem";

const Journal = () => {
  const { refetch, data, loading } = useQuery(QUERY_JOURNALS, {
    fetchPolicy: "network-only",
    variables: {
      journalInput: {
        motCle: "",
        page: 1,
        take: 20,
      },
    },
  });

  if (loading) return <Spin />;

  return (
    <div style={{ overflow: "auto" }}>
      {get(data, "journals.data", []).map((journal) => (
        <JournalItem key={journal.id} journal={journal} />
      ))}
    </div>
  );
};

export default Journal;
