const schedule = {
  SummerMatin: "Lundi - vendredi: 09H00-13H00 15H00-19H00",
  SummerSoire: "Samedi: 09H00 - 13H00",
  WinterMatin: "Lundi - vendredi: 08H30 12H30 14H30 - 18H30",
  WinterSoire: "Samedi: 09H00 - 13H00",
};

const scheduleLayoun = {
  SummerWinter: "Lundi - samedi: 09H30 - 19H00",
};

export default {
  "SIÉGE CASABLANCA": {
    position: [33.5898251, -7.6084525],
    address: "BD khouribga, 7 rue maarakat ait abdellah, Casablanca 20250",
    phone: "05 22 44 40 04/05",
    fax: "05 22 44 40 04",
    email: "contact@gap-maroc.com",
    schedule,
  },

  "SIÉGE CENTRAL": {
    position: [33.5976161, -7.4909905],
    address: "Bd Ahl Loghlam, sidi Bernoussi, Zenata, CP 20400 - Casablanca",
    phone: "05 22 44 30 13",
    fax: "",
    email: "contact@gap-maroc.com",
    schedule,
  },
};
