import Loadable from "react-loadable";
import Loading from "../../../shared/components/Loading";

const View = Loadable({
  loader: () => import("./View"),
  loading: Loading,
});

const routes = [
  {
    path: "/",
    exact: true,
    component: View,
  },
];
export default routes;
