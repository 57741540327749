import React, { useState } from "react";
import { FILES } from "../../../utils/constants";
export default ({
  image,
  style,
  defaultImage = FILES.noAttachementUploaded,
}) => {
  const [src, setSrc] = useState(image);
  return (
    <img
      alt="example"
      style={style}
      src={src}
      onError={() => setSrc(defaultImage)}
    />
  );
};
