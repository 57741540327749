import { Collapse } from "antd";
import React, { useState } from "react";
import ClaimModal from "./Modal/Claims/ClaimModal";
import DeliveryModal from "./Modal/Claims/DeliveryModal";
import OrderModal from "./Modal/Claims/OrderModal";
import PaiementModal from "./Modal/Claims/PaiementModal";
import ProductModal from "./Modal/Claims/ProductModal";
import ResponsabilityModal from "./Modal/Claims/ResponsabilityModal";
const { Panel } = Collapse;

export default () => {
  const [selected, setSelected] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(false);
  const [selectedPaiement, setSelectedPaiement] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState(false);
  const [selectedResponsability, setSelectedResponsability] = useState(false);

  return (
    <>
      <Collapse bordered={false} expandIconPosition={"right"}>
        <Panel header="Nos engagements" className="contact_info_item" key="1">
          <div className="contact_info_item_content">
            <div
              className="contact_info_item_content_link"
              onClick={() => {
                return setSelected(true);
              }}
            >
              Nos valeurs
            </div>
            <div
              className="contact_info_item_content_link"
              onClick={() => {
                return setSelectedProduct(true);
              }}
            >
              Produits
            </div>
            <div
              className="contact_info_item_content_link"
              onClick={() => {
                return setSelectedOrder(true);
              }}
            >
              Suivi de la commande
            </div>
            <div
              className="contact_info_item_content_link"
              onClick={() => {
                return setSelectedPaiement(true);
              }}
            >
              Le paiement
            </div>
            <div
              className="contact_info_item_content_link"
              onClick={() => {
                return setSelectedDelivery(true);
              }}
            >
              La livraison
            </div>
            <div
              className="contact_info_item_content_link"
              onClick={() => {
                return setSelectedResponsability(true);
              }}
            >
              Responsabilité et SAV
            </div>
          </div>
        </Panel>
      </Collapse>
      <ClaimModal selected={selected} setSelected={setSelected} />
      <ProductModal
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
      />
      <OrderModal
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
      />
      <PaiementModal
        selectedPaiement={selectedPaiement}
        setSelectedPaiement={setSelectedPaiement}
      />
      <DeliveryModal
        selectedDelivery={selectedDelivery}
        setSelectedDelivery={setSelectedDelivery}
      />
      <ResponsabilityModal
        selectedResponsability={selectedResponsability}
        setSelectedResponsability={setSelectedResponsability}
      />
    </>
  );
};
