import PrivateRoute from "../../shared/authorization/PrivateRoute";

import { Sales as Layout } from "../../shared/components/Layout";
import Pusher from "../../shared/components/Pusher";
import { PATHS } from "../../utils/constants";
import Catalog from "./Catalog";

const routes = [
  {
    path: PATHS.SALES,
    exact: true,
    component: Catalog,
  }
];

export default routes.map(({ component, ...rest }) => {
  return {
    component: () =>
      PrivateRoute({
        component: Layout({
          component: Pusher(component),
        }),
      }),
    ...rest,
  };
});
