import gql from "graphql-tag";

export const CONNECTOR = gql`
  query connector($where: InputConnector) {
    connector(where: $where) {
      type
      name
      state
    }
  }
`;

export const CONNECTORS = gql`
  query connectors($where: InputConnectors) {
    connectors(where: $where) {
      type
      name
      state
    }
  }
`;

export default {
  CONNECTORS,
  CONNECTOR,
};
