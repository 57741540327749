import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import React from "react";
import { ManufacturersQueries } from "../shared/graphql/queries";

const { VEHICLES } = ManufacturersQueries;

const Vehicles = ({ View, filter, options, ...props }) => {
  // console.log("filter", filter);
  // console.log("options", options);

  const query = useQuery(VEHICLES, {
    variables: filter,
    ...options,
  });
  return (
    <View
      {...{
        ...props,
        ...query,
        type: "vehicles",
      }}
    />
  );
};

Vehicles.propTypes = {
  View: PropTypes.func.isRequired,
  filter: PropTypes.object,
  options: PropTypes.object,
};

Vehicles.defaultProps = {
  filter: {},
  options: {},
};

export default Vehicles;
