import { Collapse } from "antd";
import React, { useState } from "react";
import ApplicationDetailModal from "./Modal/TechnicalHelp/ApplicationDetailModal";
import ReplacementModal from "./Modal/TechnicalHelp/ReplacementModal";

const { Panel } = Collapse;

export default () => {
  const [selected, setSelected] = useState(false);
  const [selectedReplacement, setSelectedReplacement] = useState(false);

  return (
    <>
      <Collapse bordered={false} expandIconPosition={"right"}>
        <Panel header="Aide technique" className="contact_info_item" key="2">
          <div className="contact_info_item_content">
            <div
              className="contact_info_item_content_link"
              onClick={() => setSelectedReplacement(true)}
            >
              Pièces de rechange
            </div>

            <div
              className="contact_info_item_content_link"
              onClick={() => setSelected(true)}
            >
              Siteweb et application mobile
            </div>
          </div>
        </Panel>
      </Collapse>
      <ApplicationDetailModal selected={selected} setSelected={setSelected} />
      <ReplacementModal
        selectedReplacement={selectedReplacement}
        setSelectedReplacement={setSelectedReplacement}
      />
    </>
  );
};
