import {
  CloseCircleOutlined,
  FieldTimeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useQuery } from "@apollo/react-hooks";
import { AutoComplete, Input } from "antd";
import React, { memo, useContext, useState } from "react";
import Highlighter from "react-highlight-words";
import { TabContext } from "../../../Context/Tab";
import { SCREENS, TABS_ACTIONS } from "../../../utils/constants";
import {
  datetimeFormatter,
  numberFormatter,
} from "../../../utils/formatter-functions";
import { format } from "../../../utils/helpers";
import { QUERY_LAST_RECHERCHE_AND_COUNT } from "../../graphql/queries/recherche";

const Recherche = () => {
  const [searchedReference, setSearchedReference] = useState(
    ""
    /*  tabs.items[tabs.activeTab].tab.indexOf(">") > -1 ||
            tabs.items[tabs.activeTab].tab.indexOf("(") > -1
              ? ""
              : tabs.items[tabs.activeTab].tab */
  );

  const { tabs, dispatch } = useContext(TabContext);

  const { Search } = Input;

  // console.log("render recherche");

  const {
    data,
    loading: isLoading,
    error,
    refetch,
  } = useQuery(QUERY_LAST_RECHERCHE_AND_COUNT, {
    fetchPolicy: "network-only",
    variables: {
      lastRechercheInput: {
        motCle: format(searchedReference),
        type: "reference",
        orderBy: "count",
      },
    },
  });

  const { Option, OptGroup } = AutoComplete;

  function renderTitle(title) {
    return (
      <div className="div-horizontal center" style={{ width: "100%" }}>
        <span style={{ flexGrow: "1" }}>{title}</span>
        <CloseCircleOutlined onClick={() => setSearchedReference("")} />
      </div>
    );
  }

  const options = data
    ? [
        <OptGroup
          key={"Historique recherche"}
          label={renderTitle("Historique recherche")}
        >
          {data.lastRechercheAndCount.map((opt) => (
            <Option key={opt.reference} value={opt.reference}>
              <div className="div-vertical">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Highlighter
                    highlightClassName="YourHighlightClass"
                    highlightTag={({ children, highlightIndex }) => (
                      <strong
                        className="highlighted-text"
                        style={{ color: "red" }}
                      >
                        {children}
                      </strong>
                    )}
                    searchWords={[format(searchedReference)]}
                    autoEscape={true}
                    textToHighlight={opt.reference}
                  />

                  <span className="div-horizontal center">
                    <label
                      style={{
                        fontWeight: "bold",
                        margin: "0",
                        marginRight: "5px",
                        textAlign: "right",
                      }}
                    >
                      {"(" + numberFormatter(opt.count) + ")"}
                    </label>
                    <UserOutlined />
                  </span>
                </div>

                <div
                  className="div-horizontal center"
                  style={{ width: "100%" }}
                >
                  <FieldTimeOutlined style={{ color: "rgba(0,0,0,1)" }} />
                  <label
                    style={{
                      margin: 0,
                      marginLeft: "5px",
                      color: "rgba(0,0,0,1)",
                      flexGrow: "1",
                      fontWeight: "bold",
                      fontSize: "11px",
                    }}
                  >
                    {datetimeFormatter(opt.lastVisitedDate)}
                  </label>
                </div>
              </div>
            </Option>
          ))}
        </OptGroup>,
      ]
    : [];

  return (
    <>
      <AutoComplete
        dropdownClassName="recherche-autocomplete-dropdown"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: 300 }}
        size="large"
        value={searchedReference}
        style={{ width: "100%" }}
        dataSource={options}
        placeholder="Cherchez une référence, OE..."
        optionLabelProp="value"
        onChange={(value) => {
          setSearchedReference(value);
        }}
      >
        <Search
          className="search-code-article"
          key="reference"
          size={"large"}
          onSearch={(e) => {
            // console.log("onSearch", e);
            if (e.length >= 3) {
              dispatch({
                type: TABS_ACTIONS.HISTORY_PUSH,
                payload: {
                  screen: SCREENS.ARTICLES,
                  props: {
                    search: {
                      reference: e,
                      type: "direct",
                    },
                  },
                },
              });
            }
          }}
          onPressEnter={(e) => {
            if (e.target.value.length >= 3) {
              dispatch({
                type: TABS_ACTIONS.HISTORY_PUSH,
                payload: {
                  screen: SCREENS.ARTICLES,
                  props: {
                    search: {
                      reference: e.target.value,
                      type: "direct",
                    },
                  },
                },
              });
            }
          }}
        />
      </AutoComplete>
    </>
  );
};

export default memo(Recherche);
