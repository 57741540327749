import gql from "graphql-tag";

export const EXPORT_ASSET = gql`
  mutation exportAsset($data: AssetWhereInput,$gt:AssetWhereInput,$lt:AssetWhereInput) {
    exportAsset(data: $data, gt: $gt, lt: $lt)
  }
`;

export default {
  EXPORT_ASSET
};
