import { useQuery } from "@apollo/react-hooks";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Spin, Tooltip } from "antd";
import { get, isNil } from "lodash";
import flatten from "lodash/flatten";
import React, { useContext, useEffect, useState } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { useHistory } from "react-router-dom";
import { TabContext } from "../../Context/Tab";
import Critere from "../../screens/Customer/Catalog/GlobalSearch/Vehicles/Critere";
import { Logos } from "../../utils/Logos/AssemblyGroups";
import {
  DEFAULT_LINKING_TARGET_ID,
  PRODUCTS,
  SCREENS,
  TABS_ACTIONS,
} from "../../utils/constants";
import { ManufacturersQueries } from "../graphql/queries";
import Heading from "./stories/Heading";

const { ASSEMBLY_GROUPS } = ManufacturersQueries;

const MenuItem = ({
  assemblyGroup: { id, name },
  linkingTarget,
  manufacturerBean,
  modelBean,
  pourCritere,
  assemblyGroupModal,
  setAssemblyGroupModal,
  setVisible,
  motorizationBean,
  onPress,
}) => {
  //

  const { dispatch } = useContext(TabContext);

  return (
    <div
      onClick={() => {
        if (pourCritere) {
          setAssemblyGroupModal({ id, name });
          setVisible(true);
        } else {
          if (onPress) {
            onPress({ id, name });
          } else {
            dispatch({
              type: TABS_ACTIONS.HISTORY_PUSH,
              payload: {
                screen: SCREENS.ARTICLES,
                props: {
                  search: {
                    assemblyGroup: { id, name },
                    car: { id: linkingTarget },
                    manufacturerBean,
                    modelBean,
                    motorizationBean,
                    name,
                  },
                },
              },
            });
          }
        }
      }}
      className={"menu-item filterTop__details_scroll_horizontal_item"}
    >
      <Tooltip
        placement="top"
        title={name}
        overlayClassName={"filterTop__details_scroll_horizontal_item_tooltip"}
      >
        <div className="filterTop__details_scroll_horizontal_item_image">
          <img
            className="text-center logobrand"
            src={Logos[id] || Logos["default"]}
            alt=""
          />
        </div>
      </Tooltip>
    </div>
  );
};

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({
  text: <FontAwesomeIcon icon={faChevronCircleLeft} />,
  className: "arrow-prev",
});

const ArrowRight = Arrow({
  text: <FontAwesomeIcon icon={faChevronCircleRight} />,
  className: "arrow-next",
});

const Menu = ({
  products,
  linkingTarget,
  history,
  manufacturerBean,
  modelBean,
  pourCritere,
  assemblyGroupModal,
  setAssemblyGroupModal,
  setVisible,
  motorizationBean,
  onPress,
}) =>
  products.map((assemblyGroup, index) => (
    <MenuItem
      assemblyGroup={assemblyGroup}
      linkingTarget={linkingTarget}
      key={index}
      pourCritere={pourCritere}
      assemblyGroupModal={assemblyGroupModal}
      setAssemblyGroupModal={setAssemblyGroupModal}
      setVisible={setVisible}
      manufacturerBean={manufacturerBean}
      modelBean={modelBean}
      motorizationBean={motorizationBean}
      history={history}
      onPress={onPress}
    />
  ));

export default (props) => {
  const { search = {} } = props;
  const history = useHistory();
  const { car, manufacturerBean, modelBean, motorizationBean, critereBean } =
    search;

  //
  //
  console.log("props", props);

  const [visible, setVisible] = useState(false);
  const [assemblyGroupModal, setAssemblyGroupModal] = useState({});

  const linkingTarget = get(car, "id") || get(props.linkingTarget, "id");

  if (!linkingTarget && !critereBean) return null;
  const variables = {
    where: {
      linkingTarget: {
        id: linkingTarget ? linkingTarget : DEFAULT_LINKING_TARGET_ID,
      },
      linked: true,
    },
  };
  const { loading, error, data } = useQuery(ASSEMBLY_GROUPS, {
    variables,
  });
  const assemblyGroups = get(data, "assemblyGroups.data", []);

  const products = React.useMemo(() => {
    return flatten(
      PRODUCTS.map(({ items }) =>
        items
          .filter(
            (item) =>
              assemblyGroups.find(
                (assemblyGroup) => assemblyGroup.id === item.id
              ) && get(item, "hide", false) === false
          )
          .filter((assemblyGroup) => !isNil(assemblyGroup))
      )
    ).sort((a, b) => a.order - b.order);
  }, [assemblyGroups]);

  const [menu, setMenu] = useState(
    Menu({
      products,
      pourCritere: critereBean ? true : false,
      assemblyGroupModal,
      setAssemblyGroupModal,
      setVisible,
      linkingTarget,
      manufacturerBean,
      modelBean,
      motorizationBean,
      history,
      onPress: props.onPress,
    })
  );

  useEffect(
    () => {
      if (!loading)
        setMenu(
          Menu({
            products,
            pourCritere: critereBean ? true : false,
            assemblyGroupModal,
            setAssemblyGroupModal,
            setVisible,
            linkingTarget,
            manufacturerBean,
            modelBean,
            motorizationBean,
            history,
            onPress: props.onPress,
          })
        );
    },
    // eslint-disable-next-line
    [loading]
  );

  if (loading) return <Spin></Spin>;
  if (error) return `Error! ${error.message}`;
  return (
    <div className="div-horizontal center">
      <div className="filterTop__details_scroll_horizontal">
        <ScrollMenu
          data={menu}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          scrollToSelected={true}
          wheel={true}
          hideArrows={true}
          hideSingleArrow={true}
          transition={0.3}
        />

        <Modal
          className="form-modal"
          visible={visible && assemblyGroupModal}
          footer={null}
          onCancel={() => setVisible(false)}
          title={<Heading title={"Recherche par critère"} />}
          width={1000}
          centered
        >
          <Critere
            assemblyGroupName={assemblyGroupModal.name}
            assemblyGroupId={assemblyGroupModal.id}
          />
        </Modal>
      </div>
    </div>
  );
};
