import gql from "graphql-tag";

export const COMPLAINTS = gql`
  query Complaints(
    $where: ComplaintWhereInput
    $skip: Int
    $like: ComplaintWhereInput
    $take: Int
    $gt: ComplaintWhereInput
    $lt: ComplaintWhereInput
  ) {
    complaints(
      where: $where
      skip: $skip
      take: $take
      like: $like
      gt: $gt
      lt: $lt
    ) {
      data {
        id
        status
        message
        createdDate
      }
      count
    }
  }
`;

export default {
  COMPLAINTS,
};
