import { Spin } from "antd";
import React from "react";

const Loading = (props) => {
  if (props.error) {
    return <div>Error!</div>;
  } else {
    return (
      <Spin></Spin>
    );
  }
};

export default Loading;
