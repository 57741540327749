import gql from "graphql-tag";

export const DEVICE = gql`
  query Device($where: DeviceWhereInput) {
    device(where: $where) @authBff {
      id
      access {
        id
        code
      }
    }
  }
`;

export const ACCESS = gql`
  query Access($where: AccessWhereInput) {
    access(where: $where) @authBff {
      id
      code
      device {
        id
        guid
      }
    }
  }
`;

export const USER = gql`
  query User($where: UserWhereInput) {
    user(where: $where) @authBff {
      id
      phone
      accesses {
        id
        code
        device {
          id
          guid
        }
      }
    }
  }
`;

export const ACCESSES = gql`
  query accesses($where: AccessWhereInput, $skip: Int, $take: Int) {
    accesses(where: $where, skip: $skip, take: $take) @authBff {
      count
      data {
        id
        code
        user {
          id
          username
        }
        device {
          id
          guid
        }
      }
    }
  }
`;

export const DEVICES = gql`
  query devices($where: DeviceWhereInput) {
    devices(where: $where) @authBff {
      data {
        guid
        access {
          id
          code
        }
      }
    }
  }
`;

export const USERS = gql`
  query users($where: UserWhereInput, $like: UserWhereInput) {
    users(where: $where, like: $like) @authBff {
      data {
        id
        username
      }
    }
  }
`;

export const PIN = gql`
  query Pin($where: InputPhoneVerification) {
    pin(where: $where) @authBff {
      id
      code
    }
  }
`;

export default {
  DEVICE,
  USER,
  USERS,
  ACCESS,
  ACCESSES,
  DEVICES,
  PIN,
};
