import Loadable from "react-loadable";
import Loading from "../../../shared/components/Loading";
import { PATHS } from "./../../../utils/constants";

const Detail = Loadable({
  loader: () => import("./Detail"),
  loading: Loading,
});

const List = Loadable({
  loader: () => import("./List"),
  loading: Loading,
});

const routes = [
  {
    path: "/promotion/:id",
    exact: true,
    component: Detail,
  },
  {
    path: PATHS.PROMOTIONS,
    exact: true,
    component: List,
  },
];
export default routes;
