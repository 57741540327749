import React from "react";
import { Container, Row } from "react-bootstrap";
import { Map as LeafletMap, Marker, Popup, TileLayer } from "react-leaflet";
import branches from "./../../../../utils/branches";
import iconMarker from "./icon";

const Details = {
  LAAYOUNE: ({ schedule }) => (
    <div className="contactInfo_hours">
      <h3>Horaire : </h3>
      <p>{schedule.SummerWinter}</p>
    </div>
  ),
  DEFAULT: ({ schedule }) => (
    <div className="contactInfo_hours">
      <h3>Horaire été: </h3>
      <p>{schedule.SummerMatin}</p>
      <p>{schedule.SummerSoire} </p>

      <h3>Horaire d’hiver: </h3>
      <p>{schedule.WinterMatin}</p>
      <p>{schedule.WinterSoire} </p>
    </div>
  ),
};

const Detail = ({ branches, city }) => {
  const CityDetails = Details[city] || Details.DEFAULT;

  return (
    <Container>
      <Row className="contactInfo">
        <div className="contactInfo_info">
          <h3>{city}</h3>

          <p>{branches[city].address}</p>

          <p>
            <span>Téléphone :</span> {branches[city].phone}
          </p>

          <p>
            <span>Fax :</span> {branches[city].fax || "indisponible"}
          </p>

          <p>
            <span>Email :</span> {branches[city].email}{" "}
          </p>
        </div>
        <CityDetails schedule={branches[city].schedule} />
      </Row>
    </Container>
  );
};

const Map = () => {
  return (
    <LeafletMap
      center={[31.6346248, -8.0158078]}
      zoom={20}
      maxZoom={30}
      attributionControl={true}
      zoomControl={true}
      doubleClickZoom={true}
      scrollWheelZoom={false}
      dragging={true}
      animate={true}
      easeLinearity={0.35}
    >
      <TileLayer url="http://dev.tile.openstreetmap.ma/osm/{z}/{x}/{y}.png" />
      {Object.keys(branches).map((city) => (
        <Marker position={branches[city].position} icon={iconMarker}>
          <Popup>
            <Detail branches={branches} city={city} />
          </Popup>
        </Marker>
      ))}
    </LeafletMap>
  );
};

export default Map;
