import gql from "graphql-tag";
export const CREATE_PROMOTION = gql`
  mutation CreatePromotion($data: PromotionWhereInput) {
    createPromotion(data: $data) {
      id
    }
  }
`;

export const DELETE_PROMOTION = gql`
  mutation DeletePromotion($data: PromotionWhereInput) {
    deletePromotion(data: $data) {
      id
    }
  }
`;

export const UPDATE_PROMOTION = gql`
  mutation UpdatePromotion($data: PromotionWhereInput) {
    updatePromotion(data: $data) {
      id
    }
  }
`;

export default {
  CREATE_PROMOTION,
  UPDATE_PROMOTION,
  DELETE_PROMOTION,
};
