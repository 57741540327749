import get from "lodash/get";
import React from "react";
import PrivateRoute from "../../shared/authorization/PrivateRoute";
import { Admin as Layout } from "../../shared/components/Layout";
import { ROLES } from "../../utils/constants";
import Pusher from "./../../shared/components/Pusher";
import Accesses from "./Access";
import Accounts from "./Accounts";
import AssemblyGroups from "./AssemblyGroups";
import Assets from "./Assets";
import Banners from "./Banners";
import Entrees from "./Entrees";
import Mdds from "./Mdds";
import Notifications from "./Notifications";
import Vedettes from "./Vedettes";

import Cart from "./Cart";
import Claims from "./Claims";
import Invoices from "./Invoices";
import Orders from "./Orders";
import Promotions from "./Promotion";
import Quotes from "./Quotes";
import Suppliers from "./Supplier";
import Supports from "./Support";
import Synchronisation from "./Synchronisation";
import Uploads from "./Uploads";
import CronJob from "./cronJob";

import Users from "./User";
import WishList from "./wishList";

const Admin = [
  {
    path: "/admin",
    exact: true,
    component: () => <div>This the home admin it is a work in progress</div>,
  },
];

const routes = [
  ...CronJob,
  //...Stock,
  ...Vedettes,
  ...Entrees,
  ...Synchronisation,
  ...Admin,
  ...Suppliers,
  ...Users,
  ...Promotions,
  ...Supports,
  ...Invoices,
  ...Orders,
  ...Assets,
  ...Cart,
  ...Claims,
  ...Quotes,
  ...Accesses,
  ...Accounts,
  ...Uploads,
  ...AssemblyGroups,
  ...WishList,
  ...Banners,
  ...Mdds,
  ...Notifications,
];

export default (props) =>
  (get(props, "user.role.name") === ROLES.ADMIN ? routes : []).map(
    ({ component, ...rest }) => {
      return {
        component: () =>
          PrivateRoute({
            component: Layout({
              component: Pusher(component),
            }),
          }),
        ...rest,
      };
    }
  );
