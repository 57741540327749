import Loadable from "react-loadable";
import Loading from "../../../shared/components/Loading";
import { PATHS } from "../../../utils/constants";
const List = Loadable({
  loader: () => import("./List"),
  loading: Loading,
});

const routes = [
  {
    path: PATHS.CUSTOMER.INVOICES.LIST,
    exact: true,
    component: List,
  },
];

export default routes;
