import gql from "graphql-tag";

export const QUERY_JOURNALS = gql`
  query journals($journalInput: JournalInput) {
    journals(journalInput: $journalInput) {
      data {
        id
        titre
        description
        date
        url
        imageUrl
        employe {
          username
        }
      }
      count
    }
  }
`;

export const QUERY_COUNT_JOURNALS = gql`
  query journalsCount($journalCountInput: JournalCountInput) {
    journalsCount(journalCountInput: $journalCountInput)
  }
`;
