import gql from "graphql-tag";

export const STOCKS = gql`
  query Stocks(
    $isIn: [StockWhereInput]
    $isNotIn: [StockWhereInput]
    $where: StockWhereInput
    $like: StockWhereInput
    $skip: Int
    $take: Int
    $gt: StockWhereInput
    $lt: StockWhereInput
    $gte: StockWhereInput
    $lte: StockWhereInput
    $orderBy: [JSONObject]
  ) {
    stocks(
      isNotIn: $isNotIn
      isIn: $isIn
      where: $where
      like: $like
      skip: $skip
      take: $take
      gt: $gt
      lt: $lt
      gte: $gte
      lte: $lte
      orderBy: $orderBy
    ) {
      data {
        id
        quantity
        price {
          id
          value
        }
        oldPrice {
          id
          value
        }
        discount {
          id
          value
        }
        deleted
        createdDate
        updatedDate
        arrivalDate
        supplier {
          id
          name
          image
        }
        item {
          id
          prices {
            id
            value
            updatedDate
          }
          discount {
            id
            discountLines {
              id
              min
              max
              startDate
              endDate
              value
              account {
                title
                id
              }
            }
          }
          itemWareHouses {
            id
            quantity
            wareHouse {
              id
              name
              code
              city
            }
          }
          catalogCode
        }
        reason
        designation
        application
      }
      count
    }
  }
`;

export const STOCK = gql`
  query Stock($where: StockWhereInput) {
    stock(where: $where) {
      id
      quantity
      price {
        id
        value
      }
      oldPrice {
        id
        value
      }
      discount {
        id
        value
      }
      deleted
      createdDate
      updatedDate
      arrivalDate
      supplier {
        id
        name
        image
      }
      item {
        id
        prices {
          id
          value
          updatedDate
        }

        discount {
          id
          discountLines {
            id
            min
            max
            startDate
            endDate
            value
            account {
              title
              id
            }
          }
        }
        itemWareHouses {
          id
          quantity
          wareHouse {
            id
            name
            code
            city
          }
        }
        catalogCode
      }
      reason
      designation
      application
    }
  }
`;

export default {
  STOCKS,
  STOCK,
};
