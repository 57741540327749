import { Icon, Tabs } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Heading from "../../../shared/components/stories/Heading";
import Claims from "./Claims";
import Map from "./Map";
import SocialNetwork from "./SocialNetwork";
import TechnicalHelp from "./TechnicalHelp";
import Values from "./Values";

const { TabPane } = Tabs;

const Contact = () => {
  return (
    <div className="section full contactPage">
      <div className="div-vertical" style={{ width: "100%" }}>
        <div className="titre-div-horizontal">
          <Heading title={"Nos coordonnées"} />
        </div>
        <Row
          gutter={[24, 16]}
          className=" p-top-40 p-bottom-20 p-left-20 p-right-20"
        >
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <div className="contactCard">
              <div className="contactCard_head">
                <Icon
                  type="phone"
                  style={{ fontSize: "50px" }}
                  theme="outlined"
                />
              </div>
              <div className="contactCard_body">
                <p>
                  <span>(+212) 05224-44004</span>
                  <br />
                  <span>(+212) 05224-44005</span>
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <div className="contactCard">
              <div className="contactCard_head">
                <Icon
                  type="environment"
                  style={{ fontSize: "50px" }}
                  theme="outlined"
                />
              </div>
              <div className="contactCard_body">
                <p>
                  BD khouribga, 7 rue maarakat ait abdellah, Casablanca 20250
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <div className="contactCard">
              <div className="contactCard_head">
                <Icon
                  type="mail"
                  style={{ fontSize: "50px" }}
                  theme="outlined"
                />
              </div>
              <div className="contactCard_body">
                <p>contact@gap-maroc.com</p>
              </div>
            </div>
          </Col>
        </Row>

        <div className="p-top-20 ">
          <div className="titre-div-horizontal">
            <Heading title={"Aide"} />
          </div>
          <div className="modal_details p-top-20 p-bottom-20 p-left-20 p-right-20">
            <Row gutter={[24, 16]}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Claims />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <SocialNetwork />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <TechnicalHelp />
              </Col>

              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Values />
              </Col>
            </Row>
          </div>
        </div>
        <div className="p-top-20 ">
          <div className="titre-div-horizontal">
            <Heading title={"Localisation"} />
          </div>

          <Tabs defaultActiveKey="2" className="text-center">
            <TabPane
              tab={
                <span>
                  <Icon
                    type="menu"
                    style={{ fontSize: "20px" }}
                    theme="outlined"
                  />
                  Mode List
                </span>
              }
              key="1"
            >
              <div
                className="div-horizontal wrapped p-left-20 p-right-20 p-top-20 m-bottom-20"
                style={{ alignItems: "stretch" }}
              >
                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>Siège Casablanca</h3>
                      <p>
                        BD khouribga, 7 rue maarakat ait abdellah, Casablanca
                        20250
                      </p>
                      <p>05 22 44 40 04/05</p>
                    </div>

                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 09H00 13H00 14H30 - 18H30</p>
                      <p>Samedi: 09H00 13H00</p>
                    </div>
                  </div>
                </Col>

                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>Siège central</h3>
                      <p>
                        Bd Ahl Loghlam, sidi Bernoussi, Zenata, CP 20400 -
                        Casablanca
                      </p>
                      <p>05 22 44 30 13</p>
                    </div>
                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 09H00 13H00 14H30 - 18H30</p>
                      <p>Samedi: 09H00 13H00</p>
                    </div>
                  </div>
                </Col>
              </div>
            </TabPane>
            <TabPane
              tab={
                <span>
                  <Icon
                    type="environment"
                    style={{ fontSize: "20px" }}
                    theme="outlined"
                  />
                  Mode map
                </span>
              }
              key="2"
            >
              <Map />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Contact;
