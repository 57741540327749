import gql from "graphql-tag";

export const CONNECTOR_ACTION = gql`
  mutation applyActionConnector($data: InputConnector) {
    applyActionConnector(data: $data)
  }
`;
export const CONNECTORS_ACTION = gql`
  mutation applyActionAllConnectors($data: InputConnectors) {
    applyActionAllConnectors(data: $data)
  }
`;
export default {
  CONNECTOR_ACTION,
  CONNECTORS_ACTION,
};
