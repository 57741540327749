import { Collapse, Descriptions } from "antd";
import React from "react";

const { Panel } = Collapse;

export default React.memo((props) => {
  const {
    article: { ean, status, packagingUnit, packagingQuantity, code },
  } = props;

  return (
    <div className="descriptions-div">
      <Collapse
        bordered={false}
        expandIconPosition={"right"}
        defaultActiveKey={["1"]}
      >
        <Panel header={"Informations article"} key="1">
          <Descriptions bordered size="small">
            <Descriptions.Item label="N° article">{code}</Descriptions.Item>
            <Descriptions.Item label="Unité d'emballage">
              {packagingUnit}
            </Descriptions.Item>
            <Descriptions.Item label="Quantité par UV">
              {packagingQuantity}
            </Descriptions.Item>
            <Descriptions.Item label="Statut article">
              {status}
            </Descriptions.Item>
            <Descriptions.Item label="EAN">{ean}</Descriptions.Item>
            <Descriptions.Item label="Statut">
              <span className="color_status">Active</span>
            </Descriptions.Item>
          </Descriptions>
        </Panel>
      </Collapse>
    </div>
  );
});
