import { Collapse, Descriptions } from "antd";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import React from "react";

const { Panel } = Collapse;

export default React.memo(({ article }) => {
  const attributes = groupBy(get(article, "attributes", []), "name");
  return (
    <div className="descriptions-div">
      <Collapse
        bordered={false}
        expandIconPosition={"right"}
        defaultActiveKey={["1"]}
      >
        <Panel header={"Caractéristiques techniques"} key="1">
          <Descriptions bordered size="small">
            {Object.entries(attributes).map(([key, values]) => {
              return (
                <>
                  {values.map((value) => (
                    <Descriptions.Item key={`car-${key}`} label={key}>
                      {value.value}
                    </Descriptions.Item>
                  ))}
                </>
              );
            })}
          </Descriptions>
        </Panel>
      </Collapse>
    </div>
  );
});
