import React from "react";
import CartItemMenu from "./CartItemMenu";
import NotificationMenu from "./NotificationMenu";
import SelectBoxUser from "./SelectBoxUser";
import WishListItems from "./WishListItems";

export const buttons = ({ clearStore }) => {
  return [
    {
      key: 0,
      element: <NotificationMenu />,
    },
    {
      key: 1,
      element: <WishListItems></WishListItems>,
    },
    {
      key: 2,
      element: <CartItemMenu />,
    },
    {
      key: 3,
      element: <SelectBoxUser clearStore={clearStore}></SelectBoxUser>,
    },
  ];
};
