import Loadable from "react-loadable";
import Loading from "../../../shared/components/Loading";
import { PATHS } from "../../../utils/constants";

const Accueil = Loadable({
  loader: () => import("./Accueil"),
  loading: Loading,
});

const routes = [
  {
    path: PATHS.CUSTOMER.RECHERCHES.ACCUEIL,
    exact: true,
    component: Accueil,
  },
];

export default routes;
