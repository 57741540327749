import gql from "graphql-tag";

export const MANUFACTURERS = gql`
  query Manufacturers($where: ManufacturerWhereInput) {
    manufacturers(where: $where) {
      data {
        id
        name
      }
      count
    }
  }
`;

export const MANUFACTURER = gql`
  query Manufacturer($where: ManufacturerWhereInput) {
    manufacturer(where: $where) {
      id
      name
    }
  }
`;

export const BRANDS = gql`
  query Brands($where: BrandWhereInput) {
    brands(where: $where) {
      data {
        id
        name
        logoId
      }
      count
    }
  }
`;

export const CRITERIAS = gql`
  query Criterias($where: CriteriaWhereInput) {
    criterias(where: $where) {
      data {
        id
        name
        shortName
        type
        unity
        values
      }
      count
    }
  }
`;

export const MODELS = gql`
  query Models($where: ModelWhereInput) {
    models(where: $where) {
      data {
        id
        name
        yearOfConstructionFrom
        yearOfConstructionTo
      }
      count
    }
  }
`;

export const MODEL = gql`
  query Model($where: ModelWhereInput) {
    model(where: $where) {
      id
      name
      yearOfConstructionFrom
      yearOfConstructionTo
    }
  }
`;

export const VEHICLES = gql`
  query Vehicles($where: VehicleWhereInput, $take: Int) {
    vehicles(where: $where, take: $take) {
      data {
        id
        name
        model {
          id
          name
        }
        manufacturer {
          id
          name
        }

        yearOfConstructionFrom
        yearOfConstructionTo
        powerHorsePowerFrom
        powerHorsePowerTo
        powerKwFrom
        powerKwTo
        ccmTech
        cylinder
        cylinderCapacityCcm
        cylinderCapacityLiter
        motorType
        fuelType
        motors
      }
      count
    }
  }
`;

export const ASSEMBLY_GROUPS = gql`
  query AssemblyGroups($where: AssemblyGroupWhereInput) {
    assemblyGroups(where: $where) {
      data {
        id
        name
        hasChildren
        parent {
          id
        }
        childrens
      }
      count
    }
  }
`;
export const EXPORT_ASSEMBLY_GROUPS = gql`
  query exportAssemblyGroup($where: ExportWhereInput) {
    exportAssemblyGroup(where: $where)
  }
`;

export const LINKED_ASSEMBLY_GROUPS = gql`
  query linkedAssemblyGroup($where: LinkedAssemblyGroupWhereInput) {
    linkedAssemblyGroup(where: $where)
  }
`;

export const ARTICLES = gql`
  query Articles($where: ArticleWhereInput) {
    articles(where: $where) {
      data {
        usageNumbers {
          code
        }
        id
        name
        code
        brand {
          id
          name
        }
        attributes {
          id
          name
          shortName
          value
        }
        thumbnails {
          id
          name
          url
        }
        pdfs {
          id
          name
          url
        }

        oens {
          blockNumber
          brandName
          oenNumber
          sortNumber
        }
        substitutes {
          id
          reference
        }
        ean
        status
        packagingUnit
        packagingQuantity
      }
      count
    }
  }
`;

export const ARTICLE = gql`
  query Article(
    $where: ArticleWhereInput
    $isIn: [ArticleWhereInput]
    $like: [ArticleWhereInput]
  ) {
    article(where: $where, isIn: $isIn, like: $like) {
      data {
        id
        name
        addName
        code
        assemblyGroups {
          id
          name
        }
        brand {
          id
          name
        }
        attributes {
          id
          name
          shortName
          value
        }
        thumbnails {
          id
          name
          url
        }
        pdfs {
          id
          name
          url
        }

        oens {
          blockNumber
          brandName
          oenNumber
          sortNumber
        }
        usageNumbers {
          code
        }
        substitutes {
          id
          reference
        }
        ean
        status
        quantity
        packagingUnit
        packagingQuantity
      }
      count
    }
  }
`;

export const MDD_ARTICLES = gql`
  query MddArticles($where: ArticleWhereInput, $like: [ArticleWhereInput]) {
    mddArticles(where: $where, like: $like) {
      data {
        id
        name
        code
        usageNumbers {
          code
        }
        brand {
          id
          name
        }
        attributes {
          id
          name
          shortName
          value
        }
        thumbnails {
          id
          name
          url
        }
        pdfs {
          id
          name
          url
        }

        oens {
          blockNumber
          brandName
          oenNumber
          sortNumber
        }
        substitutes {
          id
          reference
        }
        ean
        status
        packagingUnit
        packagingQuantity
      }
      count
    }
  }
`;
export const DIRECT_ARTICLE = gql`
  query DirectArticle($where: ArticleWhereInput, $like: ArticleWhereInput) {
    directArticle(where: $where, like: $like) {
      data {
        id
        name
        code
        usageNumbers {
          code
        }
        brand {
          id
          name
        }
        attributes {
          id
          name
          shortName
          value
        }
        thumbnails {
          id
          name
          url
        }
        pdfs {
          id
          name
          url
        }

        oens {
          blockNumber
          brandName
          oenNumber
          sortNumber
        }
        substitutes {
          id
          reference
        }
        ean
        status
        packagingUnit
        packagingQuantity
      }
      count
    }
  }
`;

export const CARS = gql`
  query Cars($where: CarWhereInput) {
    cars(where: $where) {
      data {
        id
        name
        model {
          id
          name
        }
        manufacturer {
          id
          name
        }
      }
      count
    }
  }
`;

export const SEARCH = gql`
  query Search($where: SearchWhereInput) {
    search(where: $where)
  }
`;
export const SEARCH_BAR = gql`
  query SearchBar($where: searchBarWhereInput) {
    searchBar(where: $where)
  }
`;

export const CATALOG = gql`
  query catalog($where: JSONObject, $skip: Int, $take: Int) {
    catalog(where: $where, skip: $skip, take: $take)
  }
`;

export const NOUVEAUTES = gql`
  query nouveautes($where: JSONObject) {
    nouveautes(where: $where)
  }
`;

export default {
  CATALOG,
  SEARCH_BAR,
  NOUVEAUTES,
  LINKED_ASSEMBLY_GROUPS,
  EXPORT_ASSEMBLY_GROUPS,
  MANUFACTURERS,
  MANUFACTURER,
  BRANDS,
  CRITERIAS,
  MODELS,
  MODEL,
  VEHICLES,
  ASSEMBLY_GROUPS,
  ARTICLES,
  ARTICLE,
  MDD_ARTICLES,
  DIRECT_ARTICLE,
  CARS,
  SEARCH,
};
