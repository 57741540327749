import { Modal } from "antd";
import React from "react";

export default ({ selected, setSelected }) => {
  return (
    <Modal
      className="form-modal"
      title="Siteweb et application mobile"
      visible={selected}
      width={800}
      onOk={() => setSelected(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => setSelected(false)}
    >
      <h4 style={{ fontWeight: "bold" }}>Accès au site</h4>
      <p>
        L'accès à notre site est sécurisé via l'utilisation de token. En effet,
        les token permettent une protection supplémentaire permettant ainsi de
        profiter pleinement de votre espace web en toute sécurité. L'utilisation
        d'un token est unique. Il ne peut être utilisé qu'une seule fois.
      </p>
      <p>
        Il arrive que surviennent des problèmes de réseau et qu'un message
        d'erreur s'affiche. Nous travaillons assidûment pour réduire ces
        incidents techniques, mais ils sont souvent indépendants de notre
        système.
      </p>
      <p>
        Cela peut parfois être dû à un logiciel malveillant empêchant la
        connexion. Nous vous conseillons vivement de mettre à jour votre
        anti-virus et votre antispyware puis de lancer une vérification du ou
        des disques durs. Vous pourrez ensuite accéder de nouveau au site.
      </p>
      <h4 style={{ fontWeight: "bold" }}> Navigation sur le site</h4>
      <p>
        Lors de votre navigation sur le site, plusieurs types de
        dysfonctionnements sont susceptibles de survenir, ceux-ci entraînant
        alors l'affichage d'un message d'erreur sur votre écran. Si un
        dysfonctionnement est récurrent, vous avez la possibilité de joindre
        notre Service client, du lundi au vendredi de 9h à 19h et le samedi de
        9h à 13h au (+212){" "}
        <i style={{ fontWeight: "bold" }}>05 22 44 40 04/05 </i>
        (Service gratuit + prix appel)
      </p>
      <h4 style={{ fontWeight: "bold" }}> Pages qui ne s'affichent pas</h4>
      <p>
        Si certaines pages du site ne s'affichent pas, nous vous conseillons de
        redémarrer une nouvelle session de votre navigateur, puis d'ouvrir une
        nouvelle page sur le site avec vos identifiants.
      </p>
      <h4 style={{ fontWeight: "bold" }}> Connexion interrompue</h4>
      <p>
        Il arrive parfois que la connexion s'interrompt alors que vous êtes
        connecté sur notre site.
        <br /> En effet, votre navigateur peut rencontrer un problème de
        session. Ce dysfonctionnement peut survenir lors d'une trop grande
        utilisation des boutons "Précédent" et "Suivant".
        <br /> Dans ce cas, nous vous invitions à redémarrer une nouvelle
        session de votre navigateur puis vous reconnecter pour ouvrir une
        nouvelle session. Nous vous conseillons aussi de réinitialiser votre
        navigateur en supprimant vos cookies de session, vos fichiers de
        navigation et votre historique de navigation.
      </p>
      <p>
        Si vous avez des demandes correctives ou d'évolutions concernant le
        siteweb, le catalogue en ligne et l'application mobile ou simplement une
        demande d'assistance, vous avez la possibilité de joindre notre Service
        client, du lundi au vendredi de 9h à 19h et le samedi de 9h à 13h au
        (+212) 05 22 44 40 04/05 (Service gratuit + prix appel) ou par mail à
        l'adresse:
        <i style={{ color: "blue" }}>contact@gap-maroc.com</i>
      </p>
      <h4 style={{ fontWeight: "bold" }}>
        Changement de device (PC, tablette, mobile)
      </h4>
      <p>
        Vous changez votre ordinateur/tablette/téléphone mobile ? GAP vous
        propose de contacter votre commercial ou le service client au (+212)
        <i style={{ fontWeight: "bold" }}> 05 22 44 40 04/05</i> pour demander
        de nouveaux identifiants.
      </p>
    </Modal>
  );
};
