import React, { Component } from "react";

export const Context = React.createContext("store");

export const Consumer = Context.Consumer;

const INIT_STATE = {
  user: {},
  hasPrivateAccess: false,
  access_token: "",
  compact: false,
  lastSeenNotification: null,
};

export class Provider extends Component {
  constructor() {
    super();
    const access_token = localStorage.getItem("access_token");
    const hasPrivateAccess = localStorage.getItem("hasPrivateAccess");
    const item = localStorage.getItem("user");
    const compact = localStorage.getItem("compact") || "false";
    const lastSeenNotification =
      localStorage.getItem("lastSeenNotification") || null;

    const users_uid = localStorage.getItem("users_uid")
      ? JSON.parse(localStorage.getItem("users_uid"))
      : [];

    const user = JSON.parse(item === "undefined" ? "{}" : item);
    this.state = access_token
      ? {
          users_uid,
          user,
          access_token,
          hasPrivateAccess: eval(hasPrivateAccess),
          compact: eval(compact),
          lastSeenNotification,
        }
      : { ...INIT_STATE, users_uid };
  }

  setUser = ({ user }) => {
    this.setState({ user });
    localStorage.setItem("user", JSON.stringify(user));
  };

  setCompact = (compact) => {
    this.setState({ compact });
    localStorage.setItem("compact", compact);
  };

  setLastSeenNotification = (lastSeenNotification) => {
    this.setState({ lastSeenNotification });
    localStorage.setItem("lastSeenNotification", lastSeenNotification);
  };

  setAccessToken = (access_token) => this.setState({ access_token });
  setUsersUid = (userId, uuid) => {
    localStorage.setItem(
      "users_uid",
      JSON.stringify([...this.state.users_uid, { id: userId, uid: uuid }])
    );
    this.setState({
      users_uid: [...this.state.users_uid, { id: userId, uid: uuid }],
    });
  };

  setToken = ({ accessToken, refreshToken }) => {
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
    this.setAccessToken(accessToken);
  };

  grantPrivateAccess = () => {
    localStorage.setItem("hasPrivateAccess", true);
    this.setState({
      hasPrivateAccess: true,
    });
  };

  clearStore = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.setItem("hasPrivateAccess", false);

    this.setState(INIT_STATE);
  };

  render() {
    const { children } = this.props;
    const {
      user,
      access_token,
      hasPrivateAccess,
      users_uid,
      compact,
      lastSeenNotification,
    } = this.state;
    return (
      <Context.Provider
        value={{
          users_uid,
          user,
          access_token,
          hasPrivateAccess,
          setUser: this.setUser,
          setAppToken: this.setAccessToken,
          clearStore: this.clearStore,
          setToken: this.setToken,
          grantPrivateAccess: this.grantPrivateAccess,
          setUsersUid: this.setUsersUid,
          setCompact: this.setCompact,
          compact,

          setLastSeenNotification: this.setLastSeenNotification,
          lastSeenNotification,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}
