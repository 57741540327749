import { Spin, Table } from "antd";
import React, { useContext } from "react";
import { Query } from "react-apollo";
import { TabContext } from "../../../../../Context/Tab";
import { ManufacturersQueries } from "../../../../../shared/graphql/queries";
import { SCREENS, TABS_ACTIONS } from "../../../../../utils/constants";

const { MANUFACTURERS } = ManufacturersQueries;

const columns = [
  {
    title: "#id",
    dataIndex: "id",
    key: "id",
    align: "center",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
];

export default (props) => {
  const variables = {
    where: {},
  };
  const { dispatch } = useContext(TabContext);

  return (
    <Query query={MANUFACTURERS} variables={variables}>
      {({ loading, error, data }) => {
        if (loading) return <Spin></Spin>;
        if (error) return `Error! ${error.message}`;

        const manufacturers = data.manufacturers.data;
        return (
          <Table
            bordered
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={manufacturers}
            onRow={(record) => {
              return {
                onClick: () =>
                  dispatch({
                    type: TABS_ACTIONS.HISTORY_PUSH,
                    payload: {
                      screen: SCREENS.MODELS,
                      props: { search: { manufacturer: record.id } },
                    },
                  }),
              };
            }}
          />
        );
      }}
    </Query>
  );
};
