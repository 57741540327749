import React from "react";
import LogoPlus from "../LogoPlus";

import "./styles.scss";

const Heading = ({ title, sombre, sansIcone, ...rest }) => (
  <div
    className={
      "heading" + (sombre ? " sombre" : "") + (sansIcone ? " sansIcone" : "")
    }
    {...rest}
  >
    {!sansIcone && (
      <span style={{ width: "50px" }}>
        <LogoPlus sombre />
      </span>
    )}
    <h2 className="heading__title">{title}</h2>
  </div>
);

export default Heading;
