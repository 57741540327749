import { WrappedForm } from "./view";
import { applyHandleSubmit, useSubmit } from "./utils";
import Field from "./Field";
import { Form } from "antd";

export const AUTO_GENERATE = "AUTO_GENERATE";

export default {
  WrappedForm,
  applyHandleSubmit,
  useSubmit,
  Field,
  AUTO_GENERATE,
  FormItem: Form.Item
};
