import { Spin, Tag } from "antd";
import { get } from "lodash";
import React, { useContext } from "react";
import { TabContext } from "../../../../../Context/Tab";
import Vehicles from "../../../../../entities/vehicles";
import Loader from "../../../../../shared/components/Loader";
import { SCREENS, TABS_ACTIONS } from "../../../../../utils/constants";

const Vin = (props) => {
  const { dispatch } = useContext(TabContext);

  const { vin } = props.search;
  if (!vin) return null;
  return (
    <Vehicles
      NoData={() => null}
      filter={{ where: { vin } }}
      Loading={() => <Spin />}
      View={Loader(({ data }) => {
        return (
          <div className="home_section_bg">
            {data.map((vehicle) => (
              <Tag
                key={vehicle.id}
                onClick={() => {
                  console.log("dispatch 1 *******");
                  return dispatch({
                    type: TABS_ACTIONS.HISTORY_PUSH,
                    payload: {
                      screen: SCREENS.ASSEMBLYGROUPS,
                      props: {
                        search: {
                          linkingTarget: get(vehicle, "id", ""),
                          manufacturer: get(vehicle, "manufacturer.id", ""),
                          model: get(vehicle, "model.id", ""),
                        },
                      },
                    },
                  });
                }}
              >
                {vehicle.id}) {vehicle.name}
              </Tag>
            ))}
          </div>
        );
      })}
    ></Vehicles>
  );
};
export default Vin;
