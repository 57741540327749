import gql from "graphql-tag";

export const CREATE_CLAIM = gql`
  mutation CreateClaim($data: ClaimWhereInput) {
    createClaim(data: $data){
      id
    }
  }
`;

export const UPDATE_CLAIM = gql`
  mutation UpdateClaim($data: ClaimWhereInput) {
    updateClaim(data: $data){
      id
    }
  }
`;

export default {
  CREATE_CLAIM,
  UPDATE_CLAIM
};
