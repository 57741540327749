import { useMutation } from "@apollo/react-hooks";
import { Select, Tooltip } from "antd";
import React, { useState } from "react";
import { WishListItemsMutations } from "../../../../../shared/graphql/mutations";
import Icons from "../../../../../shared/components/Icon";
import { ITEM_WAREHOUSE_DESCRIPTION_VIEW } from "../../../../../utils/constants";
import { useHistory } from "react-router-dom";
const { UPDATE_WISH_LIST_ITEM } = WishListItemsMutations;
const { Option } = Select;

export default (props) => {
  const history = useHistory();
  const { record, options } = props;
  const [item, setItem] = useState(record);
  const [updateWishListItem] = useMutation(UPDATE_WISH_LIST_ITEM, {
    onCompleted: () => {
      history.push("/wish-list-items");
    },
  });

  return (
    <Select
      onChange={(value) => {
        updateWishListItem({
          variables: { data: { id: item.id, description: value } },
        });
      }}
      defaultValue={Icons[item.description]}
      style={{ width: 120 }}
    >
      {options.map((option) => (
        <Option value={option.value}>
          <Tooltip title={ITEM_WAREHOUSE_DESCRIPTION_VIEW[option.value]}>
            {Icons[option.value]}
          </Tooltip>
        </Option>
      ))}
    </Select>
  );
};
