import { Button } from "antd";
import React, { useState } from "react";
import LogoPlus from "../../../../../shared/components/stories/LogoPlus";
import AssemblyGroups from "./AssemblyGroups";

export default (props) => {
  const [mode, setMode] = useState("card");

  return (
    <div className="home_section_bg">
      <div className="text-right p-top-20 switch">
        <div className="headingSwitch">
          <span style={{ width: "50px" }}>
            <LogoPlus />
          </span>
          <h2 className="headingSwitch__title">
            Toutes les pièces disponibles par catégorie
          </h2>
        </div>
        <Button
          type="danger"
          className="btn_switch"
          onClick={() => setMode(mode === "table" ? "card" : "table")}
        >
          Switch
        </Button>
      </div>

      <AssemblyGroups mode={mode} {...props} />
    </div>
  );
};
