/* eslint-disable */
import React, { useEffect } from "react";
import Pusher from "pusher-js";
import { notification } from "antd";
import format from "string-template";

const TOPICS = {
  ORDER_UPDATED: "order.updated",
  ORDER_CREATED: "order.created",
  FILE_CREATED: "file.created"
};
const navigators = {
  [TOPICS.ORDER_UPDATED]: {
    message: "Order Updated",
    description: "Votre commande a été mise à jour",
    goTo: "/orders/{0}"
  },
  [TOPICS.ORDER_CREATED]: {
    message: "Order Created",
    description: "Vous avez créé une commande",
    goTo: "/orders/{0}"
  },
  [TOPICS.FILE_CREATED]: {
    message: "File Created",
    description: "Votre Fichier a bien été généré"
  }
};

const openNotification = ({ topic, data }, { navigation }) => {
  const { message, description, goTo } = navigators[[topic]];
  notification.open({
    message,
    description,
    onClick: () => {
      window.open(data, "_blank");
    }
  });
};

export default WrappedComponent => props => {
  useEffect(() => {
    const pusher = new Pusher("d38b2acd876187cf127d", {
      cluster: "eu",
      forceTLS: true
    });
    pusher
      .subscribe("user-1")
      .bind("order.updated", data =>
        openNotification(
          { topic: "order.updated", data },
          {
            navigation: { navigate: props.history.push }
          }
        )
      )
      .bind("order.created", data =>
        openNotification(
          { topic: "order.created", data },
          {
            navigation: { navigate: props.history.push }
          }
        )
      )
      .bind("file.created", data => {
        return openNotification(
          { topic: "file.created", data },
          {
            navigation: { navigate: props.history.push }
          }
        );
      });
    return function cleanup() {
      pusher.unsubscribe("user-1");
    };
  });

  return <WrappedComponent {...props} />;
};
