import gql from "graphql-tag";

export const SUPPLIERS = gql`
  query Suppliers(
    $where: SupplierWhereInput
    $skip: Int
    $take: Int
    $like: SupplierWhereInput
    $isIn: [SupplierWhereInput]
  ) {
    suppliers(
      where: $where
      skip: $skip
      take: $take
      like: $like
      isIn: $isIn
    ) {
      data {
        id
        name
        url
        image
        file
        video
      }
      count
    }
  }
`;

export const SUPPLIER = gql`
  query Supplier($where: SupplierWhereInput) {
    supplier(where: $where) {
      id
      name
      url
      image
      file
      video
    }
  }
`;

export default {
  SUPPLIERS,
  SUPPLIER,
};
